import * as yup from 'yup';
import { FIELD_DEFINITIONS, FIELD_TEXT_MESSAGES } from '../../../config';

export const ForgotPasswordEmailStepSchema = yup.object().shape({
  email: yup
    .string()
    .min(FIELD_DEFINITIONS.EMAIL_MIN_LENGTH, FIELD_TEXT_MESSAGES.EMAIL_MIN_LENGTH)
    .max(FIELD_DEFINITIONS.EMAIL_MAX_LENGTH, FIELD_TEXT_MESSAGES.EMAIL_MAX_LENGTH)
    .email(FIELD_TEXT_MESSAGES.EMAIL_INVALID)
    .required(FIELD_TEXT_MESSAGES.EMAIL_REQUIRED),
});
