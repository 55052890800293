import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

import LoadingIndicator from '../components/common/LoadingIndicator';

import { persistor, store } from '../store';

function ErrorFallback() {
  return (
    <div className='d-flex flex-column min-vh-100 min-vw-100'>
      <div className='d-flex flex-grow-1 justify-content-center align-items-center'>
        <div className='text-center'>
          <h1>Something went wrong.</h1>
          <button className='text-white btn btn-danger' onClick={() => window.location.reload()}>
            Refresh the page
          </button>
        </div>
      </div>
    </div>
  );
}

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <React.Suspense fallback={<LoadingIndicator />}>
      <HelmetProvider>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Router>{children}</Router>
              <ToastContainer limit={1} transition={Slide} theme={'colored'} />
            </PersistGate>
          </Provider>
        </ErrorBoundary>
      </HelmetProvider>
    </React.Suspense>
  );
};
