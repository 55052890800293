/* eslint-disable jsx-a11y/label-has-associated-control */
import clsx from 'clsx';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

const Label = (
  { className, style, ...props }: ComponentPropsWithoutRef<'label'>,
  ref: React.LegacyRef<HTMLLabelElement>,
) => {
  return (
    <label
      style={{ fontWeight: '500', fontSize: '14px', lineHeight: '20px', ...style }}
      ref={ref}
      className={clsx('form-label', className)}
      {...props}
    />
  );
};

export default forwardRef(Label);
