import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import RegisterAccountService from '../../../api/services/RegisterService';

const ACTIONS_PREFIX = 'register';

type RegisterState = {
  isLoading: boolean;
  error: string | null;
  successMessage: string | null;
  registeredUser: any | null;
  deviceSession: {
    accessToken: string | null;
    expiresAt: string | null;
  } | null;
};

const initialState: RegisterState = {
  isLoading: false,
  error: null,
  successMessage: null,
  registeredUser: null,
  deviceSession: null,
};

export const registerUserThunk = createAsyncThunk(
  `${ACTIONS_PREFIX}/registerUser`,
  async (
    params: { userData: any; accessToken: string; callback: (success: boolean) => void },
    { rejectWithValue },
  ) => {
    const { userData, accessToken, callback } = params;
    try {
      const response = await RegisterAccountService.register(userData, accessToken, callback);
      callback(true);
      return response;
    } catch (error) {
      callback(false);
      return rejectWithValue(error);
    }
  },
);

export const searchUserThunk = createAsyncThunk(
  `${ACTIONS_PREFIX}/searchUser`,
  async (params: { searchData: any; accessToken: string }, { rejectWithValue }) => {
    const { searchData, accessToken } = params;
    try {
      const response = await RegisterAccountService.search(searchData, accessToken);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createDeviceSessionThunk = createAsyncThunk(
  `${ACTIONS_PREFIX}/createDeviceSession`,
  async (deviceId: string, { rejectWithValue }) => {
    try {
      const response = await RegisterAccountService.createDeviceSession(deviceId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    clearRegistrationData: (state) => {
      state.registeredUser = null;
      state.successMessage = null;
      state.error = null;
    },
    clearDeviceSession: (state) => {
      state.deviceSession = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUserThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(registerUserThunk.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.registeredUser = action.payload;
        state.successMessage = 'Registration successful';
        state.error = null;
      })
      .addCase(registerUserThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Registration failed';
        state.successMessage = null;
      })
      .addCase(searchUserThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(searchUserThunk.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(searchUserThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Search failed';
      })
      .addCase(createDeviceSessionThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        createDeviceSessionThunk.fulfilled,
        (state, action: PayloadAction<{ accessToken: string; expiresAt: string }>) => {
          state.isLoading = false;
          state.deviceSession = action.payload;
          state.error = null;
        },
      )
      .addCase(createDeviceSessionThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to create device session';
        state.deviceSession = null;
      });
  },
});

export const { clearRegistrationData, clearDeviceSession } = registerSlice.actions;

export const registerState = (state: RootState) => state.register;
export default registerSlice.reducer;
