import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enCombined from './en';
import esCombined from './es';

const resources = {
  en: {
    translation: enCombined,
  },
  es: {
    translation: esCombined,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    // [FORCE LANGUAGE HERE -> TESTING PURPOSES ONLY!]
    resources: resources,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
