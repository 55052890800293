import { useTranslation } from 'react-i18next';
import { CommonDropdownType } from '../types';

const useCountries = (): CommonDropdownType[] => {
  const { t } = useTranslation();

  return [
    { value: 'US', label: t('United States') },
    { value: 'CA', label: t('Canada') },
    { value: 'MX', label: t('Mexico') },
  ];
};

export default useCountries;
