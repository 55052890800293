import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// COMPONENTS
import LoaderButton from '../../../../../components/common/LoaderButton';

import AccountAlreadyExistsImg from '../../../../../assets/images/icons/lookup-already-exists-x3.png';

// TYPES
import { FunctionComponent } from 'react';

type Props = {
  employeeInfo: any;
};

export const AccountAlreadyExists: FunctionComponent<Props> = ({ employeeInfo }: Props) => {
  const { t }: any = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <div className='d-flex flex-column align-items-center text-center'>
        <h2 className='auth__page-title'>{t(employeeInfo.message)}</h2>
        <img src={AccountAlreadyExistsImg} alt='account-not-found' width='70%' height='70%' />

        <p>{t('Try logging in with your credentials.')}</p>

        <p className='mt-4'>{t('If you continue to have trouble, reset your password.')}</p>
      </div>

      <div>
        <LoaderButton
          className={`auth__btn-primary mt-3`}
          type='submit'
          onClick={() => {
            navigate('/auth/login');
          }}
        >
          {t('Back to Login')}
        </LoaderButton>
      </div>
    </>
  );
};
