import './InactivityModal.scss';

import { FunctionComponent, useCallback, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useIdleTimer } from 'react-idle-timer';

import warningGlowIcon from '../../assets/images/icons/warning-glow.svg';
import { SESSION_IDLE_MINUTES, REFRESH_SESSION_TRESHOLD_SECONDS } from '../../config';
import { logoutUserThunk, setInactivity } from '../../features/auth/api/authSlice';
import { getCurrentUser } from '../../hooks/useAuth';
import useCountdown from '../../hooks/useCountdown';
import { useAppSelector, useAppDispatch } from '../../store';
import { upsertLastActivityCookie } from '../../utils/sessionCookies';
import LoaderButton from '../common/LoaderButton';

const IDLE_TIMEOUT = 1000 * 60 * SESSION_IDLE_MINUTES;

const PROMPT_BEFORE_IDLE = 1000 * REFRESH_SESSION_TRESHOLD_SECONDS;

const InactivityModal: FunctionComponent = () => {
  const { seconds, start, reset } = useCountdown(REFRESH_SESSION_TRESHOLD_SECONDS);
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showInactivityModal, setShowInactivityModal] = useState(false);

  const logout = useCallback(() => dispatch(logoutUserThunk()), [dispatch]);

  // Message handler
  const onMessage = useCallback(
    (data: any) => {
      switch (data.action) {
        case 'INACTIVE_SESSION_REFRESHED':
          reset();
          dispatch(setInactivity({ isInactive: false }));
          setShowInactivityModal(false);
          break;
        case 'INACTIVE_SESSION_LOGOUT':
          setShowInactivityModal(false);
          logout();
          break;
        default:
      }
    },
    [dispatch, logout, reset],
  );

  const onPrompt = useCallback(() => {
    dispatch(setInactivity({ isInactive: true }));
    setShowInactivityModal(true);
    start();
  }, [dispatch, start]);

  const onIdle = useCallback(() => {
    logout();
  }, [logout]);

  const onAction = useCallback(() => {
    upsertLastActivityCookie();
  }, []);

  const { message, activate } = useIdleTimer({
    timeout: IDLE_TIMEOUT + PROMPT_BEFORE_IDLE,
    // timeout: IDLE_TIMEOUT,
    debounce: 500,
    crossTab: true,
    syncTimers: 1,
    promptBeforeIdle: PROMPT_BEFORE_IDLE,
    onMessage,
    onIdle,
    onPrompt,
    onAction,
  });

  const onRefreshSession = useCallback(async () => {
    setIsSubmitting(true);
    try {
      const user = await getCurrentUser();
      if (!user) {
        message({ action: 'INACTIVE_SESSION_LOGOUT' }, true);
      } else {
        activate();
        message({ action: 'INACTIVE_SESSION_REFRESHED' }, true);
      }
    } catch (_) {
      message({ action: 'INACTIVE_SESSION_LOGOUT' }, true);
    } finally {
      setIsSubmitting(false);
    }
  }, [activate, message]);

  const onCloseSession = useCallback(() => {
    message({ action: 'INACTIVE_SESSION_LOGOUT' }, true);
  }, [message]);

  return (
    <Modal
      backdrop='static'
      keyboard={false}
      fullscreen={'md-down'}
      animation={false}
      centered
      contentClassName='inactivity-modal__content'
      dialogClassName='inactivity-modal__dialog'
      show={showInactivityModal && auth.isInactive}
    >
      <Modal.Header className='inactivity-modal__header'>
        <Modal.Title>
          <img src={warningGlowIcon} alt='inactivity-icon' />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='inactivity-modal__body'>
        <h4 className='inactivity-modal__title'>Are you still there?</h4>
        <p>You have been idle for {SESSION_IDLE_MINUTES} minutes.</p>
        <p className='text-gray-500'>
          You have <strong>{seconds} seconds</strong> to refresh your session or logout
        </p>
      </Modal.Body>
      <Modal.Footer className='inactivity-modal__footer'>
        <Button onClick={onCloseSession} variant={'secondary'} className='inactivity-modal__btn'>
          Logout
        </Button>
        <LoaderButton
          className={`inactivity-modal__btn`}
          onClick={onRefreshSession}
          isSubmitting={isSubmitting}
        >
          Refresh Session
        </LoaderButton>
      </Modal.Footer>
    </Modal>
  );
};

export default InactivityModal;
