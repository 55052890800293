import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// COMPONENTS
import LoaderButton from '../../../../../components/common/LoaderButton';

// TYPES
import { FunctionComponent } from 'react';
import { APIResponseStatus } from '../../../../../api/types';

import AccountNotFoundImg from '../../../../../assets/images/icons/lookup-not-found-x3.png';

type Props = {
  employeeInfo: APIResponseStatus | undefined;
};

export const AccountNotFound: FunctionComponent<Props> = ({ employeeInfo }: Props) => {
  const { t }: any = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <div className='d-flex flex-column align-items-center text-center'>
        <h2 className='auth__page-title'>{t(employeeInfo?.message)}</h2>
        <img src={AccountNotFoundImg} alt='account-not-found' width='70%' height='70%' />

        <p>{t('For help please contact your HR manager or contact Direct2Care')}</p>

        <a className='mt-4' href='tel:8338887566'>
          {t('(833) 888-7566')}
        </a>
        <a href='mailto:customercare@direct2care.com'>{t('customercare@direct2care.com')}</a>
      </div>

      <div>
        <LoaderButton
          className={`auth__btn-primary mt-3`}
          type='submit'
          onClick={() => {
            navigate('/auth/login');
          }}
        >
          {t('Back to Home Screen')}
        </LoaderButton>
      </div>
    </>
  );
};
