import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { FunctionComponent, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import envelopeGlowIcon from '../../../assets/images/icons/envelope-glow.svg';
import lockGlowIcon from '../../../assets/images/icons/lock-glow.svg';
import LoaderButton from '../../../components/common/LoaderButton';
import Input from '../../../components/Input';
import Label from '../../../components/Label';
import { useAppDispatch, useAppSelector } from '../../../store';
import alert from '../../../utils/alert';
import { forgotPasswordThunk } from '../../auth/api/authSlice';
import { ForgotPasswordEmailStepSchema } from '../schemas/index';
import { EmailFormData, EmailFormScreenProps, SuccessScreenProps } from '../types';
import { useTranslation } from 'react-i18next';

const EmailFormScreen: FunctionComponent<EmailFormScreenProps> = ({ onSubmitEmail }) => {
  const auth = useAppSelector((state) => state.auth);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, dirtyFields },
  } = useForm<EmailFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(ForgotPasswordEmailStepSchema),
  });

  const { t } = useTranslation();

  return (
    <div className='d-flex flex-column justify-content-center'>
      <div className='d-flex flex-column'>
        <img className='auth__page-icon' alt={'lock-icon'} src={lockGlowIcon} />
        <p className='auth__page-title'>{t('Forgot Password')}</p>
        <p className='auth__help-text'>{t('Enter the email associated with your account.')}</p>
      </div>
      <form className='auth__form--width' onSubmit={handleSubmit(onSubmitEmail)}>
        <Form.Group controlId='email' className='auth__input-group'>
          <Label>{t('Email')}</Label>
          <Input
            type='text'
            className={clsx(
              [errors.email && 'is-invalid'],
              [dirtyFields.email && !errors.email ? 'is-valid' : ''],
            )}
            {...register('email')}
          />
          {errors.email?.message && (
            <div role={'alert'} className='invalid-feedback'>
              {t(errors.email?.message)}
            </div>
          )}
        </Form.Group>
        <LoaderButton
          className={`w-100`}
          type='submit'
          isSubmitting={auth.isLoading}
          disabled={!isValid}
        >
          {t('Next')}
        </LoaderButton>
      </form>
    </div>
  );
};

const SuccessScreen: FunctionComponent<SuccessScreenProps> = ({
  userEmail,
  onProcessDone,
  onResendClick,
}) => {
  const RESEND_THRESHOLD = 30;
  const [seconds, setSeconds] = useState(RESEND_THRESHOLD);

  useEffect(() => {
    const timeout = seconds > 0 ? setTimeout(() => setSeconds(seconds - 1), 1000) : undefined;
    return () => clearTimeout(timeout);
  }, [seconds]);

  const onResend = () => {
    onResendClick();
    setSeconds(RESEND_THRESHOLD);
  };
  const { t } = useTranslation();

  return (
    <div>
      <div className='row justify-content-center'>
        <div className='col-md-9  col-lg-9'>
          <div className='d-flex flex-column justify-content-center'>
            <div className='d-flex flex-column '>
              <img className='auth__page-icon' alt={'envelope-icon'} src={envelopeGlowIcon} />
              <p className='auth__page-title'>{t('Check your email')}</p>
              <p className='auth__help-text'>
                {t('We sent a password reset link to')} <strong>{userEmail}</strong> <br />
                {t('Please follow the instructions in the email to reset your password.')}
              </p>
            </div>
            <button className='btn btn-primary auth__btn-primary' onClick={() => onProcessDone()}>
              {t('Done')}
            </button>
            <p className='auth__help-text'>
              {t("Didn't receive the email?")}
              {seconds === 0 ? (
                <a
                  className='auth__link'
                  tabIndex={0}
                  role={'button'}
                  onClick={() => onResend()}
                  onKeyDown={(e) => e.key === 'Enter' && onResend()}
                >
                  {t('Click to resend')}
                </a>
              ) : (
                <span>
                  {' '}
                  {t("Didn't receive the email? Check your spam folder or please wait")}{' '}
                  <strong>
                    {seconds} {t('seconds')}
                  </strong>{' '}
                  {t('to resend!')}
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const ForgotPasswordEmailStep = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState<string>('');
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState<boolean>(false);
  const { t } = useTranslation();

  const sendRequest = (email: string) => {
    return dispatch(
      forgotPasswordThunk({
        username: email,
      }),
    ).unwrap();
  };

  const resendRequest = async () => {
    try {
      await sendRequest(userEmail);
    } catch (error: any) {
      let message = 'Something went wrong. Please try again.';
      if (error instanceof Error) message = error.message;
      alert.error(t(message), { toastId: 'forgot-password-error' });
    }
  };

  const setEmailAndSendRequest = async (formData: EmailFormData) => {
    const { email } = formData;
    setUserEmail(email);
    if (!email) return;
    try {
      await sendRequest(email);
      setHasBeenSubmitted(true);
    } catch (error: any) {
      let message = 'Something went wrong. Please try again.';
      if (error instanceof Error) message = error.message;
      setHasBeenSubmitted(false);
      alert.error(t(message), { toastId: 'set-email-request-error' });
    }
  };

  const onProcessDone = () => navigate('/');

  return !hasBeenSubmitted ? (
    <EmailFormScreen onSubmitEmail={setEmailAndSendRequest} />
  ) : (
    <SuccessScreen
      userEmail={userEmail}
      onProcessDone={onProcessDone}
      onResendClick={resendRequest}
    />
  );
};

export default ForgotPasswordEmailStep;
