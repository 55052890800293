import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { FunctionComponent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import eyeOffIcon from '../../../assets/images/icons/eye-off.svg';
import eyeIcon from '../../../assets/images/icons/eye.svg';
import LoaderButton from '../../../components/common/LoaderButton';
import Input from '../../../components/Input';
import Label from '../../../components/Label';
import { useAppDispatch, useAppSelector } from '../../../store';
import alert from '../../../utils/alert';
import { clearAuthState, loginUserThunk } from '../api/authSlice';
import { LoginSchema } from '../schemas';

type LoginFormData = {
  email: string;
  password: string;
};

export const LoginForm: FunctionComponent = () => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isValid, dirtyFields },
  } = useForm<LoginFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(LoginSchema),
  });

  const { t }: any = useTranslation();
  const navigate = useNavigate();
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const [inputFieldType, setInputFieldType] = useState('password');
  const togglePasswordVisibility = () => {
    setInputFieldType(inputFieldType === 'text' ? 'password' : 'text');
  };

  const onSubmit = async (data: LoginFormData) => {
    try {
      const { email, password } = data;
      const res = await dispatch(loginUserThunk({ username: email, password })).unwrap();
      if (res && res.newPasswordRequired) {
        navigate('/auth/reset-password?newPasswordRequired=true');
        return;
      }
      if (res.status === 'success') navigate('/');
    } catch (error: Error | any) {
      if (error.errorName === 'NotAuthorizedException') {
        reset({ password: '' });
      }
      let message = 'Something went wrong. Please try again.';
      if (error instanceof Error) message = error.message;
      alert.error(t(message), { toastId: 'login-error' });
    }
  };

  useEffect(() => {
    dispatch(clearAuthState());
  }, [dispatch]);

  return (
    <div className='login-wrapper d-flex flex-column justify-content-center'>
      <div className='d-flex flex-column align-items-start'>
        <h2 className='auth__page-title'>{t('Login')}</h2>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className={'pt-2'}>
        <Form.Group controlId='email' className='auth__input-group'>
          <Label>{t('Email')}</Label>
          <Input
            type='text'
            placeholder={t('Email')}
            className={clsx(
              [errors.email && 'is-invalid'],
              [dirtyFields.email && !errors.email ? 'is-valid auth__input-no-bootstrap-icon' : ''],
            )}
            {...register('email')}
          />
          {errors.email && <div className='invalid-feedback'>{errors.email.message}</div>}
        </Form.Group>

        <Form.Group controlId='password' className='auth__input-group auth__input-group--relative'>
          <Label>{t('Password')}</Label>
          <Input
            placeholder={t('Password')}
            type={inputFieldType}
            className={clsx('auth__input-no-bootstrap-icon', [
              dirtyFields.password && errors.password && 'is-invalid',
            ])}
            data-testid={'password-input'}
            {...register('password')}
          />
          {errors.password && <div className='invalid-feedback'>{errors.password.message}</div>}
          <span
            role={'button'}
            tabIndex={0}
            onKeyDown={(e) => e.key === 'Enter' && togglePasswordVisibility()}
            className='auth__input-group-icon'
            onClick={togglePasswordVisibility}
          >
            {inputFieldType === 'password' ? (
              <img src={eyeOffIcon} width={20} height={20} alt='eye-off-icon' />
            ) : (
              <img src={eyeIcon} width={20} height={20} alt='eye-icon' />
            )}
          </span>
        </Form.Group>

        <div className='d-flex flex-column align-items-center'>
          <p className='auth__help-text'>
            <Link className='auth__link text-primary-700' to={'/auth/forgot-password'}>
              {t('Forgot Password?')}
            </Link>
          </p>
          <LoaderButton
            className={`auth__btn-primary`}
            type='submit'
            onClick={handleSubmit(onSubmit)}
            isSubmitting={auth.isLoading}
            disabled={!isValid}
          >
            {t('Login')}
          </LoaderButton>

          {/* <div className='already_have_account-container'>
            <p className='already_have_account-text'>{t("Don't have an account?")}</p>
            <Link
              to='/auth/register'
              className='already_have_account-create_account_text text-primary-700'
              data-testid='login-createAccount-link'
            >
              {t('Create Account')}
            </Link>
          </div> */}
        </div>
      </form>
      <div className='auth__find-me'>
        <p className='auth__find-me--text'>{t('Is this being provided by your employer?')}</p>
        <p className='auth__help-text'>
          <Link className='auth__link text-primary-700' to={'/auth/find-me'}>
            {t('Find Me')}
          </Link>
        </p>
      </div>
    </div>
  );
};
