/* eslint-disable @typescript-eslint/no-unused-vars */ // REMOVE THIS AFTER COMPONENT COMPLETION!!
import { FunctionComponent, useState } from 'react';

// TYPES
import { APIResponseStatus } from '../../../../api/types';

// COMPONENTS
import { Step, Stepper } from 'react-form-stepper';
import { ConfirmInfoScreen } from './ConfirmInfoScreen';
import { AccountStatusScreen } from './AccountStatusScreen';
import { OtpVerificationScreen } from './OtpVerificationScreen';
import { CreateAccountScreen } from './CreateAccountScreen';
import { AccountCreatedScreen } from './AccountCreatedScreen';

export interface FindMeFormData {
  lastName: string;
  dob: Date;
  phoneNumber: string;
}

export interface UserInfo {
  email: string;
  password: string;
}

export const FindMe: FunctionComponent = () => {
  // Response of account lookup
  const [employeeInfo, setEmployeeInfo] = useState<APIResponseStatus>();

  // email/username and password of user
  const [userInfo, setUserInfo] = useState<UserInfo>({
    email: '',
    password: '',
  });

  // ConfirmInfoScreen form data
  const [lookupUserFormData, setLookupUserFormData] = useState<FindMeFormData>();

  // final ver codes of user
  const [verCodeResUUID, setVerCodeResUUID] = useState<string>('');
  const [verCode, setVerCode] = useState<string>('');

  const [step, setStep] = useState(0);

  const stepForward = () => {
    setStep(step + 1);
  };

  const StepRenderer: FunctionComponent = () => {
    switch (step) {
      case 0:
        return (
          <ConfirmInfoScreen
            setEmployeeInfo={setEmployeeInfo}
            lookupUserFormData={lookupUserFormData}
            setLookupUserFormData={setLookupUserFormData}
            stepForward={stepForward}
            setUserInfo={setUserInfo}
          />
        );

      case 1:
        return (
          <AccountStatusScreen
            employeeInfo={employeeInfo}
            setVerCodeResUUID={setVerCodeResUUID}
            stepForward={stepForward}
          />
        );
      case 2:
        return (
          <OtpVerificationScreen
            stepForward={stepForward}
            employeeInfo={employeeInfo}
            verCodeResUUID={verCodeResUUID}
            verCode={verCode}
            setVerCode={setVerCode}
          />
        );
      case 3:
        return (
          <CreateAccountScreen
            stepForward={stepForward}
            setUserInfo={setUserInfo}
            userInfo={userInfo}
            verCodeResUUID={verCodeResUUID}
            verCode={verCode}
            employeeInfo={employeeInfo}
          />
        );
      case 4:
        return <AccountCreatedScreen userInfo={userInfo} />;
      default:
        return (
          <ConfirmInfoScreen
            setEmployeeInfo={setEmployeeInfo}
            lookupUserFormData={lookupUserFormData}
            setLookupUserFormData={setLookupUserFormData}
            stepForward={stepForward}
            setUserInfo={setUserInfo}
          />
        );
    }
  };

  return (
    <div className='login-wrapper d-flex flex-column flex-start'>
      <Stepper
        activeStep={step}
        styleConfig={{
          activeBgColor: '#0ba5ec',
          activeTextColor: '#ffffff',
          completedBgColor: '#0ba5ec',
          completedTextColor: '#ffffff',
          inactiveBgColor: '#e0e0e0',
          inactiveTextColor: '#ffffff',
          size: '32px',
          circleFontSize: '16px',
          labelFontSize: '12px',
          borderRadius: '50%',
          fontWeight: 500,
        }}
        style={{ padding: 0, marginBottom: 30, marginTop: 12 }}
        className='animated-stepper'
      >
        <Step onClick={() => setStep(0)} />
        <Step />
        <Step />
        <Step />
        <Step />
      </Stepper>

      <StepRenderer />
    </div>
  );
};
