import termsAndCondition from './termsAndCondition.json';
import PasswordUpdatedSuccess from './passwordUpdatedSuccess.json';
import SetNewPassword from './setNewPassword.json';
import temporaryPassword from './temporaryPassword.json';
import login from './login.json';
import forgotPassword from './forgotPassword.json';
import forgotPasswordSuccess from './forgotPasswordSuccess.json';
import createdAccountSuccess from './createdAccountSuccess.json';
import biometric from './biometric.json';
import register from './register.json';
import landingScreen from './landingScreen.json';
import membershipLookup from './membershipLookup.json';
import validationMessages from './validationMessages.json';
import CalendarCar from './CalendarCar.json';
import ConfirmPhone from './ConfirmPhone.json';
import Consent from './Consent.json';
import Dropdown from './Dropdown.json';
import MediaFile from './MediaFile.json';
import Questionnaire from './Questionnaire.json';
import QuestionnaireConsentConfirm from './QuestionnaireConsentConfirm.json';
import QuestionnaireSubmit from './QuestionnaireSubmit.json';
import Review from './Review.json';
import SelectPatient from './SelectPatient.json';
import SelectPlan from './SelectPlan.json';
import SelectProvider from './SelectProvider.json';
import SelectVisitReason from './SelectVisitReason.json';
import VisitBooking from './VisitBooking.json';
import VisitConfirmed from './VisitConfirmed.json';
import WaitingRoom from './WaitingRoom.json';
import ReviewIntake from './ReviewIntake.json';
import ReviewIntakeItem from './ReviewIntakeItem.json';
import visit from './visit.json';
import VisitDetails from './VisitDetails.json';
import VisitItem from './VisitItem.json';
import bottomBar from './bottomBar.json';
import getCare from './getCare.json';
import householdMembers from './householdMembers.json';
import messages from './messages.json';
import modalActions from './modalActions.json';
import payments from './payments.json';
import settings from './settings.json';
import backendMessages from './backendMessages.json';

const enCombined = {
  ...termsAndCondition,
  ...PasswordUpdatedSuccess,
  ...SetNewPassword,
  ...temporaryPassword,
  ...login,
  ...forgotPassword,
  ...createdAccountSuccess,
  ...forgotPasswordSuccess,
  ...biometric,
  ...register,
  ...landingScreen,
  ...membershipLookup,
  ...validationMessages,
  ...CalendarCar,
  ...ConfirmPhone,
  ...Consent,
  ...Dropdown,
  ...MediaFile,
  ...Questionnaire,
  ...QuestionnaireConsentConfirm,
  ...QuestionnaireSubmit,
  ...Review,
  ...SelectPatient,
  ...SelectPlan,
  ...SelectProvider,
  ...SelectVisitReason,
  ...VisitBooking,
  ...VisitConfirmed,
  ...WaitingRoom,
  ...ReviewIntake,
  ...ReviewIntakeItem,
  ...visit,
  ...VisitDetails,
  ...VisitItem,
  ...bottomBar,
  ...getCare,
  ...householdMembers,
  ...messages,
  ...modalActions,
  ...payments,
  ...settings,
  ...backendMessages,
};

export default enCombined;
