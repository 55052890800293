import { AxiosResponse } from 'axios';

import { API } from '../../utils/axios';
import { APIResponseStatus } from '../types';
import { AccountUser } from '../../features/account/types';

class AccountService {
  static getMe(uuid = '00000000-0000-0000-0000-000000000000') {
    return API.get<any, AxiosResponse<APIResponseStatus<AccountUser>>>(
      `v1/resources/Patient/${uuid}`,
    ).then((res) => res.data);
  }
}

export default AccountService;
