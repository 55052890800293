import { SYSTEM_ROLES, SYSTEM_ROLES_IDENTIFIERS } from '../config';

export const rolesFromFHIR4Identifier = (identifiers: fhir4.Identifier[]): string[] => {
  const roles: string[] = [];
  identifiers.forEach((identifier) => {
    if (identifier.system === SYSTEM_ROLES_IDENTIFIERS.ADMIN) {
      roles.push(SYSTEM_ROLES.ADMIN);
    }
    if (identifier.system === SYSTEM_ROLES_IDENTIFIERS.MANAGER) {
      roles.push(SYSTEM_ROLES.MANAGER);
    }
    if (identifier.system === SYSTEM_ROLES_IDENTIFIERS.PROVIDER) {
      roles.push(SYSTEM_ROLES.PROVIDER);
    }
  });
  return roles;
};

export const usernameFromFHIR4HumanName = (name?: fhir4.HumanName): string | undefined => {
  if (!name) return undefined;
  if (name.text && name.text.length > 0) return name.text;

  const nameArr: string[] = [];

  if (name.prefix && name.prefix.length > 0) {
    const prefix = name.prefix
      .map((n) => n)
      .join(' ')
      .trim();
    nameArr.push(prefix);
  }
  if (name.given && name.given.length > 0) {
    const given = name.given[0].trim();
    nameArr.push(given);
  }
  if (name.given && name.given.length > 1) {
    let middleInitial = name.given[1].trim();
    if (middleInitial) {
      middleInitial = middleInitial.charAt(0).toUpperCase() + '.';
      nameArr.push(middleInitial);
    }
  }
  if (name.family && name.family.length > 0) {
    const family = name.family.trim();
    nameArr.push(family);
  }
  if (name.suffix && name.suffix.length > 0) {
    const suffix = name.suffix
      .map((s) => s)
      .join(', ')
      .trim();
    nameArr.push(suffix);
  }

  return nameArr.join(' ');
};
