import { CommonDropdownType } from '../types';

const US_STATES = [
  {
    state: 'Alabama',
    abbrev: 'Ala.',
    code: 'AL',
  },
  {
    state: 'Alaska',
    abbrev: 'Alaska',
    code: 'AK',
  },
  {
    state: 'Arizona',
    abbrev: 'Ariz.',
    code: 'AZ',
  },
  {
    state: 'Arkansas',
    abbrev: 'Ark.',
    code: 'AR',
  },
  {
    state: 'California',
    abbrev: 'Calif.',
    code: 'CA',
  },
  {
    state: 'Colorado',
    abbrev: 'Colo.',
    code: 'CO',
  },
  {
    state: 'Connecticut',
    abbrev: 'Conn.',
    code: 'CT',
  },
  {
    state: 'Delaware',
    abbrev: 'Del.',
    code: 'DE',
  },
  {
    state: 'District of Columbia',
    abbrev: 'D.C.',
    code: 'DC',
  },
  {
    state: 'Florida',
    abbrev: 'Fla.',
    code: 'FL',
  },
  {
    state: 'Georgia',
    abbrev: 'Ga.',
    code: 'GA',
  },
  {
    state: 'Hawaii',
    abbrev: 'Hawaii',
    code: 'HI',
  },
  {
    state: 'Idaho',
    abbrev: 'Idaho',
    code: 'ID',
  },
  {
    state: 'Illinois',
    abbrev: 'Ill.',
    code: 'IL',
  },
  {
    state: 'Indiana',
    abbrev: 'Ind.',
    code: 'IN',
  },
  {
    state: 'Iowa',
    abbrev: 'Iowa',
    code: 'IA',
  },
  {
    state: 'Kansas',
    abbrev: 'Kans.',
    code: 'KS',
  },
  {
    state: 'Kentucky',
    abbrev: 'Ky.',
    code: 'KY',
  },
  {
    state: 'Louisiana',
    abbrev: 'La.',
    code: 'LA',
  },
  {
    state: 'Maine',
    abbrev: 'Maine',
    code: 'ME',
  },
  {
    state: 'Maryland',
    abbrev: 'Md.',
    code: 'MD',
  },
  {
    state: 'Massachusetts',
    abbrev: 'Mass.',
    code: 'MA',
  },
  {
    state: 'Michigan',
    abbrev: 'Mich.',
    code: 'MI',
  },
  {
    state: 'Minnesota',
    abbrev: 'Minn.',
    code: 'MN',
  },
  {
    state: 'Mississippi',
    abbrev: 'Miss.',
    code: 'MS',
  },
  {
    state: 'Missouri',
    abbrev: 'Mo.',
    code: 'MO',
  },
  {
    state: 'Montana',
    abbrev: 'Mont.',
    code: 'MT',
  },
  {
    state: 'Nebraska',
    abbrev: 'Nebr.',
    code: 'NE',
  },
  {
    state: 'Nevada',
    abbrev: 'Nev.',
    code: 'NV',
  },
  {
    state: 'New Hampshire',
    abbrev: 'N.H.',
    code: 'NH',
  },
  {
    state: 'New Jersey',
    abbrev: 'N.J.',
    code: 'NJ',
  },
  {
    state: 'New Mexico',
    abbrev: 'N.M.',
    code: 'NM',
  },
  {
    state: 'New York',
    abbrev: 'N.Y.',
    code: 'NY',
  },
  {
    state: 'North Carolina',
    abbrev: 'N.C.',
    code: 'NC',
  },
  {
    state: 'North Dakota',
    abbrev: 'N.D.',
    code: 'ND',
  },
  {
    state: 'Ohio',
    abbrev: 'Ohio',
    code: 'OH',
  },
  {
    state: 'Oklahoma',
    abbrev: 'Okla.',
    code: 'OK',
  },
  {
    state: 'Oregon',
    abbrev: 'Ore.',
    code: 'OR',
  },
  {
    state: 'Pennsylvania',
    abbrev: 'Pa.',
    code: 'PA',
  },
  {
    state: 'Rhode Island',
    abbrev: 'R.I.',
    code: 'RI',
  },
  {
    state: 'South Carolina',
    abbrev: 'S.C.',
    code: 'SC',
  },
  {
    state: 'South Dakota',
    abbrev: 'S.D.',
    code: 'SD',
  },
  {
    state: 'Tennessee',
    abbrev: 'Tenn.',
    code: 'TN',
  },
  {
    state: 'Texas',
    abbrev: 'Tex.',
    code: 'TX',
  },
  {
    state: 'Utah',
    abbrev: 'Utah',
    code: 'UT',
  },
  {
    state: 'Vermont',
    abbrev: 'Vt.',
    code: 'VT',
  },
  {
    state: 'Virginia',
    abbrev: 'Va.',
    code: 'VA',
  },
  {
    state: 'Washington',
    abbrev: 'Wash.',
    code: 'WA',
  },
  {
    state: 'West Virginia',
    abbrev: 'W.Va.',
    code: 'WV',
  },
  {
    state: 'Wisconsin',
    abbrev: 'Wis.',
    code: 'WI',
  },
  {
    state: 'Wyoming',
    abbrev: 'Wyo.',
    code: 'WY',
  },
];

const US_STATES_DROPDOWN_SHORT: CommonDropdownType[] = US_STATES.map((s) => ({
  value: s.code,
  label: s.code,
}));

const US_STATES_DROPDOWN_LONG: CommonDropdownType[] = US_STATES.map((s) => ({
  value: s.code,
  label: `${s.code} - ${s.state}`,
}));

export { US_STATES, US_STATES_DROPDOWN_SHORT, US_STATES_DROPDOWN_LONG };
