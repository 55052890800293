import { Spinner } from 'react-bootstrap';

const LoadingIndicator = () => (
  <div className='flex-grow-1 d-flex w-100 vh-100 justify-content-center align-items-center'>
    <Spinner animation='border' role='status'>
      <span className='visually-hidden'>Loading...</span>
    </Spinner>
  </div>
);

export default LoadingIndicator;
