import './CancelActionModal.scss';

import { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import { Button, Modal, ModalProps } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';

import Select from './Select';
import warningGlowIcon from '../../../assets/images/icons/warning-glow.svg';
import { CommonDropdownType } from '../../../types';
import TextArea from './TextArea';
import { useTranslation } from 'react-i18next';

interface CancelActionModalProps extends ModalProps {
  onConfirm?: (e?: SyntheticEvent, reason?: string, additionalDetails?: string) => void;
  onCancel?: (e?: SyntheticEvent) => void;
  title: string;
  helpText?: string;
  cancelText?: string;
  confirmText?: string;
  isFromWaitingRoom?: boolean;
  pickerOptions?: any;
}

const OTHER = 'other';

const CancelActionModal: FunctionComponent<CancelActionModalProps> = ({
  onConfirm,
  onCancel,
  title,
  helpText,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  isFromWaitingRoom = false,
  pickerOptions,
  ...props
}) => {
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(true);
  const { t } = useTranslation();

  const { control, watch, reset } = useForm({
    defaultValues: {
      cancelationReason: undefined as CommonDropdownType | undefined,
      value: undefined as string | undefined,
      description: undefined as string | undefined,
    },
  });

  const cancelationReasonValue = watch('cancelationReason')?.value;
  const additionalDetails = watch('description');

  useEffect(() => {
    if (cancelationReasonValue !== undefined) {
      if (cancelationReasonValue === OTHER) {
        setIsConfirmDisabled(true);
        if (additionalDetails && additionalDetails.length > 5) {
          setIsConfirmDisabled(false);
        }
      } else {
        setIsConfirmDisabled(false);
      }
    }
  }, [cancelationReasonValue, additionalDetails]);

  const onHide = () => {
    reset();
    setIsConfirmDisabled(true);
    onCancel?.();
  };

  return (
    <Modal
      {...props}
      backdrop='static'
      keyboard={false}
      fullscreen={'md-down'}
      centered
      contentClassName='cancel-modal__content'
      dialogClassName='cancel-modal__dialog'
      onHide={onHide}
    >
      <Modal.Header closeButton className='cancel-modal__header'>
        <Modal.Title>
          <img src={warningGlowIcon} alt='' />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='cancel-modal__body'>
        <h4 className='cancel-modal__title'> {t(title)}</h4>
        <p>{helpText}</p>
        {isFromWaitingRoom && (
          <div>
            <h4 className='cancel-modal__reason_title'>{t('Select Reason for Cancellation')}</h4>
            <Controller
              name='cancelationReason'
              control={control}
              render={({ field: { ...rest } }) => (
                <Select
                  {...rest}
                  placeholder={'Select'}
                  id='cancelation-reason-select'
                  name='cancelation-reason'
                  options={pickerOptions}
                />
              )}
            />
          </div>
        )}

        {cancelationReasonValue === OTHER && (
          <div>
            <Controller
              name='description'
              control={control}
              render={({ field }) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { ref, ...nonRefField } = field;
                return (
                  <div>
                    <h4 className='cancel-modal__details_title'>{t('Additional Details')}</h4>
                    <TextArea
                      id='service-new-description'
                      placeholder={'Enter a description'}
                      rows={5}
                      {...nonRefField}
                    />
                  </div>
                );
              }}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className='cancel-modal__footer'>
        <Button
          onClick={(e) => {
            props.onHide?.();
            onCancel?.(e);
            reset();
          }}
          style={{ fontSize: '15px' }}
          variant={'secondary'}
          className='cancel-modal__btn'
          type={'button'}
          data-testid='cancel-action'
        >
          {t(cancelText)}
        </Button>
        <Button
          onClick={(e) => {
            props.onHide?.();
            onConfirm?.(e, cancelationReasonValue, additionalDetails);
            reset();
          }}
          disabled={isFromWaitingRoom && isConfirmDisabled}
          style={{ fontSize: '15px' }}
          variant={'primary'}
          className='text-white cancel-modal__btn'
          type={'button'}
          data-testid='confirm-action'
        >
          {t(confirmText)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelActionModal;
