/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-named-as-default */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createAsyncThunk, createSlice, Middleware, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { env } from '../env';
import { createWSSessionAPIThunk } from '../features/auth/api/authSlice';

import { RootState } from '.';
type SocketConnectionState = {
  connected: boolean;
  connectionUrl: string;
  latestMessage: any;
};

const ACTIONS_PREFIX = 'socket';

function getDeviceId() {
  const LS_STRING = 'deviceId';
  const ls = localStorage.getItem(LS_STRING);
  if (ls) {
    return ls;
  } else {
    const newId = uuidv4();
    localStorage.setItem(LS_STRING, newId);
    return newId;
  }
}

let socket: WebSocket | null = null;
const deviceId: string = getDeviceId();
export const getSocket = (): WebSocket | null => {
  if (socket) return socket;
  return null;
};

export const createSocketConnection = createAsyncThunk(
  `${ACTIONS_PREFIX}/connect`,
  async (_, { dispatch }) => {
    const currentSocket = getSocket();
    if (currentSocket && currentSocket.readyState === WebSocket.OPEN) return;
    const { token } = await dispatch(
      createWSSessionAPIThunk({
        deviceId,
      }),
    ).unwrap();
    const wsUrl = `${env.REACT_APP_SOCKET_URL}?tk=${token}`;
    console.log('[io]: connecting', `${env.REACT_APP_SOCKET_URL}?tk=${token}`);
    socket = new WebSocket(wsUrl);
    dispatch(socketSlice.actions.setConnectionUrl(wsUrl));
    dispatch(socketSlice.actions.connect(true));
    return { socket: socket, url: wsUrl, token: token };
  },
);

// let wsReq = {
//   reqType: 'visit',
//   reqId: utils.uuidv4(),
//   visitParams: cp
// };

export const sendSocketMessageThunk = createAsyncThunk<
  any,
  {
    reqType: string;
    reqId: string;
    visitParams?: any;
    messagingParams?: any;
  }
>(`${ACTIONS_PREFIX}/sendMessage`, async (payload) => {
  const socket = getSocket();
  if (!socket || socket.readyState !== WebSocket.OPEN) {
    throw new Error('Socket connection not open');
  }
  socket.send(JSON.stringify(payload));
});

export const sendSocketPingThunk = createAsyncThunk(`${ACTIONS_PREFIX}/sendPing`, async () => {
  const socket = getSocket();
  if (!socket || socket.readyState !== WebSocket.OPEN) {
    throw new Error('Socket connection not open');
  }
  return new Promise((resolve) => {
    socket.send('wsPing');
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      resolve(data);
    };
  });
});

const socketSlice = createSlice({
  name: 'socket',
  initialState: {
    connected: false,
    connectionUrl: '',
    latestMessage: null,
  } as SocketConnectionState,
  reducers: {
    connect: (state, { payload }: PayloadAction<boolean>) => {
      state.connected = payload;
    },
    setConnectionUrl: (state, { payload }: PayloadAction<string>) => {
      state.connectionUrl = payload;
    },
    messageReceived: (state, { payload }: PayloadAction<any>) => {
      state.latestMessage = payload;
    },
    disconnect: () => {},
  },
});

export const socketMiddleware: Middleware = (store) => (next) => (action) => {
  socket = socket || null;
  if (socketSlice.actions.connect.match(action)) {
    // TODO: improve this logic
    if (!socket) {
      store.dispatch(createSocketConnection() as any);
      return next(action);
    }
    socket.addEventListener('open', (event: any) => {
      // console.log('[io]: connect', event?.id);
      store.dispatch(socketSlice.actions.connect(true));
    });
    //socket.onmessage
    socket.addEventListener('message', (event: any) => {
      // console.log('[io]: Incoming message: ', event?.data);
      store.dispatch(socketSlice.actions.messageReceived(event?.data));
    });
    socket.addEventListener('close', (event: any) => {
      // console.log('[io]: disconnect', event?.id);
      store.dispatch(socketSlice.actions.connect(false));
    });
  }
  if (socketSlice.actions.disconnect.match(action)) {
    socket?.close();
    socket = null;
  }
  return next(action);
};

export const selectSocketState = (state: RootState) => state.socket;
export const { disconnect } = socketSlice.actions;
export default socketSlice.reducer;
