import { formatInTimeZone } from 'date-fns-tz';
import { useEffect, useMemo, useState, useRef, useCallback, FunctionComponent } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '../../../assets/images/icons/menu.svg';

import myClinicIcon from '../../../assets/images/icons/hospital.svg';
import logoutIcon from '../../../assets/images/icons/logout.svg';
import messageCenterIcon from '../../../assets/images/icons/messagecenter.svg';
import myAccountIcon from '../../../assets/images/icons/myaccount.svg';
import pastVisitsIcon from '../../../assets/images/icons/pastvisits.svg';
import patientListIcon from '../../../assets/images/icons/patientlist.svg';
import patientQueueIcon from '../../../assets/images/icons/patientqueue.svg';
import leftRightArrowsImg from '../../../assets/left-right-arrows-circle.svg';
import logoImg from '../../../assets/logo-dark-md.svg';
import logoMark from '../../../assets/logo-short.svg';
import AvatarPlaceholder from '../../../components/common/AvatarPlaceholder';
import CustomModal from '../../../components/modal/CustomModal';
import { env } from '../../../env';
import { useAppDispatch, useAppSelector } from '../../../store';
import { usernameFromFHIR4HumanName } from '../../../utils/general';
import {
  selectAccountState,
  selectPractitionerTimezone,
  toggleMenuCollapsed,
} from '../../account/api/accountSlice';
import { logoutUserThunk } from '../../auth/api/authSlice';
import { APP_LAYOUT_ROUTES, DEFAULT_APP_LAYOUT_ROUTE } from '../routes';

import './Sidebar.scss';
import { useTranslation } from 'react-i18next';

const sidebarItems = [
  { icon: patientQueueIcon, ...APP_LAYOUT_ROUTES.TODAYS_VISITS },
  { icon: pastVisitsIcon, ...APP_LAYOUT_ROUTES.PAST_VISITS },
  { icon: messageCenterIcon, ...APP_LAYOUT_ROUTES.MESSAGES },
  { icon: patientListIcon, ...APP_LAYOUT_ROUTES.PATIENTS },
  { icon: myAccountIcon, ...APP_LAYOUT_ROUTES.MY_ACCOUNT },
  { icon: myClinicIcon, ...APP_LAYOUT_ROUTES.MY_CLINIC },
];

const SidebarItem = (props: {
  title: string;
  path: string;
  icon: string;
  isMenuCollapsed: boolean;
  closeSidebar: () => void;
}) => {
  const { pathname } = useLocation();
  const isActive = pathname.includes(`/app/${props.path}`);
  const itemRef = useRef(null);

  const handleClick = () => {
    props.closeSidebar();
  };

  return (
    <div
      className={`app__sidebar-item ${isActive ? 'app__sidebar-item--active' : ''} `}
      ref={itemRef}
    >
      {!props.isMenuCollapsed ? (
        <>
          <img src={props.icon} alt='patient queue icon' className='app__sidebar-item-icon' />
          <Link className='app__sidebar-item-link' to={props.path} onClick={handleClick}>
            {props.title}
          </Link>
        </>
      ) : (
        <OverlayTrigger
          container={itemRef}
          rootClose={false}
          trigger={['hover', 'focus']}
          placement='right'
          overlay={<Tooltip className='text-gray-700'>{props.title}</Tooltip>}
        >
          <Link
            className='app__sidebar-item-link'
            to={props.path}
            style={{ height: '26px' }}
            onClick={handleClick}
          >
            <img src={props.icon} alt='patient queue icon' className='app__sidebar-item-icon' />
          </Link>
        </OverlayTrigger>
      )}
    </div>
  );
};

const SidebarMenu = (props: { isMenuCollapsed: boolean; closeSidebar: () => void }) => {
  return (
    <div>
      {sidebarItems.map((item) => {
        return (
          <SidebarItem
            {...item}
            key={item.title}
            isMenuCollapsed={props.isMenuCollapsed}
            closeSidebar={props.closeSidebar}
          />
        );
      })}
    </div>
  );
};

const SidebarCard = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const timezone = useAppSelector(selectPractitionerTimezone);
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(new Date());
    }, 60 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className='app__sidebar-card'>
      <div>
        <div className='app__sidebar-card-time-date'>
          {formatInTimeZone(currentDate, timezone, 'hh:mm a')} |{' '}
          {formatInTimeZone(currentDate, timezone, 'MMM dd, yyyy')}
        </div>
        <div className='app__sidebar-card-time-zone'>
          {t('Local Time')} ({formatInTimeZone(currentDate, timezone, 'O')})
        </div>
      </div>
      {env.REACT_APP_VERSION !== '' && (
        <div className='w-100 d-flex flex-row justify-content-between align-items-center flex-wrap'>
          <p className='app__sidebar-card-version m-0'>Version: {env.REACT_APP_VERSION}</p>
          <p className='app__sidebar-card-copyright m-0'>&copy; Direct2Care</p>
        </div>
      )}
    </div>
  );
};

const SidebarProfile = (props: { setShowLogoutModal: (show: boolean) => void }) => {
  const { patient, avatar, sidebarMenuCollapsed } = useAppSelector(selectAccountState);
  const handleShowLogout = () => {
    props.setShowLogoutModal(true);
  };

  const practitionerName = useMemo(
    () => (patient?.name?.[0] ? usernameFromFHIR4HumanName(patient.name[0]) : ''),
    [patient?.name],
  );

  return (
    <div
      className={`app__sidebar-profile ${
        sidebarMenuCollapsed ? 'app__sidebar-profile-collapsed' : ''
      }`}
    >
      <div className='app__sidebar-profile-user-wrapper'>
        <button
          className='border-0 bg-transparent p-0'
          onClick={sidebarMenuCollapsed ? handleShowLogout : undefined}
        >
          {!avatar ? (
            <AvatarPlaceholder
              style={{ width: 40, height: 40 }}
              stringToUse={patient?.name?.[0]?.given?.[0] || patient?.name?.[0]?.family}
              className={`app__sidebar-profile-avatar ${
                sidebarMenuCollapsed ? 'app__sidebar-profile-avatar-collapsed' : ''
              } `}
            />
          ) : (
            <img
              src={avatar?.url}
              className={`app__sidebar-profile-avatar ${
                sidebarMenuCollapsed ? 'app__sidebar-profile-avatar-collapsed' : ''
              }`}
              alt='avatar'
            />
          )}
        </button>
        {!sidebarMenuCollapsed && <p className='app__sidebar-profile-name'>{practitionerName}</p>}
      </div>
      {!sidebarMenuCollapsed && (
        <button className='border-0 bg-transparent p-0' onClick={handleShowLogout}>
          <img src={logoutIcon} alt='logout btn' />
        </button>
      )}
    </div>
  );
};

const Sidebar: FunctionComponent = () => {
  const { sidebarMenuCollapsed } = useAppSelector(selectAccountState);
  const MOBILE_BREAKPOINT = 768;
  const [showConfirmMessageDialog, setShowConfirmMessageDialog] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < MOBILE_BREAKPOINT);
  const [showSidebar, setShowSidebar] = useState(false);
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLDivElement | null>(null);

  const handleSidebarChange = useCallback(() => {
    setShowSidebar((prev) => !prev);
  }, []);

  const closeSidebar = useCallback(() => {
    setShowSidebar(false);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_BREAKPOINT);
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node) &&
        headerRef.current &&
        !headerRef.current.contains(event.target as Node) &&
        showSidebar
      ) {
        closeSidebar();
      }
    };

    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeSidebar, showSidebar]);

  const dispatch = useAppDispatch();
  const logout = () => dispatch(logoutUserThunk());

  return (
    <>
      {isMobile && (
        <div
          className={`app__sidebar-header d-md-none ${showSidebar ? 'is-active' : ''}`}
          ref={headerRef}
        >
          <Link
            className='bg-transparent border-0 p-0'
            to={DEFAULT_APP_LAYOUT_ROUTE}
            onClick={closeSidebar}
          >
            <img
              className={`app__sidebar-brand${sidebarMenuCollapsed ? '-collapsed' : ''}`}
              src={sidebarMenuCollapsed ? logoMark : logoImg}
              alt='sidebar-logo'
            />
          </Link>
          <button className='app__sidebar-btn' onClick={handleSidebarChange}>
            <img src={MenuIcon} alt='' />
          </button>
        </div>
      )}
      <nav
        ref={sidebarRef}
        className={`d-md-flex app__sidebar app__sidebar${sidebarMenuCollapsed ? '-collapsed' : ''} ${
          showSidebar ? 'is-sidebar-active' : ''
        }`}
      >
        <div
          role='button'
          tabIndex={0}
          onClick={() => dispatch(toggleMenuCollapsed())}
          onKeyDown={(e) => e.key === 'Enter' && dispatch(toggleMenuCollapsed())}
          className={`app__sidebar__collapsed-wrapper ${
            sidebarMenuCollapsed ? 'app__sidebar__collapsed-wrapper-active' : ''
          }`}
        >
          <img
            className='app__sidebar__collapsed-icon'
            src={leftRightArrowsImg}
            alt='sidebar-toggle'
          />
        </div>
        <div className='d-flex flex-column justify-content-between flex-grow-1'>
          <div>
            <div
              className={`app__sidebar-brandwrap ${sidebarMenuCollapsed ? 'app__sidebar-link' : ''}`}
            >
              <Link
                className='bg-transparent border-0 p-0'
                to={DEFAULT_APP_LAYOUT_ROUTE}
                onClick={closeSidebar}
              >
                <img
                  className={`app__sidebar-brand${sidebarMenuCollapsed ? '-collapsed' : ''}`}
                  src={sidebarMenuCollapsed ? logoMark : logoImg}
                  alt='sidebar-logo'
                />
              </Link>
            </div>
            <SidebarMenu isMenuCollapsed={sidebarMenuCollapsed} closeSidebar={closeSidebar} />
          </div>
          {!sidebarMenuCollapsed && <SidebarCard />}
        </div>

        <div>
          <div className='app__sidebar-divider'></div>
          <SidebarProfile setShowLogoutModal={setShowConfirmMessageDialog} />
        </div>
        <CustomModal
          size={'sm'}
          show={showConfirmMessageDialog}
          title='Logout'
          helpText='Are you sure you want logout?'
          confirmText='Logout'
          cancelText='Cancel'
          handleConfirm={() => {
            setShowConfirmMessageDialog(false);
            logout();
          }}
          handleCancel={() => {
            setShowConfirmMessageDialog(false);
          }}
        />
      </nav>
    </>
  );
};

export default Sidebar;
