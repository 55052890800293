import { useEffect, useRef } from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { useSentryRoutes } from '..';
import useAuth from '../hooks/useAuth';
import { useAppDispatch } from '../store';
import { clearError } from '../store/error/error.slice';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

export const AppRoutes = () => {
  const navigate = useRef(useNavigate());
  const dispatch = useAppDispatch();
  const { isAuthenticating, authStatus } = useAuth({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onAuthStatusChange: (newStatus) => {
      console.log('** AUTH STATUS CHANGED **', newStatus);
      navigate.current('/');
    },
  });

  useEffect(() => {
    dispatch(clearError());
  }, [dispatch]);

  const commonRoutes: any = [];
  const routes = authStatus === 'signedIn' ? protectedRoutes : publicRoutes;
  const element = useSentryRoutes([...routes, ...commonRoutes]);

  if (isAuthenticating || authStatus === undefined) {
    return (
      <div className='d-flex flex-column min-vh-100 min-vw-100'>
        <div className='d-flex flex-grow-1 justify-content-center align-items-center'>
          <Spinner animation='border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
        </div>
      </div>
    );
  }
  return element;
};
