import React, { useCallback, useEffect, useRef, useState, FunctionComponent } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import './CreateAccount.scss';
import LoaderButton from '../../../components/common/LoaderButton';
import { RegisterSchema } from '../schemas/registerSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  GOOGLE_JS_API_LOADER_CONFIG,
  extractDataFromGooglePlaceResult,
} from '../../../utils/google';
import asterixIcon from '../../../assets/images/icons/asterix.png';
import { CommonDropdownType } from '../../../types';
import { US_STATES_DROPDOWN_SHORT } from '../../../utils/usStates';
import Select from '../../app/components/Select';
import registerFromToParams from '../../../utils/registerForm';
import { useAppDispatch } from '../../../store';
import alert from '../../../utils/alert';
import { createDeviceSessionThunk, registerUserThunk, searchUserThunk } from '../api/registerSlice';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import useCountries from '../../../utils/countries';
import { useNavigate } from 'react-router-dom';

type CreateAccountFormData = {
  email: string;
  phone: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  birthdate: Date;
  sex: { label: string; value: string };
  street: string;
  city: string;
  state: { label: string; value: string };
  zip: string;
  country: { label: string; value: string };
  unit?: string;
  termsAgreed: boolean;
};

export const CreateAccount: FunctionComponent = () => {
  const { isLoaded } = useJsApiLoader(GOOGLE_JS_API_LOADER_CONFIG);
  const states: CommonDropdownType[] = US_STATES_DROPDOWN_SHORT;
  const countries: CommonDropdownType[] = useCountries();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const [showTermsModal, setShowTermsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [phoneAvailable, setPhoneAvailable] = useState(true);
  const [checkingPhoneAvailability, setCheckingPhoneAvailability] = useState(false);
  const [emailAvailable, setEmailAvailable] = useState(true);
  const [checkingEmailAvailability, setCheckingEmailAvailability] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [emailChanged, setEmailChanged] = useState(false);
  const [phoneChanged, setPhoneChanged] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    setValue,
  } = useForm<CreateAccountFormData>({
    mode: 'onChange',
    resolver: yupResolver(RegisterSchema),
  });

  useEffect(() => {
    const createDeviceSession = async () => {
      if (!accessToken) {
        try {
          const deviceId = uuidv4();
          const resultAction = await dispatch(createDeviceSessionThunk(deviceId)).unwrap();
          if (resultAction.accessToken) {
            setAccessToken(resultAction.accessToken);
          } else {
            alert.error(t('We encountered an issue. Please try again.'), {
              toastId: 'signup-error',
            });
          }
        } catch (error) {
          alert.error(t('We encountered an issue. Please try again.'), {
            toastId: 'signup-error',
          });
        }
      }
    };

    createDeviceSession();
  }, [dispatch, accessToken, t]);

  useEffect(() => {
    if (emailChanged && phoneChanged) {
      setFormSubmitted(false);
      setEmailChanged(false);
      setPhoneChanged(false);
    }
  }, [emailChanged, phoneChanged]);

  const checkIfEmailOrPhoneAvailable = useCallback(
    (searchResult: any, value: string, type: string) => {
      const isEmail = type === 'email';
      const availabilityState = isEmail ? setEmailAvailable : setPhoneAvailable;
      const checkingState = isEmail ? setCheckingEmailAvailability : setCheckingPhoneAvailability;

      if (searchResult.entry && searchResult.entry.length > 0) {
        const item = searchResult.entry[0]?.resource?.telecom?.find(
          (item: { system: string; value: string }) => item.system === type,
        );
        availabilityState(!(item && item.value.toLowerCase() === value.toLowerCase()));
      } else {
        availabilityState(true);
      }
      checkingState(false);
    },
    [],
  );

  const searchEmailOrPhoneNumber = useCallback(
    async (value: string, type: string) => {
      const isEmail = type === 'email';
      const checkingState = isEmail ? setCheckingEmailAvailability : setCheckingPhoneAvailability;
      const availabilityState = isEmail ? setEmailAvailable : setPhoneAvailable;

      checkingState(true);
      setIsLoading(true);

      try {
        const searchBody = {
          searchData: { telecom: value, limit: 1 },
          accessToken: accessToken || '',
        };

        const resultAction = await dispatch(searchUserThunk(searchBody));

        if (searchUserThunk.fulfilled.match(resultAction)) {
          checkIfEmailOrPhoneAvailable(resultAction.payload, value, type);
        } else {
          availabilityState(true);
        }
      } catch (error) {
        availabilityState(true);
      } finally {
        setIsLoading(false);
        checkingState(false);
      }
    },
    [accessToken, dispatch, checkIfEmailOrPhoneAvailable],
  );

  const handlePhoneChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const phoneNumber = e.target.value;
      setValue('phone', phoneNumber, { shouldValidate: true });
      if (phoneNumber.length >= 10) {
        searchEmailOrPhoneNumber(phoneNumber, 'phone');
      } else {
        setPhoneAvailable(true);
      }
      setPhoneChanged(true);
    },
    [setValue, searchEmailOrPhoneNumber],
  );

  const handleEmailChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const email = event.target.value;
      setValue('email', email, { shouldValidate: true });
      if (email && email.includes('@')) {
        searchEmailOrPhoneNumber(email, 'email');
      } else {
        setEmailAvailable(true);
      }
      setEmailChanged(true);
    },
    [setValue, searchEmailOrPhoneNumber],
  );

  useEffect(() => {
    if (emailChanged && phoneChanged) {
      setFormSubmitted(false);
      setEmailChanged(false);
      setPhoneChanged(false);
    }
  }, [emailChanged, phoneChanged]);

  const onSubmit = async (data: CreateAccountFormData) => {
    setIsLoading(true);
    try {
      const params = registerFromToParams({
        email: data.email,
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        phone: data.phone,
        street: data.street,
        unit: data.unit,
        city: data.city,
        state: data.state.value,
        zip: data.zip,
        country: data.country.value,
        birthdate: data.birthdate.toISOString(),
        sex: data.sex.value,
      });

      const registerBody = {
        userData: params,
        accessToken: accessToken || '',
        callback: (success: boolean) => {
          if (success) {
            alert.success(
              t('Your account was successfully created, please click login to continue.'),
              { toastId: 'signup-success' },
            );
            setFormSubmitted(true);
            navigate('/');
          } else {
            alert.error(t('Registration failed. Please try again.'), { toastId: 'signup-error' });
          }
        },
      };

      await dispatch(registerUserThunk(registerBody)).unwrap();
    } catch (err) {
      alert.error(t('Registration failed. Please try again.'), { toastId: 'signup-error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePlaceChanged = () => {
    const place = autocompleteRef.current?.getPlace();
    const { city, state, zip, country, formattedAddress } = extractDataFromGooglePlaceResult(place);

    setValue('city', city?.long_name || '', { shouldValidate: true, shouldDirty: true });
    setValue('zip', zip?.long_name || '', { shouldValidate: true, shouldDirty: true });
    if (state) {
      setValue(
        'state',
        { label: state.long_name, value: state.short_name },
        { shouldValidate: true, shouldDirty: true },
      );
    }
    if (country) {
      setValue(
        'country',
        { label: country.long_name, value: country.short_name },
        { shouldValidate: true, shouldDirty: true },
      );
    }
    setValue('street', formattedAddress?.split(',')[0] || '', {
      shouldValidate: true,
      shouldDirty: true,
    });
  };
  return (
    <div className='create-account'>
      <div className='create-account__container'>
        <div className='create-account__header'>
          <h2 className='create-account__title'>{t('Sign Up')}</h2>
        </div>

        <div className='create-account__scrollable'>
          <Form className='create-account__form' onSubmit={handleSubmit(onSubmit)}>
            <div className='form-row'>
              <Form.Group className='form-group' controlId='formEmail'>
                <Form.Label>{t('Email')}</Form.Label>
                <Form.Control
                  type='email'
                  {...register('email', {
                    onChange: handleEmailChange,
                  })}
                />
                {errors.email?.message && (
                  <Form.Text className='text-danger'>{t(errors.email.message)}</Form.Text>
                )}
                {!checkingEmailAvailability && !emailAvailable && (
                  <Form.Text className='text-danger'>
                    {t('This Email is already registered.')}
                  </Form.Text>
                )}
              </Form.Group>
            </div>

            <div className='form-row'>
              <Form.Group className='form-group' controlId='formPhone'>
                <Form.Label>{t('Phone')}</Form.Label>
                <Form.Control
                  type='tel'
                  {...register('phone', {
                    onChange: handlePhoneChange,
                  })}
                />
                {errors.phone?.message && (
                  <Form.Text className='text-danger'>{t(errors.phone.message)}</Form.Text>
                )}
                {!checkingPhoneAvailability && !phoneAvailable && (
                  <Form.Text className='text-danger'>
                    {t('This Phone Number is already in use.')}
                  </Form.Text>
                )}
              </Form.Group>
            </div>

            <div className='form-row'>
              <Form.Group className='form-group' controlId='formFirstName'>
                <Form.Label> {t('First Name')}</Form.Label>
                <Form.Control type='text' {...register('firstName')} />
                {errors.firstName?.message && (
                  <Form.Text className='text-danger'>{t(errors.firstName.message)}</Form.Text>
                )}
              </Form.Group>

              <Form.Group className='form-group' controlId='formMiddleName'>
                <Form.Label>{t('Middle Name')}</Form.Label>
                <Form.Control type='text' {...register('middleName')} />
                {errors.middleName?.message && (
                  <Form.Text className='text-danger'>{t(errors.middleName.message)}</Form.Text>
                )}
              </Form.Group>

              <Form.Group className='form-group' controlId='formLastName'>
                <Form.Label>{t('Last Name')}</Form.Label>
                <Form.Control type='text' {...register('lastName')} />
                {errors.lastName?.message && (
                  <Form.Text className='text-danger'>{t(errors.lastName.message)}</Form.Text>
                )}
              </Form.Group>
            </div>

            <div className='form-row'>
              <Form.Group className='form-group d-flex flex-column mt-1' controlId='formDOB'>
                <Form.Label>{t('Date of Birth')}</Form.Label>
                <Controller
                  control={control}
                  name='birthdate'
                  render={({ field }) => (
                    <div className='datepicker-wrapper'>
                      <DatePicker
                        showIcon
                        onChange={(date: Date | null) => field.onChange(date)}
                        onBlur={field.onBlur}
                        selected={field.value}
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={100}
                        showMonthDropdown
                        dropdownMode='scroll'
                        maxDate={new Date()}
                        dateFormat='MM/dd/yyyy'
                        className='form-control datepicker-input'
                        calendarClassName='custom-datepicker-calendar'
                        id='update-user-birthDate'
                      />
                    </div>
                  )}
                />
                {errors.birthdate?.message && (
                  <Form.Text className='text-danger'>{t(errors.birthdate.message)}</Form.Text>
                )}
              </Form.Group>
              <Form.Group className='form-group' controlId='formSex'>
                <Form.Label>{t('Sex')}</Form.Label>
                <Controller
                  name='sex'
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={[
                        { value: 'male', label: t('Male') },
                        { value: 'female', label: t('Female') },
                        { value: 'other', label: t('Other') },
                      ]}
                      hideSelectedOptions={false}
                      controlShouldRenderValue={true}
                      name='sex'
                      id='sex-select'
                      inputId='sex-input'
                      menuPlacement='top'
                      placeholder={t('Select')}
                    />
                  )}
                />
                {errors.sex?.message && (
                  <Form.Text className='text-danger'>{t(errors.sex.message)}</Form.Text>
                )}
              </Form.Group>
            </div>

            <div className='form-row'>
              <Form.Group className='form-group form-group--large' controlId='formStreet'>
                <Form.Label>{t('Street')}</Form.Label>
                {isLoaded && (
                  <Autocomplete
                    onLoad={(autocomplete) => {
                      autocompleteRef.current = autocomplete;
                    }}
                    onPlaceChanged={handlePlaceChanged}
                  >
                    <Form.Control
                      type='text'
                      placeholder=''
                      {...register('street', {
                        onChange: () => {
                          setValue('unit', '', { shouldValidate: true });
                          setValue('city', '', { shouldValidate: true });
                          setValue('state', { label: '', value: '' }, { shouldValidate: true });
                          setValue('zip', '', { shouldValidate: true });
                          setValue('country', { label: '', value: '' }, { shouldValidate: true });
                        },
                      })}
                    />
                  </Autocomplete>
                )}
                {errors.street?.message && (
                  <Form.Text className='text-danger'>{t(errors.street.message)}</Form.Text>
                )}
              </Form.Group>
              <Form.Group className='form-group' controlId='formUnit'>
                <Form.Label>{t('Unit')}</Form.Label>
                <Form.Control type='text' {...register('unit')} />
                {errors.unit?.message && (
                  <Form.Text className='text-danger'>{t(errors.unit.message)}</Form.Text>
                )}
              </Form.Group>
            </div>

            <div className='form-row'>
              <Form.Group className='form-group' controlId='formCity'>
                <Form.Label>{t('City')}</Form.Label>
                <Form.Control type='text' {...register('city')} />
                {errors.city?.message && (
                  <Form.Text className='text-danger'>{t(errors.city.message)}</Form.Text>
                )}
              </Form.Group>

              <Form.Group className='form-group' controlId='formZip'>
                <Form.Label>{t('Zip')}</Form.Label>
                <Form.Control type='text' {...register('zip')} />
                {errors.zip?.message && (
                  <Form.Text className='text-danger'>{t(errors.zip.message)}</Form.Text>
                )}
              </Form.Group>

              <Form.Group className='form-group' controlId='formState'>
                <Form.Label>{t('State')}</Form.Label>
                <Controller
                  name='state'
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={states}
                      hideSelectedOptions={false}
                      controlShouldRenderValue={true}
                      name='state'
                      id='state-select'
                      inputId='state-input'
                      menuPlacement='top'
                      placeholder={t('Select')}
                    />
                  )}
                />
                {errors.state?.message && (
                  <Form.Text className='text-danger'>{t(errors.state.message)}</Form.Text>
                )}
              </Form.Group>

              <Form.Group className='form-group' controlId='formCountry'>
                <Form.Label>{t('Country')}</Form.Label>
                <Controller
                  name='country'
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={countries}
                      hideSelectedOptions={false}
                      controlShouldRenderValue={true}
                      name='country'
                      id='country-select'
                      inputId='country-input'
                      menuPlacement='top'
                      placeholder={t('Select')}
                    />
                  )}
                />
                {errors.country?.message && (
                  <Form.Text className='text-danger'>{t(errors.country.message)}</Form.Text>
                )}
              </Form.Group>
              <Form.Group className='form-group terms-group' controlId='formTerms'>
                <Form.Check
                  type='checkbox'
                  {...register('termsAgreed')}
                  label={
                    <span className='terms-label'>
                      {t('I agree to the')}{' '}
                      <button
                        type='button'
                        className='terms-button'
                        onClick={() => setShowTermsModal(true)}
                      >
                        {t('Terms & Conditions')}
                      </button>
                    </span>
                  }
                />
                {errors.termsAgreed?.message && (
                  <Form.Text className='text-danger'>{t(errors.termsAgreed.message)}</Form.Text>
                )}
              </Form.Group>
            </div>
            <LoaderButton
              className='btn btn-primary auth__btn-primary w-100'
              type='submit'
              disabled={
                !isValid ||
                isLoading ||
                (!checkingEmailAvailability && !emailAvailable) ||
                (!checkingPhoneAvailability && !phoneAvailable) ||
                formSubmitted
              }
              style={{ minWidth: '100%' }}
            >
              {t('Sign Up')}
            </LoaderButton>
          </Form>
          <Modal
            show={showTermsModal}
            onHide={() => setShowTermsModal(false)}
            dialogClassName='terms-modal'
          >
            <div className='terms-modal-img-container'>
              <img src={asterixIcon} alt='asterix-icon' className='terms-icon' />
            </div>
            <Modal.Header>
              <Modal.Title>{t('Terms & Conditions')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className='terms-description'>{t('Terms and Conditions content')}</p>
            </Modal.Body>
            <Modal.Footer className='d-flex align-center'>
              <Button
                variant='btn btn-primary auth__btn-primary'
                onClick={() => setShowTermsModal(false)}
              >
                {t('Close')}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};
