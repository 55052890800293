import { toast, ToastOptions } from 'react-toastify';

const ErrorIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_8859_4279)'>
      <path
        d='M9.99984 6.66669V10M9.99984 13.3334H10.0082M18.3332 10C18.3332 14.6024 14.6022 18.3334 9.99984 18.3334C5.39746 18.3334 1.6665 14.6024 1.6665 10C1.6665 5.39765 5.39746 1.66669 9.99984 1.66669C14.6022 1.66669 18.3332 5.39765 18.3332 10Z'
        stroke='#D92D20'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_8859_4279'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

const InfoIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_8859_4782)'>
      <path
        d='M9.99984 13.3333V9.99996M9.99984 6.66663H10.0082M18.3332 9.99996C18.3332 14.6023 14.6022 18.3333 9.99984 18.3333C5.39746 18.3333 1.6665 14.6023 1.6665 9.99996C1.6665 5.39759 5.39746 1.66663 9.99984 1.66663C14.6022 1.66663 18.3332 5.39759 18.3332 9.99996Z'
        stroke='#0086C9'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_8859_4782'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

const SuccessIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_8859_4928)'>
      <path
        d='M18.3332 9.23342V10.0001C18.3321 11.7971 17.7503 13.5457 16.6743 14.9849C15.5983 16.4242 14.0859 17.4772 12.3626 17.9867C10.6394 18.4962 8.79755 18.435 7.1119 17.8122C5.42624 17.1895 3.98705 16.0385 3.00897 14.531C2.03089 13.0235 1.56633 11.2401 1.68457 9.44702C1.80281 7.65389 2.49751 5.94703 3.66507 4.58098C4.83263 3.21494 6.41049 2.26291 8.16333 1.86688C9.91617 1.47085 11.7501 1.65204 13.3915 2.38342M18.3332 3.33342L9.99984 11.6751L7.49984 9.17509'
        stroke='#039855'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_8859_4928'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

const WarningIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_8859_4906)'>
      <path
        d='M10.0002 10.9333V7.81659'
        stroke='#7A2E0E'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.99933 13.4375C9.88433 13.4375 9.791 13.5309 9.79183 13.6459C9.79183 13.7609 9.88517 13.8542 10.0002 13.8542C10.1152 13.8542 10.2085 13.7609 10.2085 13.6459C10.2085 13.5309 10.1152 13.4375 9.99933 13.4375'
        stroke='#7A2E0E'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.6905 3.4491L18.073 14.6191C18.8146 15.9174 17.8771 17.5333 16.3821 17.5333H3.61714C2.12131 17.5333 1.18381 15.9174 1.92631 14.6191L8.30881 3.4491C9.05631 2.13993 10.943 2.13993 11.6905 3.4491Z'
        stroke='#7A2E0E'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_8859_4906'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export const GLOBAL_TOASTIFY_CONFIG: ToastOptions = {
  hideProgressBar: true,
  autoClose: 3000,
};

const fontStyles = {
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '20px',
  margin: '0',
};

const ContentWrap = ({ title, content }: { title: string; content: string }) => {
  return (
    <div className='d-flex flex-column'>
      <p style={fontStyles} className='mb-1'>
        {title}
      </p>
      <p
        style={{
          ...fontStyles,
          fontWeight: '400',
        }}
        className='text-break'
      >
        {content}
      </p>
    </div>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  error: (content: string, config?: ToastOptions) =>
    toast.error(<ContentWrap title='Error' content={content} />, {
      ...GLOBAL_TOASTIFY_CONFIG,
      icon: ErrorIcon,
      ...config,
    }),
  success: (content: string, config?: ToastOptions) =>
    toast.success(<ContentWrap title='Success' content={content} />, {
      ...GLOBAL_TOASTIFY_CONFIG,
      icon: SuccessIcon,
      ...config,
    }),
  info: (content: string, config?: ToastOptions) =>
    toast.info(<ContentWrap title='Info' content={content} />, {
      ...GLOBAL_TOASTIFY_CONFIG,
      icon: InfoIcon,
      ...config,
    }),
  warning: (content: string, config?: ToastOptions) =>
    toast.warning(<ContentWrap title='Warning' content={content} />, {
      ...GLOBAL_TOASTIFY_CONFIG,
      icon: WarningIcon,
      ...config,
    }),
  clearQueue: () => toast.clearWaitingQueue(),
};
