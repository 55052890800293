import clsx from 'clsx';
import { ComponentProps, forwardRef } from 'react';

const Input = (
  { className, ...props }: ComponentProps<'input'>,
  ref: React.Ref<HTMLInputElement>,
) => {
  return <input ref={ref} className={clsx('form-control', className)} {...props} />;
};

export default forwardRef(Input);
