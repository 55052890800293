import { useState, useEffect } from 'react';

/**
 * hook which starts countdown immediately after being invoked
 * @param treshold - number of seconds to start countdown from - 30 is default
 * @returns Object
 */
const useCountdown = (treshold = 30) => {
  const [seconds, setSeconds] = useState(treshold);
  const [hasElapsed, setHasElapsed] = useState(false);
  const [canCountdown, setCanCountdown] = useState(false);

  useEffect(() => {
    const onElapsed = () => {
      setCanCountdown(false);
      setHasElapsed(true);
      return undefined;
    };
    if (canCountdown) {
      const timeout = seconds > 0 ? setTimeout(() => setSeconds(seconds - 1), 1000) : onElapsed();
      return () => clearTimeout(timeout);
    }
  }, [seconds, canCountdown]);

  const stop = () => {
    setCanCountdown(false);
  };

  const start = () => {
    setCanCountdown(true);
  };

  const reset = () => {
    setSeconds(treshold);
    setHasElapsed(false);
    setCanCountdown(false);
  };

  return { seconds, hasElapsed, stop, start, reset };
};

export default useCountdown;
