import { getCurrentUser, fetchAuthSession, type GetCurrentUserOutput } from '@aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { useEffect, useState } from 'react';

import { createSessionApiThunk } from '../features/auth/api/authSlice';
import { useAppDispatch } from '../store';
import alert from '../utils/alert';
import { verifyAllSessionCookies } from '../utils/sessionCookies';

type AuthStatus = 'signedIn' | 'signedOut' | undefined;
export interface UseAuthResponse {
  isAuthenticating: boolean;
  currentUser: GetCurrentUserOutput | null;
  authStatus: AuthStatus;
}

const getCurrentUserInfo = async (): Promise<GetCurrentUserOutput | null> => {
  try {
    return await getCurrentUser();
  } catch {
    return null;
  }
};

// const checkIfUserHasPermission = (identifiers: string): boolean => {
//   const identifiersArray: fhir4.Identifier[] = JSON.parse(identifiers);

//   const userRoles = rolesFromFHIR4Identifier(identifiersArray || []);

//   return userRoles.some((role) =>
//     [SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.PROVIDER, SYSTEM_ROLES.MANAGER].includes(
//       role
//     )
//   );
// };

// Documentation for Hub events:
// https://docs.amplify.aws/lib/auth/auth-events/q/platform/js/
const useAuth = (config?: {
  onAuthStatusChange?: (authStatus: AuthStatus) => void;
}): UseAuthResponse => {
  const dispatch = useAppDispatch();
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<GetCurrentUserOutput | null>(null);
  const [authStatus, setAuthStatus] = useState<AuthStatus>(() =>
    verifyAllSessionCookies() ? 'signedIn' : 'signedOut',
  );

  useEffect(() => {
    getCurrentUserInfo().then(setCurrentUser);
  }, []);

  useEffect(() => {
    const updateUser = async (data?: any) => {
      if (data) {
        // if (data.payload.data.challengeParam) {
        //   const hasPermission = checkIfUserHasPermission(
        //     data.payload.data.challengeParam.userAttributes["custom:identifier"]
        //   );

        //   if (!hasPermission) {
        //     return;
        //   }
        // }

        const status = data.payload.event;
        if ((status === 'signedIn' || status === 'signedOut') && status !== authStatus) {
          console.log('STATUS', status);
          setIsAuthenticating(true);
          if (status === 'signedIn') {
            try {
              const cognitoUser: GetCurrentUserOutput = data.payload.data;
              const { accessToken } = (await fetchAuthSession()).tokens ?? {};
              const cognitoAccessToken = accessToken?.toString();
              await dispatch(
                createSessionApiThunk({
                  accessToken: cognitoAccessToken || '',
                }),
              ).unwrap();
              setCurrentUser(cognitoUser);
              setAuthStatus(status);
              config?.onAuthStatusChange?.(status);
            } catch (err: any) {
              alert.error(err?.error || 'Something went wrong, cannot login.', {
                toastId: 'general-login-error',
              });
              // await dispatch(logoutUserThunk());
              // disconnect websockets
              // dispatch(disconnect());
            }
          } else {
            // disconnect websockets
            // dispatch(disconnect());
            setCurrentUser(null);
            setAuthStatus(status);
            config?.onAuthStatusChange?.(status);
          }
          setIsAuthenticating(false);
        }
      }
    };
    const hubListenerCancelToken = Hub.listen('auth', updateUser);
    return () => hubListenerCancelToken();
  }, [authStatus, config, dispatch]);

  return { isAuthenticating, currentUser, authStatus };
};

export default useAuth;
export { getCurrentUserInfo as getCurrentUser };
