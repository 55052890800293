import RegisterFromType from '../features/register/types/registerFormType';
import moment from 'moment';

const registerFromToParams = (formData: RegisterFromType) => {
  return {
    resource: {
      resourceType: 'Patient',
      identifier: [
        {
          system: 'http://proactivehealthtech.com/sid/d2md/v1/person/username',
          value: formData.email,
        },
      ],
      active: true,
      address: [
        {
          city: formData.city,
          country: formData.country,
          line: [formData.street ?? '', formData.unit ?? ''],
          postalcode: formData.zip,
          state: formData.state,
          use: 'home',
        },
      ],
      birthDate: moment(formData.birthdate).format('YYYY-MM-DD'),
      gender: formData.sex,
      name: [
        {
          family: formData.lastName,
          given: [formData.firstName, ...(formData.middleName ? [formData.middleName] : [])],
          use: 'usual',
        },
      ],
      telecom: [
        {
          isNew: true,
          system: 'email',
          value: formData.email,
        },
        {
          isNew: false,
          system: 'phone',
          value: formData.phone,
          countryCode: '+1',
          country: 'US',
        },
      ],
      preference: {
        notification: {
          general: {
            email: true,
            sms: true,
            app: true,
          },
        },
      },
    },
  };
};

export default registerFromToParams;
