import * as yup from 'yup';
import { FIELD_DEFINITIONS, FIELD_TEXT_MESSAGES } from '../../../config';

export const ResetPasswordWithCodeSchema = yup.object().shape({
  email: yup
    .string()
    .min(FIELD_DEFINITIONS.EMAIL_MIN_LENGTH, FIELD_TEXT_MESSAGES.EMAIL_MIN_LENGTH)
    .max(FIELD_DEFINITIONS.EMAIL_MAX_LENGTH, FIELD_TEXT_MESSAGES.EMAIL_MAX_LENGTH)
    .email(FIELD_TEXT_MESSAGES.EMAIL_INVALID)
    .required(FIELD_TEXT_MESSAGES.EMAIL_REQUIRED),
  code: yup
    .string()
    .min(FIELD_DEFINITIONS.CODE_MIN_LENGTH, FIELD_TEXT_MESSAGES.CODE_MIN_LENGTH)
    .max(FIELD_DEFINITIONS.CODE_MIN_LENGTH, FIELD_TEXT_MESSAGES.CODE_MIN_LENGTH)
    .required(FIELD_TEXT_MESSAGES.OTP_CODE_REQUIRED),
});

export const ResetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(FIELD_DEFINITIONS.PASSWORD_MIN_LENGTH, FIELD_TEXT_MESSAGES.PASSWORD_MIN_LENGTH)
    .max(FIELD_DEFINITIONS.PASSWORD_MAX_LENGTH, FIELD_TEXT_MESSAGES.PASSWORD_MAX_LENGTH)
    .matches(
      FIELD_DEFINITIONS.PASSWORD_ONE_LOWERCASE_CHARACTER_PATTERN,
      FIELD_TEXT_MESSAGES.PASSWORD_LOWERCASE_CHARACTER_REQUIRED,
    )
    .matches(
      FIELD_DEFINITIONS.PASSWORD_ONE_UPPERCASE_CHARACTER_PATTERN,
      FIELD_TEXT_MESSAGES.PASSWORD_UPPERCASE_CHARACTER_REQUIRED,
    )
    .matches(
      FIELD_DEFINITIONS.PASSWORD_ONE_SPECIAL_CHARACTER_PATTERN,
      FIELD_TEXT_MESSAGES.PASSWORD_SPECIAL_CHARACTER_REQUIRED,
    )
    .matches(
      FIELD_DEFINITIONS.PASSWORD_ONE_NUMBER_PATTERN,
      FIELD_TEXT_MESSAGES.PASSWORD_NUMBER_REQUIRED,
    )
    .required(FIELD_TEXT_MESSAGES.PASSWORD_REQUIRED)
    .test('passwords-match', FIELD_TEXT_MESSAGES.PASSWORD_CONFIRM_INVALID, function (value) {
      return !this.parent.confirmPassword || value === this.parent.confirmPassword;
    }),
  confirmPassword: yup
    .string()
    .required(FIELD_TEXT_MESSAGES.PASSWORD_CONFIRM_INVALID)
    .test('passwords-match', FIELD_TEXT_MESSAGES.PASSWORD_CONFIRM_INVALID, function (value) {
      return !this.parent.password || value === this.parent.password;
    }),
});
