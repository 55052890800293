import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../../store';
import FindMeService from '../../../api/services/FindMeService';
import { Address } from '../../account/types';

const ACTIONS_PREFIX = 'base';

export interface Employer {
  id: string;
  resource_id: number;
  uuid: string;
  phone_number: string;
  name: fhir4.HumanName[];
  address: Address;
  email: string;
  organization: string;
  birthDate: Date;
  loginCount: number;
}

interface FindMeState {
  message: string;
  resource: Employer[];
  isLoading: boolean;
}

const initialState: FindMeState = {
  message: '',
  resource: [],
  isLoading: false,
};

export const getEmployerThunk = createAsyncThunk(
  `${ACTIONS_PREFIX}/getEmployer`,
  async (params: { lastName: string; dateOfBirth: string; phoneNumber: string }) => {
    const { lastName, dateOfBirth, phoneNumber } = params;
    return await FindMeService.getEmployer(lastName, dateOfBirth, phoneNumber);
  },
);

export const sendOtpThunk = createAsyncThunk(
  `${ACTIONS_PREFIX}/sendOtp`,
  async (params: { uuid: string }) => {
    const { uuid } = params;
    return await FindMeService.sendOtp(uuid);
  },
);

export const verifyOtpThunk = createAsyncThunk(
  `${ACTIONS_PREFIX}/verifyOtp`,
  async (params: { verCode: string; verCodeResUUID: string }) => {
    const { verCode, verCodeResUUID } = params;
    return await FindMeService.verifyOtp(verCode, verCodeResUUID);
  },
);

export const resendOtpThunk = createAsyncThunk(
  `${ACTIONS_PREFIX}/resendOtp`,
  async (params: { verCodeResUUID: string; phoneNumber: string }) => {
    const { verCodeResUUID, phoneNumber } = params;
    return await FindMeService.resendOtp(verCodeResUUID, phoneNumber);
  },
);

export const resetPasswordWithOtpThunk = createAsyncThunk(
  `${ACTIONS_PREFIX}/resetPasswordWithOtp`,
  async (params: {
    username: string;
    password: string;
    verCodeResUUID: string;
    verCode: string;
    firstName: string;
  }) => {
    const { username, password, verCodeResUUID, verCode, firstName } = params;
    return await FindMeService.resetPasswordWithOtp(
      username,
      password,
      verCodeResUUID,
      verCode,
      firstName,
    );
  },
);

const findMeState = createSlice({
  name: 'findMe',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEmployerThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEmployerThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        state.resource = action.payload.resource || [];
      })
      .addCase(getEmployerThunk.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(sendOtpThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendOtpThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        state.resource = action.payload.resource || [];
      })
      .addCase(sendOtpThunk.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(verifyOtpThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyOtpThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        state.resource = action.payload.resource || [];
      })
      .addCase(verifyOtpThunk.rejected, (state) => {
        state.isLoading = false;
      });

    builder.addCase(resendOtpThunk.fulfilled, (state, action) => {
      state.message = action.payload.message;
      state.resource = action.payload.resource || [];
    });

    builder
      .addCase(resetPasswordWithOtpThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetPasswordWithOtpThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        state.resource = action.payload.resource || [];
      })
      .addCase(resetPasswordWithOtpThunk.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

// SELECTORS
export const selectFindMe = (state: RootState) => state.findMe;

export default findMeState.reducer;
