import { useEffect, useState } from 'react';

const useOnlineStatus = function () {
  const [isOnline, setIsOnline] = useState<boolean>(window.navigator.onLine);

  useEffect(function () {
    function onOnline() {
      setIsOnline(true);
    }
    function onOffline() {
      setIsOnline(false);
    }
    window.addEventListener('online', onOnline);
    window.addEventListener('offline', onOffline);

    return function () {
      window.removeEventListener('online', onOnline);
      window.removeEventListener('offline', onOffline);
    };
  }, []);

  return { isOnline };
};

export default useOnlineStatus;
