/* eslint-disable @typescript-eslint/naming-convention */
import i18next from '../i18n';
import { env } from '../env';
const { t } = i18next;
export const API_BASE_URL = env.REACT_APP_API_BASE_URL;
export const JWT_SECRET =
  env.REACT_APP_TEST_JWT_SECRET ||
  ('JjBpReu9WCA35v4tZNXzgktboU1hVO1ukov/suVaIRrYybDApNCfMH90I5saeCySrM4U+bBAXTogwFvJNr0CabtDGvvn62p1cg7AMf4Xn16r22ZxLUo5ZqJg7LmTrovGbgF6mMmwCYcrVarXdK+WeZleNlO6MuIwTIeumSg5DsFRbARa9ATyc7M51ESthEX01xYIwWe++BJmQ829qIVKb0jHrXRiaCRnj/L2spG68zzzynaGv8myO/e6td0j+68ofVCzXAxUJwhmySuk6j6sAHLSCo4+sDiv9yAsTs9octs4jYhbpb9Wgt8MqJoRv7t7e9SP9lzFuYzZeiowsEQdhA==' as string);
export const TEST_USER_CREDENTIALS = {
  email: 'test@test.com',
  password: 'Test1234!',
};

export const SESSION_IDLE_MINUTES = 20;
export const REFRESH_SESSION_TRESHOLD_SECONDS = 60;
export const API_ACCESS_TOKEN_COOKIE_NAME = 'API_ACCESS_TOKEN';
export const LAST_ACTIVITY_TRACKER_COOKIE = 'LAST_ACTIVITY';
export const MINOR_AGE = 18;

export const API_HTTP_CODES = {
  SUCCESS: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
};

export const COGNITO_CHALLENGES = {
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
  SMS_MFA: 'SMS_MFA',
  SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA',
  SELECT_MFA_TYPE: 'SELECT_MFA_TYPE',
  MFA_SETUP: 'MFA_SETUP',
  PASSWORD_VERIFIER: 'PASSWORD_VERIFIER',
  CUSTOM_CHALLENGE: 'CUSTOM_CHALLENGE',
  DEVICE_SRP_AUTH: 'DEVICE_SRP_AUTH',
  DEVICE_PASSWORD_VERIFIER: 'DEVICE_PASSWORD_VERIFIER',
  ADMIN_NO_SRP_AUTH: 'ADMIN_NO_SRP_AUTH',
};

export const SYSTEM_ROLE_IDENTIFIER =
  'http://proactivehealthtech.com/sid/d2md/v1/organization/direct2md';

export const SYSTEM_ROLES_IDENTIFIERS = {
  ADMIN: `${SYSTEM_ROLE_IDENTIFIER}/admin`,
  MANAGER: `${SYSTEM_ROLE_IDENTIFIER}/manager`,
  PROVIDER: `${SYSTEM_ROLE_IDENTIFIER}/provider`,
};

export const SYSTEM_ROLES = {
  ADMIN: 'admin',
  MANAGER: 'manager',
  PROVIDER: 'provider',
};

export const SYSTEM_DEFAULT_ROLE = SYSTEM_ROLES.PROVIDER;

export const SYSTEM_SCOPES = {
  /** GENERAL SCOPES */
  canAdd: 'can-add',
  canRead: 'can-read',
  canEdit: 'can-edit',
  canDelete: 'can-delete',

  /** MENU SCOPES */
  canViewTodaysVisitsMenu: `can-view-todays-visits-menu`,
  canViewCalendarMenu: 'can-view-calendar-menu',
  canViewPastVisitsMenu: 'can-view-past-visits-menu',
  canViewMessagesMenu: 'can-view-messages-menu',
  canViewPatientsMenu: 'can-view-patients-menu',
  canViewMyAccountMenu: 'can-view-my-account-menu',
  canViewMyClinicMenu: 'can-view-my-clinic-menu',
};

export const SYSTEM_GENERAL_SCOPES = {
  [SYSTEM_ROLES.ADMIN]: [
    SYSTEM_SCOPES.canAdd,
    SYSTEM_SCOPES.canRead,
    SYSTEM_SCOPES.canEdit,
    SYSTEM_SCOPES.canDelete,
    SYSTEM_SCOPES.canViewMessagesMenu,
  ],
  [SYSTEM_ROLES.MANAGER]: [SYSTEM_SCOPES.canRead, SYSTEM_SCOPES.canViewMessagesMenu],
  [SYSTEM_ROLES.PROVIDER]: [
    SYSTEM_SCOPES.canViewTodaysVisitsMenu,
    SYSTEM_SCOPES.canViewCalendarMenu,
    SYSTEM_SCOPES.canViewPastVisitsMenu,
    SYSTEM_SCOPES.canViewMessagesMenu,
    SYSTEM_SCOPES.canViewPatientsMenu,
    SYSTEM_SCOPES.canViewMyAccountMenu,
  ],
};

export const SYSTEM_PAGE_PERMISSIONS = {
  account: {
    [SYSTEM_ROLES.ADMIN]: [
      SYSTEM_SCOPES.canAdd,
      SYSTEM_SCOPES.canRead,
      SYSTEM_SCOPES.canEdit,
      SYSTEM_SCOPES.canDelete,
    ],
    [SYSTEM_ROLES.MANAGER]: [SYSTEM_SCOPES.canRead, SYSTEM_SCOPES.canEdit, SYSTEM_SCOPES.canDelete],
    [SYSTEM_ROLES.PROVIDER]: [
      SYSTEM_SCOPES.canRead,
      SYSTEM_SCOPES.canEdit,
      SYSTEM_SCOPES.canDelete,
    ],
  },
  clinic: {
    general: {
      [SYSTEM_ROLES.ADMIN]: [
        SYSTEM_SCOPES.canAdd,
        SYSTEM_SCOPES.canRead,
        SYSTEM_SCOPES.canEdit,
        SYSTEM_SCOPES.canDelete,
      ],
      provider: [],
      manager: [SYSTEM_SCOPES.canRead],
    },
    users: {
      [SYSTEM_ROLES.ADMIN]: [
        SYSTEM_SCOPES.canAdd,
        SYSTEM_SCOPES.canRead,
        SYSTEM_SCOPES.canEdit,
        SYSTEM_SCOPES.canDelete,
      ],
      provider: [],
      manager: [SYSTEM_SCOPES.canRead],
    },
  },
};

export const THEME_SETTINGS = [
  {
    value: 'light',
    label: 'Light',
  },
  // * DISABLED FOR NOW
  // {
  //   value: 'dark',
  //   label: 'Dark',
  // },
  // {
  //   value: 'system',
  //   label: 'System',
  // },
];

export const UNITS_SETTINGS = [
  {
    value: 'imperial',
    label: 'Imperial',
  },
  {
    value: 'metric',
    label: 'Metric',
  },
];

export const COUNTRIES = [{ value: 'US', label: 'United States' }];

export const DEFAULT_NOTIFICATIONS = {
  event_1: {
    sms: true,
    email: true,
  },
  event_2: {
    sms: true,
    email: true,
  },
  event_3: {
    sms: true,
    email: true,
  },
  event_4: {
    sms: true,
    email: true,
  },
  event_5: {
    sms: true,
    email: true,
  },
  event_6: {
    sms: true,
    email: true,
  },
  event_7: {
    sms: true,
    email: true,
  },
  event_8: {
    sms: true,
    email: true,
  },
  event_9: {
    sms: true,
    email: true,
  },
  event_10: {
    sms: true,
    email: true,
  },
  event_11: {
    sms: true,
    email: true,
  },
  event_12: {
    sms: true,
    email: true,
  },
  event_13: {
    sms: true,
    email: true,
  },
  event_14: {
    sms: true,
    email: true,
  },
};

export const NAME_PREFIXES = [
  { value: 'Dr.', label: 'Dr.' },
  { value: 'Miss', label: 'Miss' },
  { value: 'Mr.', label: 'Mr.' },
  { value: 'Mrs.', label: 'Mrs.' },
  { value: 'Ms.', label: 'Ms.' },
  { value: 'Prof.', label: 'Prof.' },
];

// export const NAME_SUFFIXES = [
//   { value: 'Jr.', label: 'Jr.' },
//   { value: 'Sr.', label: 'Sr.' },
//   { value: '2nd', label: '2nd' },
//   { value: '3rd', label: '3rd' },
//   { value: 'MD', label: 'MD' },
//   { value: 'PhD', label: 'PhD' },
// ];

export const NAME_SUFFIXES = [
  { value: 'DO', label: 'DO' },
  { value: 'PA-C', label: 'PA-C' },
  { value: 'CNP', label: 'CNP' },
  { value: 'DDS', label: 'DDS' },
  { value: 'RN', label: 'RN' },
  { value: 'ARNP', label: 'ARNP' },
  { value: 'CRNP', label: 'CRNP' },
  { value: 'LNP', label: 'LNP' },
  { value: 'NPC', label: 'NPC' },
  { value: 'FNP', label: 'FNP' },
  { value: 'MD', label: 'MD' },
];

/**
 * @TODO: This needs to be verified.
 * @description
 * @link https://www.fhir.org/guides/argonaut/pd/ValueSet-provider-specialty.html
 */
export const SPECIALTIES = [
  { value: '207Q00000X', label: 'Family Medicine' },
  { value: '363LW0102X', label: 'Women’s Health' },
  { value: '364SW0102X', label: 'Men’s Health' },
  { value: 'base-1', label: 'Behavioral Health' },
  { value: '207N00000X', label: 'Dermatology' },
  { value: '208000000X', label: 'Pediatric' },
  { value: '207W00000X', label: 'Ophthalmology and Optometry' },
  { value: '163WD0400X', label: 'Diabetes Care Specialists' },
  { value: 'Physical Therapy ', label: 'Physical Therapy' },
  { value: '261QP2000X', label: 'Occupational Therapy' },
  { value: '2080P0202X', label: 'Cardiology' },
  { value: 'Medical', label: 'Medical' },
  { value: '207P00000X', label: 'Emergency Medicine' },
];

export const QUESTIONNAIRE_TYPES = [
  // {
  //   value: 'assessment',
  //   label: 'Assessment',
  // },
  {
    value: 'intake',
    label: 'Intake',
  },
  // {
  //   value: 'survey',
  //   label: 'Survey',
  // },
];

export const FIELD_DEFINITIONS = {
  REGISTRATION_CODE_PART_LENGTH: 3,
  FIRST_NAME_MIN_LENGTH: 1,
  FIRST_NAME_MAX_LENGTH: 26,
  MIDDLE_NAME_MIN_LENGTH: 1,
  MIDDLE_NAME_MAX_LENGTH: 26,
  LAST_NAME_MIN_LENGTH: 1,
  LAST_NAME_MAX_LENGTH: 26,
  BODY_MAX_LENGTH: 7000,
  LETTERS_ONLY: /^[aA-zZ\s]+$/,
  LETTERS_AND_NUMBERS_ONLY: /^[aA-zZ0-9\s]+$/,
  LETTERS_NUMBERS_SPACES_AND_SPECIAL_CHARACTERS: /^[a-zA-Z0-9@~`!@#$%^&*()_=+\\';:"/?>.<,-]*$/,
  EMAIL_MIN_LENGTH: 5,
  EMAIL_MAX_LENGTH: 255,
  EMAIL_PATTERN: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  PASSWORD_MIN_LENGTH: 8,
  PASSWORD_MAX_LENGTH: 32,
  PASSWORD_ONE_LOWERCASE_CHARACTER_PATTERN: /^(?=.*[a-z])/,
  PASSWORD_ONE_UPPERCASE_CHARACTER_PATTERN: /^(?=.*[A-Z])/,
  PASSWORD_ONE_SPECIAL_CHARACTER_PATTERN: /(?=.*[!@#$%^&*(),.?":{}|<>])/,
  PASSWORD_ONE_NUMBER_PATTERN: /^(?=.*?[0-9])/,
  PHONE_MIN_LENGTH: 10,
  PHONE_MAX_LENGTH: 10,
  PHONE_PATTERN: /^\d{10,10}$/,
  BIRTHDATE_MAX_DATE: new Date(),
  STREET_MIN_LENGTH: 1,
  STREET_MAX_LENGTH: 128,
  UNIT_MAX_LENGTH: 128,
  CITY_MIN_LENGTH: 1,
  CITY_MAX_LENGTH: 64,
  STATE_MIN_LENGTH: 1,
  STATE_MAX_LENGTH: 64,
  ZIP_MIN_LENGTH: 1,
  ZIP_MAX_LENGTH: 16,
  COUNTRY_MIN_LENGTH: 1,
  COUNTRY_MAX_LENGTH: 64,

  // questions
  FREE_TEXT_MAX_LENGTH: 1000,

  OTP_CODE_LENGTH: 6,
  OTP_CODE_PART_LENGTH: 3,
  PHONE_LINE_NUMBER_LENGTH: 10,
  PHONE_MIN_NUMBER_LENGTH: 1000000000,
  PHONE_MAX_NUMBER_LENGTH: 999999999999999,
  CODE_MIN_LENGTH: 6,
  CODE_MAX_LENGTH: 6,
  CLINIC_NAME_MIN_LENGTH: 1,
  CLINIC_NAME_MAX_LENGTH: 128,
  CLINIC_STREET_MAX_LENGTH: 255,
  CLINIC_SUITE_MAX_LENGTH: 128,
  CLINIC_CITY_MAX_LENGTH: 64,
  CLINIC_STATE_MAX_LENGTH: 64,
  CLINIC_ZIP_MAX_LENGTH: 16,
  ORGANIZATION_CENSUS_LOG_COMMENT_MAX_LENGTH: 1024,
  MATCH_SPECIAL_CHARACTERS: /^[^((0-9)|(a-z)|(A-Z)|\s)]*$/,
  AVOID_SPECIAL_CHARACTERS: /^[((0-9)|(a-z)|(A-Z)|\s)]*$/,
  AVOID_SPECIAL_CHARACTERS_ADDRESS_STREET: /^[((0-9)|(a-z)|(A-Z)|,|\s)]*$/,
  MATCH_BLANK_STRING: /^(\s)*$/g,
  MATCH_NOT_BLANK_STRING: /(\S)+/g,
  MATCH_YOUTUBE_URL_STRING:
    /(?:https?:\/\/)?(?:(?:(?:www\.?)?youtube\.com(?:\/(?:(?:watch\?.*?(v=[^&\s]+).*)|(?:v(\/.*))|(channel\/.+)|(?:user\/(.+))|(?:results\?(search_query=.+))))?)|(?:youtu\.be(\/.*)?))/,
  MACTH_YOUTUBE_URL_STRING_ONE_ONLY:
    /^(?:https?:\/\/)?(?:(?:(?:www\.?)?youtube\.com(?:\/(?:(?:watch\?.*?(v=[^&\s]+).*)|(?:v(\/.*))|(channel\/.+)|(?:user\/(.+))|(?:results\?(search_query=.+))))?)|(?:youtu\.be(\/.*)?))\S$/,
  QUESTION_HEADER_CHAR_LIMIT: 100,
  SERVICE_COMPLAINTS_MAX_LENGTH: 20,
};

export const FIELD_TEXT_MESSAGES = {
  EMAIL_INVALID: t('Please enter a valid email address.'),
  EMAIL_CONFIRM_INVALID: t('Email fields must match.'),
  EMAIL_MIN_LENGTH: t('Email must be at least min characters.', {
    min: FIELD_DEFINITIONS.EMAIL_MIN_LENGTH,
  }),
  EMAIL_MAX_LENGTH: t('Email must be at most max characters.', {
    max: FIELD_DEFINITIONS.EMAIL_MAX_LENGTH,
  }),
  EMAIL_REQUIRED: t('Email address is required.'),

  BODY_MAX_LENGTH: t('You have exceeded the maximum of max characters limit', {
    max: FIELD_DEFINITIONS.BODY_MAX_LENGTH,
  }),

  PHONE_INVALID: t('Please enter a valid phone number.'),
  PHONE_CONFIRM_INVALID: t('Phone fields must match'),
  PHONE_REQUIRED: t('Phone number is required.'),
  PHONE_COUNTRY_CODE_REQUIRED: t('Country Code is required.'),
  PHONE_MIN_LENGTH: t('Phone number must contain min characters.', {
    min: FIELD_DEFINITIONS.PHONE_MIN_LENGTH,
  }),
  PHONE_MAX_LENGTH: t('Phone number must contain max characters.', {
    max: FIELD_DEFINITIONS.PHONE_MAX_LENGTH,
  }),
  LETTERS_ONLY_ALLOWED: t('Please enter only letters.'),
  LETTERS_AND_NUMBERS_ONLY_ALLOWED: t('Please enter only letters and numbers.'),
  LETTERS_NUMBERS_SPACES_AND_SPECIAL_CHARACTERS_ALLOWED: t(
    'Please enter only letters, numbers, spaces and special characters.',
  ),

  FIRST_NAME_REQUIRED: t('First Name is required.'),
  FIRST_NAME_MIN_LENGTH: t('First Name must be at least min characters.', {
    min: FIELD_DEFINITIONS.FIRST_NAME_MIN_LENGTH,
  }),
  FIRST_NAME_MAX_LENGTH: t('First Name must be at most max characters.', {
    max: FIELD_DEFINITIONS.FIRST_NAME_MAX_LENGTH,
  }),

  MIDDLE_NAME_MIN_LENGTH: t('Middle Name must be at least min characters.', {
    min: FIELD_DEFINITIONS.MIDDLE_NAME_MIN_LENGTH,
  }),
  MIDDLE_NAME_MAX_LENGTH: t('Middle Name must be at most max characters.', {
    max: FIELD_DEFINITIONS.MIDDLE_NAME_MAX_LENGTH,
  }),

  LAST_NAME_REQUIRED: t('Last Name is required.'),
  LAST_NAME_MIN_LENGTH: t('Last Name must be at least min characters.', {
    min: FIELD_DEFINITIONS.LAST_NAME_MIN_LENGTH,
  }),
  LAST_NAME_MAX_LENGTH: t('Last Name must be at most max characters.', {
    max: FIELD_DEFINITIONS.LAST_NAME_MAX_LENGTH,
  }),

  BIRTHDATE_REQUIRED: t(`Date of Birth is required.`),
  BIRTHDATE_MAX_DATE: t(`Please select a valid date.`),
  BIRTHDATE_FLOOR_DATE: t('Date cannot be under year 1900.'),
  BIRTHDATE_MIN_DATE: t(`You must be at least ${MINOR_AGE} years old.`),
  BIRTHDATE_MINOR_MIN_DATE: t(`Minor may not exceed ${MINOR_AGE} years of age.`),

  RELATIONSHIP_REQUIRED: t('Relationship is required.'),
  SEX_REQUIRED: t('You must select a sex.'),

  STREET_REQUIRED: t('Street is required.'),
  STREET_MIN_LENGTH: t('Street must be at least min characters.', {
    min: FIELD_DEFINITIONS.STREET_MIN_LENGTH,
  }),
  STREET_MAX_LENGTH: t('Street must be at most max characters.', {
    max: FIELD_DEFINITIONS.STREET_MAX_LENGTH,
  }),

  UNIT_MAX_LENGTH: t('Unit must be at most max characters.', {
    max: FIELD_DEFINITIONS.UNIT_MAX_LENGTH,
  }),

  CITY_REQUIRED: t('City is required.'),
  CITY_MIN_LENGTH: t('City must be at least min characters.', {
    min: FIELD_DEFINITIONS.CITY_MIN_LENGTH,
  }),
  CITY_MAX_LENGTH: t('City must be at most max characters.', {
    max: FIELD_DEFINITIONS.CITY_MAX_LENGTH,
  }),

  STATE_REQUIRED: t('State is required.'),
  STATE_MIN_LENGTH: t('State must be at least min characters.', {
    min: FIELD_DEFINITIONS.STATE_MIN_LENGTH,
  }),
  STATE_MAX_LENGTH: t('State must be at most max characters.', {
    max: FIELD_DEFINITIONS.STATE_MAX_LENGTH,
  }),

  ZIP_REQUIRED: t('Zip is required.'),
  ZIP_MIN_LENGTH: t('Zip must be at least min characters.', {
    min: FIELD_DEFINITIONS.ZIP_MIN_LENGTH,
  }),
  ZIP_MAX_LENGTH: t('Zip must be at most max characters.', {
    max: FIELD_DEFINITIONS.ZIP_MAX_LENGTH,
  }),

  COUNTRY_REQUIRED: t('Country is required.'),
  COUNTRY_MIN_LENGTH: t('Country must be at least min characters.', {
    min: FIELD_DEFINITIONS.COUNTRY_MIN_LENGTH,
  }),
  COUNTRY_MAX_LENGTH: t('Country must be at most max characters.', {
    max: FIELD_DEFINITIONS.COUNTRY_MAX_LENGTH,
  }),

  // questions
  QUESTION_REQUIRED: t('This question is required.'),
  CONSENT_READ_REQUIRED: t('This consent is required to read.'),
  MEDIA_FILE_WATCH_REQUIRED: t('This video is required to watch.'),
  FILE_UPLOAD_REQUIRED: t('Uploading a file is required.'),
  FREE_TEXT_MIN_LENGTH: t('Answer must be at most max characters.', {
    max: FIELD_DEFINITIONS.FREE_TEXT_MAX_LENGTH,
  }),

  // messages
  RECIPIENT_REQUIRED: t('You must select a recipient.'),
  SUBJECT_REQUIRED: t('A subject is required.'),
  MESSAGE_REQUIRED: t('A message is required.'),
  GENERAL_ERROR: t('An error occurred, something went wrong.'),

  PASSWORD_REQUIRED: t('Password is required.'),
  PASSWORD_CONFIRM_INVALID: t('Passwords must match'),
  PASSWORD_SPECIAL_CHARACTER_REQUIRED: t('Must be at least 1 special character'),
  PASSWORD_NUMBER_REQUIRED: t('Must be at least 1 number'),
  PASSWORD_UPPERCASE_CHARACTER_REQUIRED: t('Must be at least 1 capital letter'),
  PASSWORD_LOWERCASE_CHARACTER_REQUIRED: t('Must be at least 1 lower case'),
  PASSWORD_MIN_LENGTH: t('Password must be alteast min characters.', {
    min: FIELD_DEFINITIONS.PASSWORD_MIN_LENGTH,
  }),
  PASSWORD_MAX_LENGTH: t('Password must be alteast max characters.', {
    max: FIELD_DEFINITIONS.PASSWORD_MAX_LENGTH,
  }),
  DOB_REQUIRED: t('Date of Birth is required.'),
  TERMS_AND_CONDITIONS_REQUIRED: t('You must agree to the Terms & Conditions'),

  CODE_MIN_LENGTH: t('Code should be exactly min characters.', {
    min: FIELD_DEFINITIONS.CODE_MIN_LENGTH,
  }),

  CODE_MAX_LENGTH: t('Code should be exactly max characters.', {
    max: FIELD_DEFINITIONS.CODE_MAX_LENGTH,
  }),
  TERMS_REQUIRED: t('You must agree to the Terms & Conditions'),
  OTP_CODE_REQUIRED: t('Code is required.'),
  OTP_CODE_VERIFIED: t('Code is verified successfully.'),
  OTP_CODE_LENGTH: t('Code must be 6 characters long.'),
  OTP_CODE_PART_LENGTH: t('Each code part must be 3 characters long.'),
  OTP_CODE_INVALID: t('Provided code is not valid.'),
  PATIENT_STATE_REQUIRED: t('Patient state is required.'),
  BLANK_STRING_NOT_ALLOWED: t('Can not contain whitespace only.'),
  FIELD_NUMBER_ONLY: t('Please enter only numbers.'),
};

export const AMPLIFY_AUTH_CONFIG = {
  MANDATORY_SIGN_IN: true,
  IDENTITY_POOL_ID: env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  REGION: env.REACT_APP_COGNITO_REGION,
  USER_POOL_ID: env.REACT_APP_COGNITO_USER_POOL_ID,
  USER_POOL_WEB_CLIENT_ID: env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  REDIRECT_SIGN_OUT: env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT,
  AUTHENTICATION_FLOW_TYPE: 'USER_PASSWORD_AUTH',
  OAUTH: {
    DOMAIN: env.REACT_APP_COGNITO_OAUTH_DOMAIN,
    SCOPE: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    REDIRECT_SIGN_IN: env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGN_IN,
    REDIRECT_SIGN_OUT: env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGN_OUT,
    RESPONSE_TYPE: 'code',
  },
};

export const ZOOM_CONFIG = {
  SDK_CLIENT_KEY: env.REACT_APP_ZOOM_SDK_CLIENT_KEY,
  OAUTH_URL: env.REACT_APP_ZOOOM_OAUTH,
  ACCOUNT_ID: env.REACT_APP_ZOOM_ACCOUNT_ID,
  REDIRECT_URL: env.REACT_APP_ZOOM_REDIRECT_URL,
  API_URL: env.REACT_APP_ZOOM_API_URL,
};

export const ZOOM_MEETING_STATES = {
  FAILED: 'Fail',
  CLOSED: 'Closed',
};

export const INPUT_KEYS_RESTRICTION = {
  VISIT_TYPE_VISIT_FEE: ['-', '+', 'e', '^'],
  VISIT_TYPE_CANCELLATION_FEE: ['-', '+', 'e', '^'],
  PLAN_VISIT_FEE_DISCOUNT: ['-', '+', 'e', '^', '.', ','],
  PLAN_CANCELLATION_FEE_DISCOUNT: ['-', '+', 'e', '^', '.', ','],
};

export const CLINIC_VISIT_TYPE_LENGTH = [
  5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115,
  120,
].map((duration) => ({
  label: `${duration} min`,
  value: duration,
}));

// TODO: Update to FHIR supported types https://www.hl7.org/fhir/valueset-item-type.html
export const QUESTION_TYPES = {
  CUSTOM_STATEMENT: {
    title: 'Statement',
    description: '',
    type: 'display',
    originalType: 'statement',
    disabled: false,
    hasDataHook: false,
  },
  CUSTOM_FREE_TEXT: {
    title: 'Free Text',
    type: 'string',
    originalType: 'freeText',
    disabled: false,
    hasDataHook: false,
  },
  CUSTOM_EMAIL: {
    title: 'Email',
    type: 'string',
    originalType: 'email',
    disabled: false,
    hasDataHook: false,
  },
  CUSTOM_PHONE: {
    title: 'Phone',
    type: 'string',
    originalType: 'phone',
    disabled: false,
    hasDataHook: false,
  },
  CUSTOM_DATE: {
    title: 'Date',
    type: 'date',
    originalType: 'dateTime',
    disabled: false,
    hasDataHook: false,
  },
  CUSTOM_DATE_OF_BIRTH: {
    title: 'Date of Birth',
    type: 'date',
    originalType: 'dateOfBirth',
    disabled: false,
    hasDataHook: true,
  },
  CUSTOM_YES_NO: {
    title: 'Yes/No',
    type: 'boolean',
    originalType: 'yesNo',
    disabled: false,
    hasDataHook: false,
  },
  CUSTOM_DROPDOWN: {
    title: 'Dropdown',
    type: 'choice',
    originalType: 'dropdown',
    disabled: false,
    hasDataHook: false,
  },
  CUSTOM_SINGLE_SELECT: {
    title: 'Single Select',
    type: 'choice',
    originalType: 'singleSelect',
    disabled: false,
    hasDataHook: false,
  },
  CUSTOM_MULTI_SELECT: {
    title: 'Multi Select',
    type: 'choice',
    originalType: 'multiSelect',
    disabled: false,
    hasDataHook: false,
  },
  CUSTOM_FILE_UPLOAD: {
    title: 'File Upload',
    type: 'attachment',
    originalType: 'fileUpload',
    disabled: false,
    hasDataHook: false,
  },
  CUSTOM_MEDIA_FILE: {
    title: 'Media File',
    type: 'attachment',
    originalType: 'mediaFile',
    disabled: false,
    hasDataHook: false,
  },
  CUSTOM_CONSENT: {
    title: 'Consent',
    description: '',
    type: 'display',
    originalType: 'consent',
    disabled: false,
    hasDataHook: false,
  },
};

export const QUESTIONS_WITH_OPTIONS = [
  QUESTION_TYPES.CUSTOM_DROPDOWN.originalType,
  QUESTION_TYPES.CUSTOM_SINGLE_SELECT.originalType,
  QUESTION_TYPES.CUSTOM_MULTI_SELECT.originalType,
];

export const QUESTIONS_WITH_SKIP_LOGIC = [
  QUESTION_TYPES.CUSTOM_YES_NO.originalType,
  QUESTION_TYPES.CUSTOM_SINGLE_SELECT.originalType,
  QUESTION_TYPES.CUSTOM_DROPDOWN.originalType,
  'group',
];

export const QUESTIONNAIRE_AUTOSAVE_INTERVAL = 100;

export const QUESTIONNAIRE_AUTOSAVE_DATE_DISPLAY_FORMAT = 'hh:mm:ss a MMM dd, yyyy';

export const QUESTIONNAIRE_YES_NO_OPTIONS = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
];

export const QUESTIONNAIRE_MESSAGES = {
  SKIP_LOGIC_DELETE_OPTION_WARNING:
    'You cannot delete this option because it is used in skip logic. Update the skip logic then try again.',
  EMPTY_OPTION: 'Option cannot be empty.',
  DUPLICATE_OPTION: 'Option already exists.',
  ONE_PREMAPPED_QUESTION: 'You can only have one Date of Birth question per questionnaire.',
};

export const COMPLAINT_MESSAGES = {
  EMPTY_OPTION: 'Complaint cannot be empty.',
  DUPLICATE_OPTION: 'Complaint already exists.',
};

export const CENSUS_UPLOADS_STATUS = {
  // BACKEND: 'none','uploading','uploaded','cancelled','parsing','processing','successful','failed'
  NONE: 'none',
  UPLOADING: 'uploading',
  UPLOADED: 'uploaded',
  CANCELLED: 'cancelled',
  PARSING: 'parsing',
  PROCESSING: 'processing',
  SUCCESSFUL: 'successful',
  FAILED: 'failed',
  IN_PROGRESS: 'in-progress',
  COMPLETED: 'completed',
};

export const COMPANY_NAME = 'Direct2Care';
