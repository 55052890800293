import { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import LoaderButton from '../../../../../components/common/LoaderButton';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { selectFindMe, sendOtpThunk } from '../../../api/findMeSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { formatInTimeZone } from 'date-fns-tz';

type Props = {
  reqData: any;
  setVerCodeResUUID: Dispatch<SetStateAction<string>>;
  stepForward: () => void;
};

export const AccountFound: FunctionComponent<Props> = ({
  reqData,
  setVerCodeResUUID,
  stepForward,
}: Props) => {
  const { t }: any = useTranslation();

  const dispatch = useAppDispatch();

  const onSubmit = async ({ uuid }: { uuid: string }) => {
    try {
      const data = await dispatch(
        sendOtpThunk({
          uuid: uuid,
        }),
      );
      const reqData = unwrapResult(data);

      setVerCodeResUUID(reqData.resource.verification_code_resource_uuid);

      // SET STEPPER VALUE AND GO NEXT
      stepForward();
    } catch (error) {
      console.error('Submission error:', error);
    }
  };

  const formattedDate = formatInTimeZone(reqData.birthDate, 'UTC', 'MM/dd/yyyy');

  const { isLoading } = useAppSelector(selectFindMe);

  return (
    <>
      <div className='d-flex flex-column'>
        <h2 className='auth__page-title'>{t('Confirm your account')}</h2>
      </div>

      <div className='card mt-4 mb-4 shadow'>
        <h5 className='card-header'>{`${reqData.name.given[0]} ${reqData.name.family}`}</h5>
        <div className='card-body'>
          <p className='text-muted'>{formattedDate}</p>
          <p className='text-muted'>{`${reqData.address.city}, ${reqData.address.state}`}</p>
          <p className='mb-0 text-muted'>{reqData.organization}</p>
        </div>
      </div>

      <div>
        <p className='text-dark mt-2 mb-1'>{t('To confirm your account we’ll text you a code.')}</p>
        <LoaderButton
          className={`auth__btn-primary mt-3`}
          type='submit'
          onClick={() => onSubmit(reqData.uuid)}
          isSubmitting={isLoading}
        >
          {t('Confirm')}
        </LoaderButton>
      </div>
    </>
  );
};
