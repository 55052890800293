declare global {
  interface Window {
    env: any;
  }
}
type EnvType = {
  DOMAIN_NAME: string;
  REACT_APP_API_BASE_URL: string;
  REACT_APP_SOCKET_URL: string;
  REACT_APP_API_KEY: string;
  REACT_APP_API_MOCKING?: boolean;
  ESLINT_NO_DEV_ERRORS?: boolean;
  GENERATE_SOURCE_MAP?: boolean;
  REACT_APP_COGNITO_IDENTITY_POOL_ID: string;
  REACT_APP_COGNITO_REGION: string;
  REACT_APP_COGNITO_USER_POOL_ID: string;
  REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID: string;
  REACT_APP_COGNITO_USER_WEB_CLIENT_ID: string;
  REACT_APP_COGNITO_OAUTH_DOMAIN: string;
  REACT_APP_COGNITO_REDIRECT_SIGN_OUT: string;
  REACT_APP_COGNITO_OAUTH_REDIRECT_SIGN_IN: string;
  REACT_APP_COGNITO_OAUTH_REDIRECT_SIGN_OUT: string;
  REACT_APP_TEST_JWT_SECRET?: string;
  REACT_APP_VERSION?: string;
  REACT_APP_GOOGLE_PLACES_API_KEY?: string;
  REACT_APP_ZOOM_ACCOUNT_ID?: string;
  REACT_APP_ZOOOM_OAUTH?: string;
  REACT_APP_ZOOM_SDK_CLIENT_KEY?: string;
  REACT_APP_ZOOM_REDIRECT_URL?: string;
  REACT_APP_ZOOM_API_URL?: string;
  REACT_APP_SENTRY_DSN?: string;
  REACT_APP_SENTRY_TRACE_SAMPLE_RATE?: number;
  NODE_ENV?: string;
};

export const env: EnvType = { ...process.env, ...window.env };
