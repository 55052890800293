import { useTranslation } from 'react-i18next';
import VerificationInput from 'react-verification-input';

// TYPES
import { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
import { APIResponseStatus } from '../../../../api/types';

// COMPONENTS
import ResendCodeButton from '../../../../components/common/ResendCodeButton';
import LoaderButton from '../../../../components/common/LoaderButton';
import { resendOtpThunk, selectFindMe, verifyOtpThunk } from '../../api/findMeSlice';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { unwrapResult } from '@reduxjs/toolkit';
import alert from '../../../../utils/alert';

interface Props {
  stepForward: () => void;
  employeeInfo: APIResponseStatus | undefined;
  verCodeResUUID: string;
  verCode: string;
  setVerCode: Dispatch<SetStateAction<string>>;
}

export const OtpVerificationScreen: FunctionComponent<Props> = ({
  stepForward,
  employeeInfo,
  verCodeResUUID,
  setVerCode,
}) => {
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(selectFindMe);
  const [isResendLoading, setIsResendLoading] = useState<boolean>(false);

  const [code, setCode] = useState('');

  const onOtpSubmit = async () => {
    try {
      const data = await dispatch(
        verifyOtpThunk({
          verCode: code,
          verCodeResUUID: verCodeResUUID,
        }),
      );

      const reqData = unwrapResult(data);

      if (reqData.statusCode < 400) {
        setVerCode(code);
        stepForward();
      } else {
        alert.error(t(reqData.message));
      }
    } catch (error) {
      alert.error(t('Something went wrong. Please try again.'));
    }
  };

  const onResendSubmit = async () => {
    setIsResendLoading(true);
    try {
      const data = await dispatch(
        resendOtpThunk({
          verCodeResUUID: verCodeResUUID,
          phoneNumber: employeeInfo?.resource[0].phone_number,
        }),
      ).unwrap();

      alert.success(t(data.message));
    } catch (error) {
      alert.error(t('Something went wrong!'));
    } finally {
      setIsResendLoading(false);
    }
  };

  return (
    <div className='d-flex flex-column align-items-center text-center'>
      <p className='mt-3'>{t('Enter the 6-digit code texted to the phone number provided')}</p>
      <VerificationInput
        value={code}
        length={6}
        placeholder=''
        onChange={setCode}
        classNames={{
          character: 'otp-input',
          characterSelected: 'otp-input--selected',
        }}
        validChars='0-9'
        inputProps={{ inputMode: 'numeric' }}
      />
      <LoaderButton
        className='auth__btn-primary mt-4'
        type='submit'
        onClick={onOtpSubmit}
        isSubmitting={isLoading}
        disabled={code.length !== 6 ? true : false}
      >
        {t('Verify')}
      </LoaderButton>
      <ResendCodeButton
        onClick={onResendSubmit}
        label={t('Resend Code')}
        seconds={30}
        isLoading={isResendLoading}
      />
    </div>
  );
};
