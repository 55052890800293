import { Navigate, RouteObject } from 'react-router-dom';

import NotFound from '../../../components/common/NotFound';
import { LoginForm } from '../components/LoginForm';
import { CreateAccount } from '../../register/components/CreateAccount';
import ForgotPasswordEmailStep from '../../forgot-password/components/ForgotPassword';
import ResetPasswordSteps from '../../reset-password/components/ResetPassword';
import { FindMe } from '../components/find-me';

export const DEFAULT_AUTH_LAYOUT_ROUTE = 'login';

export const AuthRoutes: Array<RouteObject> = [
  { path: '*', element: <NotFound /> },
  { path: 'login', element: <LoginForm /> },
  { path: 'find-me', element: <FindMe /> },
  { path: 'forgot-password', element: <ForgotPasswordEmailStep /> },
  { path: 'reset-password', element: <ResetPasswordSteps /> },
  { path: 'reset-password?c=:code&u=:email', element: <ResetPasswordSteps /> },
  { path: 'register', element: <CreateAccount /> },
  { index: true, element: <Navigate to={DEFAULT_AUTH_LAYOUT_ROUTE} replace /> },
];
