import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { getEmployerThunk, selectFindMe } from '../../api/findMeSlice';
import { FindMeSchema } from '../../schemas';

// TYPES
import { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { FindMeFormData, UserInfo } from '.';
import { APIResponseStatus } from '../../../../api/types';

// COMPONENTS
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import LoaderButton from '../../../../components/common/LoaderButton';
import Input from '../../../../components/Input';
import Label from '../../../../components/Label';
import alert from '../../../../utils/alert';

type Props = {
  setEmployeeInfo: Dispatch<SetStateAction<APIResponseStatus | undefined>>;
  lookupUserFormData: FindMeFormData | undefined;
  setLookupUserFormData: Dispatch<SetStateAction<FindMeFormData | undefined>>;
  stepForward: () => void;
  setUserInfo: Dispatch<SetStateAction<UserInfo>>;
};

export const ConfirmInfoScreen: FunctionComponent<Props> = ({
  setEmployeeInfo,
  lookupUserFormData,
  setLookupUserFormData,
  stepForward,
  setUserInfo,
}) => {
  const { t }: any = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, dirtyFields },
    control,
  } = useForm<FindMeFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(FindMeSchema),
    defaultValues: lookupUserFormData,
  });

  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(selectFindMe);

  const onSubmit = async (values: FindMeFormData) => {
    setLookupUserFormData(values);
    try {
      const data = await dispatch(
        getEmployerThunk({
          lastName: values.lastName,
          dateOfBirth: moment(values.dob).format('YYYY-MM-DD'),
          phoneNumber: values.phoneNumber,
        }),
      ).unwrap();

      setEmployeeInfo(data);

      setUserInfo((userInfo: any) => ({
        ...userInfo,
        email: data.resource?.[0].email,
      }));

      stepForward();
    } catch (error) {
      alert.error(t('Something went wrong. Please try again.'));
    }
  };

  return (
    <>
      <div className='d-flex flex-column text-start'>
        <h2 className='auth__page-title'>{t('Confirm your information.')}</h2>
        <p className='auth__help-text'>{t('This information was provided by your employer.')}</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className='pt-2 text-start' noValidate>
        <Form.Group controlId='last-name' className='auth__input-group'>
          <Label>{t('Last Name')}</Label>
          <Input
            type='text'
            placeholder={t('Last Name')}
            className={clsx('auth__input-no-bootstrap-icon', [
              dirtyFields.lastName && errors.lastName && 'is-invalid',
            ])}
            {...register('lastName')}
          />
          {errors.lastName && <div className='invalid-feedback'>{errors.lastName.message}</div>}
        </Form.Group>

        <Form.Group className='form-group d-flex flex-column mb-4' controlId='dob'>
          <Label>{t('Date of Birth')}</Label>
          <Controller
            control={control}
            name='dob'
            render={({ field }) => (
              <div className='datepicker-wrapper'>
                <DatePicker
                  showIcon
                  onChange={(date: Date | null) => field.onChange(date)}
                  onBlur={field.onBlur}
                  selected={field.value}
                  placeholderText={t('Date of Birth')}
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  showMonthDropdown
                  dropdownMode='scroll'
                  maxDate={new Date()}
                  dateFormat='MM/dd/yyyy'
                  className='form-control datepicker-input'
                  calendarClassName='custom-datepicker-calendar'
                />
              </div>
            )}
          />
          {errors.dob && <div className='invalid-feedback d-block'>{errors.dob.message}</div>}
        </Form.Group>

        <Form.Group
          controlId='phoneNumber'
          className='auth__input-group auth__input-group--relative'
        >
          <Label>{t('Mobile Number')}</Label>
          <Input
            type='tel'
            placeholder={t('Mobile Number')}
            className={clsx('auth__input-no-bootstrap-icon', [
              dirtyFields.phoneNumber && errors.phoneNumber && 'is-invalid',
            ])}
            {...register('phoneNumber')}
          />
          {errors.phoneNumber && (
            <div className='invalid-feedback'>{errors.phoneNumber.message}</div>
          )}
        </Form.Group>

        <LoaderButton
          className={`auth__btn-primary mt-3`}
          type='submit'
          isSubmitting={isLoading}
          disabled={!isValid}
        >
          {t('Next')}
        </LoaderButton>
      </form>
    </>
  );
};
