import React, { CSSProperties } from 'react';

import { NAME_PREFIXES } from '../../config';
const namePrefixesArray = NAME_PREFIXES.map((prefix) => {
  return prefix.value;
});
interface AvatarPlaceholderProps {
  stringToUse?: string;
  style?: CSSProperties;
  singleCharacter?: boolean;
  title?: string;
  className?: string;
}
function getFirstCharacter(str: string, excludes: Array<string>) {
  const excludePattern = new RegExp(excludes.join('|'), 'g');
  const parsedString = str.replace(excludePattern, '').trim();
  return parsedString.charAt(0).toUpperCase();
}
const AvatarPlaceholder: React.FC<AvatarPlaceholderProps> = ({
  stringToUse,
  style,
  singleCharacter = true,
  title,
  className,
}) => {
  let str = stringToUse ?? '';

  if (singleCharacter) {
    str = stringToUse ? getFirstCharacter(stringToUse, namePrefixesArray) : str?.toUpperCase()?.[0];
  }

  return (
    <div
      title={title}
      style={{
        width: '64px',
        height: '64px',
        background: '#E0F2FE',
        borderRadius: '200px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        fontSize: '24px',
        lineHeight: '32px',
        textAlign: 'center',
        color: '#0086C9',
        ...style,
      }}
      className={className}
    >
      {str}
    </div>
  );
};

export default AvatarPlaceholder;
