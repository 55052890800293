import { Navigate, RouteObject } from 'react-router-dom';

import NotFound from '../../../components/common/NotFound';

export const APP_LAYOUT_ROUTES = {
  TODAYS_VISITS: { path: 'todays-visits', title: `Today's Visits` },
  CALENDAR: { path: 'calendar', title: 'Calendar' },
  PAST_VISITS: { path: 'past-visits', title: 'Past Visits' },
  MESSAGES: { path: 'messages', title: 'Messages' },
  PATIENTS: { path: 'patients', title: 'Patients' },
  MY_ACCOUNT: { path: 'my-account', title: 'My Account' },
  MY_CLINIC: { path: 'my-clinic', title: 'My Clinic' },
};

export const DEFAULT_APP_LAYOUT_ROUTE = APP_LAYOUT_ROUTES.TODAYS_VISITS.path;

export const AppRoutes: Array<RouteObject> = [
  { path: '*', element: <NotFound /> },
  { index: true, element: <Navigate to={DEFAULT_APP_LAYOUT_ROUTE} replace /> },
];
