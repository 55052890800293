import { v4 as uuidv4 } from 'uuid';

export enum NotificationTypeSocketCommand {
  List = 'List',
}

export type NotificationActionMessage = {
  reqType: string;
  reqId: string;
  messagingParams: {
    command: string;
    notireq: {
      type: NotificationTypeSocketCommand;
      key: any;
    };
  };
};

export function createNotificationActionMessage(
  notificationType: NotificationTypeSocketCommand,
): NotificationActionMessage {
  return {
    reqType: 'messaging',
    reqId: uuidv4(),
    messagingParams: {
      command: 'notiSubscribe',
      notireq: {
        type: notificationType,
        key: [
          {
            type: 'Organization',
            reference: 'direct2md',
          },
        ],
      },
    },
  };
}
