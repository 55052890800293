import './CustomModal.scss';
import clsx from 'clsx';
import { SyntheticEvent } from 'react';
import { Modal, ModalProps, Button } from 'react-bootstrap';

import LoaderButton from '../common/LoaderButton';

interface CustomModalProps extends ModalProps {
  title: string;
  titlePlacement?: 'top-row' | 'below-icon';
  children?: React.ReactNode | React.ReactFragment | React.ReactPortal | null;
  show: boolean;
  showCustomHeaderIcon?: boolean;
  customHeaderIcon?: React.ReactNode | React.ReactFragment | React.ReactPortal | null;
  helpText?: React.ReactNode | React.ReactFragment | string;
  isSubmitting?: boolean;
  isUpdating?: boolean;
  isDisabled?: boolean;
  customDialogClassName?: string;
  confirmText?: string;
  cancelText?: string;
  showCancelButton?: boolean;
  disableCloseButton?: boolean;
  isDisabledFooter?: boolean;
  hasEdited?: boolean;
  size?: 'sm' | 'lg' | 'xl' | undefined;
  /**
   * When true, the footer button's width stretches according to text length
   * All other buttons adapt to fill the space accordingly to their width
   * - Flex behavior
   */
  useFlexibleFooter?: boolean;
  handleConfirm?: (e?: SyntheticEvent, shouldOnlySave?: boolean) => void;
  handleCancel?: (e?: SyntheticEvent) => void;
  editPersonalDetails?: boolean;
  editOrgDetails?: boolean;
  isMinor?: boolean;
}

const CustomModal = ({
  title,
  titlePlacement = 'below-icon',
  children,
  show,
  showCustomHeaderIcon,
  customHeaderIcon,
  helpText,
  isSubmitting,
  isUpdating,
  isDisabled,
  customDialogClassName = '',
  confirmText = 'OK',
  cancelText = 'Cancel',
  showCancelButton = true,
  disableCloseButton = false,
  isDisabledFooter = false,
  useFlexibleFooter = false,
  hasEdited = false,
  handleConfirm,
  handleCancel,
  size,
  editOrgDetails,
  editPersonalDetails,
  isMinor = false,
  ...rest
}: CustomModalProps): JSX.Element => {
  return (
    <Modal
      show={show}
      fullscreen={'md-down'}
      centered
      contentClassName='custom-modal__content'
      dialogClassName={`${customDialogClassName} custom-modal__dialog`}
      onHide={handleCancel}
      size={size}
      {...rest}
    >
      {!disableCloseButton && (
        <Modal.Header closeButton className='custom-modal__header'>
          <Modal.Title>
            <div className='d-flex flex-row flex-nowrap align-items-center gap-2'>
              {showCustomHeaderIcon && customHeaderIcon}
              {(!showCustomHeaderIcon || titlePlacement === 'top-row') && (
                <h4 className='custom-modal__title mb-0'>{title}</h4>
              )}
            </div>
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body className='custom-modal__body'>
        {showCustomHeaderIcon && titlePlacement === 'below-icon' && (
          <h4 className='custom-modal__title'>{title}</h4>
        )}
        {helpText && <div className='custom-modal__help-text pb-2'>{helpText}</div>}
        {children}
      </Modal.Body>
      {!isDisabledFooter && (
        <Modal.Footer
          className={clsx([
            'mt-2',
            'custom-modal__footer',
            `custom-modal__footer--${useFlexibleFooter ? 'flex' : 'grid'}`,
          ])}
        >
          {showCancelButton && (
            <Button
              onClick={(e) => handleCancel?.(e)}
              variant={'secondary'}
              className='custom-modal__btn m-0'
              type={'button'}
            >
              {cancelText}
            </Button>
          )}
          {hasEdited && (
            <LoaderButton
              className={`custom-modal__btn m-0`}
              onClick={(e) => handleConfirm?.(e, true)}
              type={'button'}
              isSubmitting={isUpdating}
              disabled={isDisabled}
            >
              Save
            </LoaderButton>
          )}
          {!isMinor && (
            <LoaderButton
              className={`custom-modal__btn m-0`}
              onClick={(e) => handleConfirm?.(e, false)}
              type={'button'}
              isSubmitting={isSubmitting}
              disabled={editPersonalDetails || editOrgDetails}
            >
              {confirmText}
            </LoaderButton>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default CustomModal;
