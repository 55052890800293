import Cookies from 'js-cookie';

import {
  API_ACCESS_TOKEN_COOKIE_NAME,
  LAST_ACTIVITY_TRACKER_COOKIE,
  SESSION_IDLE_MINUTES,
} from '../config';

export const upsertLastActivityCookie = () => {
  const expiresAt = new Date();
  expiresAt.setMinutes(expiresAt.getMinutes() + SESSION_IDLE_MINUTES);
  Cookies.set(LAST_ACTIVITY_TRACKER_COOKIE, `${expiresAt.getTime()}`, {
    expires: expiresAt,
    secure: true,
  });
};

export const removeLastActivityCookie = () => {
  Cookies.remove(LAST_ACTIVITY_TRACKER_COOKIE);
};

export const verifyLastActivityCookieSession = () => {
  const lastActivityCookieTimestamp = Cookies.get(LAST_ACTIVITY_TRACKER_COOKIE);
  return lastActivityCookieTimestamp ? true : false;
};

export const setAccessTokenCookie = (token: { accessToken: string; expiresAt: number }) => {
  Cookies.set(API_ACCESS_TOKEN_COOKIE_NAME, token.accessToken, {
    secure: true,
    expires: new Date(token.expiresAt * 1000),
  });
};

export const getAccessTokenCookie = () => {
  return Cookies.get(API_ACCESS_TOKEN_COOKIE_NAME);
};

export const removeAccessTokenCookie = () => {
  Cookies.remove(API_ACCESS_TOKEN_COOKIE_NAME);
};

export const verifyAllSessionCookies = () => {
  return getAccessTokenCookie() && verifyLastActivityCookieSession() ? true : false;
};

// TODO
// export const verifyAccessToken = () => {}
