import { AxiosResponse } from 'axios';

import { API } from '../../utils/axios';
import { APIResponseStatus } from '../types';

interface CreateSessionResponse extends APIResponseStatus {
  resource: {
    accessToken: string;
    expiresAt: number;
    userrole?: string[];
  };
}
interface CreateWSSessionResponse extends APIResponseStatus {
  resource: {
    wsToken: string;
    expiresAt: number;
  };
}

class AuthService {
  static createSession(params: { accessToken: string }) {
    return API.post<any, AxiosResponse<CreateSessionResponse>>('v1/auth/sessions', {
      resource: { ...params, userType: 'Patient' },
    });
  }

  static createWSSession(params: { deviceId: string }) {
    return API.post<any, AxiosResponse<CreateWSSessionResponse>>('v1/auth/sessions/ws', {
      resource: { ...params },
    });
  }
}

export default AuthService;
