import { AxiosResponse } from 'axios';

import { API } from '../../utils/axios';
import { APIResponseStatus } from '../types';
import { env } from '../../env';

interface SearchPatientResponse extends APIResponseStatus {
  resource: {
    resourceType: string;
    type: string;
    entry: Array<{ resource: fhir4.Patient }>;
    total?: string | number;
  };
}

interface DeviceSessionResponse {
  resource: {
    accessToken: string;
    expiresAt: string;
  };
}

class RegisterAccountervice {
  static async register(params: any, accessToken: string, callback: (success: boolean) => void) {
    try {
      const res = await API.post<any, AxiosResponse<SearchPatientResponse>>(
        `${env.REACT_APP_API_BASE_URL}/v1/resources/Patient`,
        params,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      callback(true);
      return res.data.resource;
    } catch (error) {
      callback(false);
      throw error;
    }
  }

  static async search(params: any, accessToken: string) {
    try {
      const result = await API.post<any, AxiosResponse<any>>(
        `${env.REACT_APP_API_BASE_URL}/v1/resources/Person/_search`,
        params,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return result.data.resource;
    } catch (error) {
      console.error('Search error:', error);
      throw error;
    }
  }

  static async createDeviceSession(deviceId: string) {
    try {
      const session = await API.post<any, AxiosResponse<DeviceSessionResponse>>(
        `${env.REACT_APP_API_BASE_URL}/v1/auth/sessions`,
        { resource: { deviceId, userType: 'Patient' } },
      );
      const { accessToken, expiresAt } = session.data.resource;
      return { accessToken, expiresAt };
    } catch (error) {
      console.error('Create device session error:', error);
      throw error;
    }
  }
}

export default RegisterAccountervice;
