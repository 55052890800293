import { Navigate, RouteObject } from 'react-router-dom';

import { AppRoutes } from '../features/app/routes';
import { AppLayout } from '../layouts/AppLayout';

export const protectedRoutes: Array<RouteObject> = [
  {
    path: '*',
    element: <Navigate to={'/'} replace />,
  },
  {
    index: true,
    element: <Navigate to='./app' replace />,
  },
  {
    path: '/app/*',
    element: <AppLayout />,
    children: AppRoutes,
  },
];
