import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { useNavigate } from 'react-router-dom';
import { loginUserThunk, selectAuthState } from '../../api/authSlice';

// TYPES
import { FunctionComponent } from 'react';
import { UserInfo } from '.';

// COMPONENTS
import alert from '../../../../utils/alert';
import SuccessIcon from '../../../../assets/images/icons/success-icon.png';
import LoaderButton from '../../../../components/common/LoaderButton';

type Props = {
  userInfo: UserInfo;
};

export const AccountCreatedScreen: FunctionComponent<Props> = ({ userInfo }) => {
  const { t }: any = useTranslation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(selectAuthState);

  const onSubmit = async () => {
    try {
      const { email, password } = userInfo;
      const res = await dispatch(loginUserThunk({ username: email, password })).unwrap();
      if (res.status === 'success') navigate('/');
    } catch (error: Error | any) {
      alert.error(t('Something went wrong. Please try again.'));
    }
  };

  return (
    <>
      <div className='d-flex flex-column text-center align-items-center'>
        <h2 className='auth__page-title'>{t('Account Created')}</h2>
        <img src={SuccessIcon} alt='account-created-icon' width='70%' height='70%' />
        <p className='auth__help-text'>
          {t('Your account was successfully created, please click login to continue.')}
        </p>
        <LoaderButton
          className='auth__btn-primary mt-3'
          type='submit'
          isSubmitting={isLoading}
          onClick={onSubmit}
        >
          {t('Login')}
        </LoaderButton>
      </div>
    </>
  );
};
