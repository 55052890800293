import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
  UnknownAction,
} from '@reduxjs/toolkit';
import { Area } from 'react-easy-crop';

import AccountService from '../../../api/services/AccountService';
import { RootState } from '../../../store';
import { CommonAvatar } from '../../../types';
import { AccountUser } from '../types';

type AccountState = {
  patient: any | null;
  status: 'pending' | 'success' | 'failure';
  isLoading: boolean;
  resetPasswordSuccess: boolean;
  avatar: { id: string; uuid: string; url: string; name: string; type: string } | null;
  newAvatar?: boolean;
  newAvatarCropped?: boolean;
  newAvatarCroppedAreaPixels?: Area | null;
  avatarDeleted?: boolean;
  phoneNr?: string;
  phoneNrVerified?: boolean;
  phoneVerificationId?: string;
  phoneVerificationExpiresAt?: string;
  lastProcessRequiringAuth?: 'email' | 'phone' | 'password';
  showTimezoneChangeModal: boolean;
  error?: string;
  sidebarMenuCollapsed: boolean;
};

export const SYSTEM_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

const initialState: AccountState = {
  patient: null,
  status: 'pending',
  isLoading: false,
  resetPasswordSuccess: false,
  avatar: null,
  newAvatar: false,
  newAvatarCropped: false,
  newAvatarCroppedAreaPixels: null,
  avatarDeleted: false,
  phoneNr: '',
  phoneNrVerified: false,
  phoneVerificationId: '',
  phoneVerificationExpiresAt: '',
  lastProcessRequiringAuth: 'email',
  showTimezoneChangeModal: false,
  error: '',
  sidebarMenuCollapsed: false,
};

const ACTIONS_PREFIX = 'account';

export const getMeThunk = createAsyncThunk(`${ACTIONS_PREFIX}/getMe`, AccountService.getMe);

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    clearAvatarDeletedState: (state) => {
      state.avatarDeleted = false;
    },
    setNewAvatarUploaded: (
      state,
      action: PayloadAction<{
        newAvatar: boolean;
        cropped: boolean;
        croppedArea: Area | null;
      }>,
    ) => {
      state.newAvatar = action.payload.newAvatar;
      state.newAvatarCropped = action.payload.cropped;
      state.newAvatarCroppedAreaPixels = action.payload.croppedArea;
    },
    setAvatarDeleted: (
      state,
      action: PayloadAction<{
        avatarDeleted: boolean;
      }>,
    ) => {
      state.avatarDeleted = action.payload.avatarDeleted;
    },
    setShowTimezoneChangeModal: (state, action: PayloadAction<boolean>) => {
      state.showTimezoneChangeModal = action.payload;
    },
    clearAccountState: () => initialState,
    toggleMenuCollapsed: (state) => {
      state.sidebarMenuCollapsed = !state.sidebarMenuCollapsed;
    },
    setMenuCollapsed: (state, action: PayloadAction<boolean>) => {
      state.sidebarMenuCollapsed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMeThunk.fulfilled, (state, action) => {
      const patient = action.payload.resource as AccountUser;
      state.patient = patient;
      state.avatar = patient?.avatar?.[0] as CommonAvatar;
    });
    builder.addMatcher(
      (action: UnknownAction) =>
        (action.type as string).startsWith(ACTIONS_PREFIX) &&
        (action.type as string).endsWith('/fulfilled'),
      (state) => {
        Object.assign(state, {
          isLoading: false,
          status: 'success',
        });
      },
    );
    builder.addMatcher(
      (action: UnknownAction) =>
        (action.type as string).startsWith(ACTIONS_PREFIX) &&
        (action.type as string).endsWith('/pending'),
      (state) => {
        Object.assign(state, {
          isLoading: true,
          status: 'loading',
          error: '',
        });
      },
    );
    builder.addMatcher(
      (action: UnknownAction) =>
        (action.type as string).startsWith(ACTIONS_PREFIX) &&
        (action.type as string).endsWith('/rejected'),
      (state, action) => {
        Object.assign(state, {
          isLoading: false,
          status: 'failure',
          error: action || '',
        });
      },
    );
  },
});

// SELECTORS
export const selectAccountState = (state: RootState) => state.account;
export const selectPractitioner = (state: RootState) => state.account.patient;
export const selectPractitionerTimezone = createSelector(
  selectPractitioner,
  (practitioner) => practitioner?.preference?.timezone || SYSTEM_TIMEZONE,
);
// ACTIONS
export const {
  clearAccountState,
  clearAvatarDeletedState,
  setNewAvatarUploaded,
  setAvatarDeleted,
  setShowTimezoneChangeModal,
  toggleMenuCollapsed,
  setMenuCollapsed,
} = accountSlice.actions;
// REDUCER
export default accountSlice.reducer;
