// TYPES
import { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { APIResponseStatus } from '../../../../api/types';

// COMPONENTS
import { AccountFound } from './extra-pages/AccountFound';
import { AccountNotFound } from './extra-pages/AccountNotFound';
import { AccountAlreadyExists } from './extra-pages/AccountAlreadyExists';

type Props = {
  employeeInfo: APIResponseStatus | undefined;
  setVerCodeResUUID: Dispatch<SetStateAction<string>>;
  stepForward: () => void;
};

export const AccountStatusScreen: FunctionComponent<Props> = ({
  employeeInfo,
  setVerCodeResUUID,
  stepForward,
}) => {
  if (employeeInfo?.statusCode === 200) {
    const reqData = employeeInfo.resource[0];

    return (
      <AccountFound
        reqData={reqData}
        setVerCodeResUUID={setVerCodeResUUID}
        stepForward={stepForward}
      />
    );
  } else if (employeeInfo?.statusCode === 409) {
    return <AccountAlreadyExists employeeInfo={employeeInfo} />;
  } else {
    return <AccountNotFound employeeInfo={employeeInfo} />;
  }
};
