import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { FieldError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { resetPasswordWithOtpThunk, selectFindMe } from '../../api/findMeSlice';
import { FindMeCreateAccountSchema } from '../../schemas';

// TYPES
import { FunctionComponent, useEffect, useState } from 'react';

// COMPONENTS
import { Button, Form, Modal } from 'react-bootstrap';
import LoaderButton from '../../../../components/common/LoaderButton';
import Input from '../../../../components/Input';
import Label from '../../../../components/Label';
import alert from '../../../../utils/alert';
import { PasswordValidationErrors } from '../../../../components/common/PasswordValidationErrors';

import eyeOffIcon from '../../../../assets/images/icons/eye-off.svg';
import eyeIcon from '../../../../assets/images/icons/eye.svg';
import asterixIcon from '../../../../assets/images/icons/asterix.png';

interface Props {
  stepForward: () => void;
  userInfo: any;
  setUserInfo: any;
  verCodeResUUID: string;
  verCode: string;
  employeeInfo: any;
}

interface CreateAccount {
  email: string;
  password: string;
  confirmPassword: string;
  agreeToTerms: boolean;
}

export const CreateAccountScreen: FunctionComponent<Props> = ({
  userInfo,
  verCode,
  verCodeResUUID,
  employeeInfo,
  stepForward,
  setUserInfo,
}) => {
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();

  const [inputFieldType, setInputFieldType] = useState('password');
  const [showTermsModal, setShowTermsModal] = useState(false);

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    formState: { errors, isValid, dirtyFields },
  } = useForm<CreateAccount>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(FindMeCreateAccountSchema),
  });

  const { isLoading } = useAppSelector(selectFindMe);

  const togglePasswordVisibility = () => {
    setInputFieldType(inputFieldType === 'text' ? 'password' : 'text');
  };

  const onSubmit = async () => {
    try {
      await dispatch(
        resetPasswordWithOtpThunk({
          username: userInfo.email,
          password: getValues('password'),
          verCodeResUUID: verCodeResUUID,
          verCode: verCode,
          firstName: employeeInfo.resource[0].name.given[0],
        }),
      ).unwrap();

      setUserInfo((userInfo: any) => ({
        ...userInfo,
        password: getValues('password'),
      }));
      stepForward();
    } catch (error) {
      alert.error(t('Something went wrong. Please try again.'));
    }
  };

  // AUTOFILL EMAIL FIELD
  useEffect(() => {
    setValue('email', userInfo.email, { shouldValidate: true, shouldDirty: true });
  }, [userInfo.email, setValue]);

  return (
    <>
      <h2 className='auth__page-title'>{t('Create your account')}</h2>
      <form onSubmit={handleSubmit(onSubmit)} className='auth__form--width mx-auto'>
        <Form.Group controlId='email' className='auth__input-group'>
          <Label>{t('Email')}</Label>
          <Input
            type='text'
            placeholder={t('Email')}
            className={clsx(
              [errors.email && 'is-invalid'],
              [dirtyFields.email && !errors.email ? 'is-valid' : ''],
            )}
            disabled
            {...register('email')}
          />
          <p className='email-provided-by-employer'>
            {t('This email was provided by you to your employer')}
          </p>
        </Form.Group>

        <Form.Group controlId='password' className='auth__input-group auth__input-group--relative'>
          <Label>{t('Password')}</Label>
          <Input
            type={inputFieldType}
            placeholder={t('Password')}
            className={clsx('auth__input-no-bootstrap-icon', [errors.password && 'is-invalid'])}
            {...register('password', { onChange: () => trigger('confirmPassword') })}
          />
          <span
            role={'button'}
            tabIndex={0}
            onKeyDown={(e) => e.key === 'Enter' && togglePasswordVisibility()}
            className='auth__input-group-icon'
            onClick={togglePasswordVisibility}
          >
            {inputFieldType === 'password' ? (
              <img src={eyeOffIcon} width={20} height={20} alt='eye-off-icon' />
            ) : (
              <img src={eyeIcon} width={20} height={20} alt='eye-icon' />
            )}
          </span>
        </Form.Group>

        <Form.Group
          controlId='confirmPassword'
          className='auth__input-group auth__input-group--relative'
        >
          <Label>{t('Confirm password')}</Label>
          <Input
            type={inputFieldType}
            placeholder={t('Confirm password')}
            className={clsx('auth__input-no-bootstrap-icon', [
              errors.confirmPassword && 'is-invalid',
            ])}
            {...register('confirmPassword', { onChange: () => trigger('password') })}
          />
          <span
            role={'button'}
            tabIndex={0}
            onKeyDown={(e) => e.key === 'Enter' && togglePasswordVisibility()}
            className='auth__input-group-icon'
            onClick={togglePasswordVisibility}
          >
            {inputFieldType === 'password' ? (
              <img src={eyeOffIcon} width={20} height={20} alt='eye-off-icon' />
            ) : (
              <img src={eyeIcon} width={20} height={20} alt='eye-icon' />
            )}
          </span>
        </Form.Group>

        <PasswordValidationErrors
          errors={errors as FieldError[]}
          showPasswordGuide={!dirtyFields.password && !dirtyFields.confirmPassword}
        />

        <Form.Group className='form-group terms-group' controlId='formTerms'>
          <Form.Check
            type='checkbox'
            {...register('agreeToTerms')}
            label={
              <span>
                {t('I agree to the')}{' '}
                <button
                  className='terms-button'
                  onClick={(e) => {
                    e.preventDefault();
                    setShowTermsModal(true);
                  }}
                >
                  {t('Terms & Conditions')}
                </button>
              </span>
            }
          />
          {errors.agreeToTerms && (
            <div className='invalid-feedback'>{errors.agreeToTerms.message}</div>
          )}
        </Form.Group>

        <LoaderButton
          className='auth__btn-primary mt-3'
          type='submit'
          isSubmitting={isLoading}
          disabled={!isValid}
        >
          {t('Continue')}
        </LoaderButton>
      </form>
      <Modal
        show={showTermsModal}
        onHide={() => setShowTermsModal(false)}
        dialogClassName='terms-modal'
      >
        <div className='terms-modal-img-container'>
          <img src={asterixIcon} alt='asterix-icon' className='terms-icon' />
        </div>
        <Modal.Header>
          <Modal.Title>{t('Terms & Conditions')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='terms-description'>{t('Terms and Conditions content')}</p>
        </Modal.Body>
        <Modal.Footer className='d-flex align-center'>
          <Button
            variant='btn btn-primary auth__btn-primary'
            onClick={() => setShowTermsModal(false)}
          >
            {t('Close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
