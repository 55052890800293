import React, { ComponentPropsWithoutRef, forwardRef } from 'react';

import loadingIcon from '../../assets/images/icons/loader.svg';

interface Props extends ComponentPropsWithoutRef<'button'> {
  /**
   * This prop is responsible for the style of the button
   * either when isSubmitting prop or disabled prop is set to true
   */
  disabledClassName?: string;
  isSubmitting?: boolean;
}

const DEFAULT_CLASS = 'btn btn-primary text-white';

const LoaderButton = (props: Props, ref: React.LegacyRef<HTMLButtonElement>) => {
  const { isSubmitting, disabledClassName, className, ...defaultProps } = props;
  const isDisabled = defaultProps.disabled || isSubmitting;

  return (
    <button
      {...defaultProps}
      ref={ref}
      className={`${DEFAULT_CLASS} ${className || ''} ${
        isDisabled ? disabledClassName || 'disabled' : ''
      }`}
      onClick={(e) => !isSubmitting && defaultProps.onClick?.(e)}
      disabled={isDisabled}
    >
      {isSubmitting ? (
        <img src={loadingIcon} alt='loading-spinner' className='spin' />
      ) : (
        defaultProps.children
      )}
    </button>
  );
};

export default forwardRef(LoaderButton);
