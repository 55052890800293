// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 791px) {
  .custom-modal__dialog_wide {
    max-width: 510px;
  }
  .custom-modal__skip_logic {
    max-width: 400px;
  }
}
.custom-modal__content {
  padding: 24px;
  border-radius: 12px;
}

.custom-modal__header {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  border: none;
}

.custom-modal__body {
  height: 100%;
  overflow-y: auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  padding: 0;
}

.custom-modal__title {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
}

.custom-modal__footer {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  border: none;
}
.custom-modal__footer--grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
  grid-gap: 10px;
}
.custom-modal__footer--flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  justify-content: center;
}
.custom-modal__footer--flex > * {
  flex-grow: 1;
}

.custom-modal__btn {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  height: 44px;
}

.custom-modal__body .auth__input {
  max-width: 100%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/modal/CustomModal.scss"],"names":[],"mappings":"AAAA;EACE;IACE,gBAAA;EACF;EAEA;IACE,gBAAA;EAAF;AACF;AAGA;EACE,aAAA;EACA,mBAAA;AADF;;AAIA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,YAAA;AADF;;AAIA;EACE,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,UAAA;AADF;;AAIA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AADF;;AAIA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;AADF;AAGE;EACE,aAAA;EACA,0DAAA;EACA,cAAA;AADJ;AAIE;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,SAAA;EACA,uBAAA;AAFJ;AAII;EACE,YAAA;AAFN;;AAOA;EACE,8CAAA;EACA,kBAAA;EACA,YAAA;AAJF;;AAQE;EACE,eAAA;EACA,WAAA;AALJ","sourcesContent":["@media screen and (min-width: 791px) {\n  .custom-modal__dialog_wide {\n    max-width: 510px;\n  }\n\n  .custom-modal__skip_logic {\n    max-width: 400px;\n  }\n}\n\n.custom-modal__content {\n  padding: 24px;\n  border-radius: 12px;\n}\n\n.custom-modal__header {\n  padding-left: 0;\n  padding-right: 0;\n  padding-top: 0;\n  border: none;\n}\n\n.custom-modal__body {\n  height: 100%;\n  overflow-y: auto;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n  color: #667085;\n  padding: 0;\n}\n\n.custom-modal__title {\n  font-weight: 500;\n  font-size: 18px;\n  line-height: 28px;\n  color: #101828;\n}\n\n.custom-modal__footer {\n  padding-left: 0;\n  padding-right: 0;\n  padding-bottom: 0;\n  border: none;\n\n  &--grid {\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));\n    grid-gap: 10px;\n  }\n\n  &--flex {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    gap: 10px;\n    justify-content: center;\n\n    &>* {\n      flex-grow: 1;\n    }\n  }\n}\n\n.custom-modal__btn {\n  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);\n  border-radius: 8px;\n  height: 44px;\n}\n\n.custom-modal__body {\n  .auth__input {\n    max-width: 100%;\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
