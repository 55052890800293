import { AxiosResponse } from 'axios';
import { API } from '../../utils/axios';
import { Employer } from '../../features/auth/api/findMeSlice';

// TYPES
import { APIResponseStatus } from '../types';

class FindMeService {
  static getEmployer(lastName: string, dateOfBirth: string, phoneNumber: string) {
    return API.get<any, AxiosResponse<APIResponseStatus<Employer[]>>>(
      `v1/auth/account-lookup/get-user-info`,
      {
        params: {
          lastName: lastName,
          dateOfBirth: dateOfBirth,
          phoneNumber: phoneNumber,
        },
      },
    ).then((res) => res.data);
  }

  static sendOtp(uuid: string) {
    return API.get<any, AxiosResponse<APIResponseStatus>>(
      `/v1/auth/account-lookup/send-otp/${uuid}`,
    ).then((res) => res.data);
  }

  static verifyOtp(verCode: string, verCodeResUUID: string) {
    const requestData = {
      resource: {
        verificationCodeResourceUuid: verCodeResUUID,
        verificationCode: verCode,
      },
    };

    return API.post<any, AxiosResponse<APIResponseStatus>>(
      `/v1/auth/account-lookup/verify-code`,
      requestData,
      {
        // adding validation status condition here so that all responses can be captured from backend
        validateStatus: function (status) {
          return status < 600;
        },
      },
    ).then((res) => res.data);
  }

  static resendOtp(verCodeResUUID: string, phoneNumber: string) {
    const requestData = {
      resource: {
        verificationCodeResourceUuid: verCodeResUUID,
        phoneNumber: phoneNumber,
      },
    };

    return API.post<any, AxiosResponse<APIResponseStatus>>(
      `/v1/auth/account-lookup/resend-code`,
      requestData,
    ).then((res) => res.data);
  }

  static resetPasswordWithOtp(
    username: string,
    password: string,
    verCodeResUUID: string,
    verCode: string,
    firstName: string,
  ) {
    const requestData = {
      resource: {
        username: username,
        password: password,
        verification_code_uuid: verCodeResUUID,
        verification_code: verCode,
        firstName: firstName,
      },
    };

    return API.put<any, AxiosResponse<APIResponseStatus>>(
      `/v1/auth/account-lookup/update-user-password`,
      requestData,
    ).then((res) => res.data);
  }
}

export default FindMeService;
