import { createSlice } from '@reduxjs/toolkit';

import alert from '../../utils/alert';

const initialState = {
  showNotification: false,
  message: '',
  title: '',
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    showError(state, action) {
      state.showNotification = true;
      const { title, message } = action.payload;
      state.message = message;
      state.title = title;
    },
    clearError(state) {
      state.showNotification = false;
      state.message = '';
      state.title = '';
    },
  },
});

export const errorActions = errorSlice.actions;
export const showError = (message: string, title: string) => (dispatch: any) => {
  alert.error(`${title} - ${message}`, { toastId: 'general-error' });
  return dispatch(errorActions.showError({ message, title }));
};

export const clearError = () => (dispatch: any) => {
  dispatch(errorActions.clearError());
};
