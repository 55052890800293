import axios, {
  AxiosInstance,
  AxiosResponse,
  AxiosError,
  type AxiosRequestConfig,
  type InternalAxiosRequestConfig,
  type AxiosRequestHeaders,
} from 'axios';

import { API_BASE_URL, API_HTTP_CODES, FIELD_TEXT_MESSAGES } from '../config';
import { env } from '../env';
import { store } from '../store';
import { errorActions } from '../store/error/error.slice';

import { getAccessTokenCookie } from './sessionCookies';

const headers: Readonly<Record<string, string | boolean>> = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'POST, PUT, GET, OPTIONS, DELETE',
  'X-Requested-With': 'XMLHttpRequest',
  'Content-Type': 'application/json',
  'X-Api-Key': env.REACT_APP_API_KEY,
};

function injectToken(config: InternalAxiosRequestConfig) {
  const token = getAccessTokenCookie();
  if (config.headers === undefined) config.headers = {} as AxiosRequestHeaders;
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
    // config.headers['x-api-key'] = env.REACT_APP_API_KEY as string;
  }
  config.headers.accept = 'application/json';
  return config;
}

export class Http {
  private instance: AxiosInstance | null = null;
  private url: string | undefined = undefined;

  constructor(url?: string) {
    this.url = url;
  }

  private get _http(): AxiosInstance {
    return this.instance !== null ? this.instance : this.initHttp();
  }

  initHttp() {
    const baseURL = this.url ? this.url : API_BASE_URL;
    const axiosInstance = axios.create({
      baseURL,
      headers,
      // withCredentials: true,
    });

    axiosInstance.interceptors.request.use(injectToken, (error) => Promise.reject(error));

    axiosInstance.interceptors.response.use(
      (response) => response,
      (error: Error | AxiosError | any) => {
        if (axios.isCancel(error)) {
          return Promise.resolve({ status: 499 });
        }

        const errorMessage = this._getErrorMessage(error);

        store.dispatch(
          errorActions.showError({
            title: 'Error',
            message: errorMessage,
          }),
        );
        return Promise.reject(
          error.response?.data && {
            ...error.response.data,
            message: errorMessage,
          },
        );
      },
    );
    this.instance = axiosInstance;
    return axiosInstance;
  }

  request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this._http.request(config);
  }

  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this._http.get<T, R>(url, config);
  }

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this._http.post<T, R>(url, data, config);
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this._http.put<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this._http.delete<T, R>(url, config);
  }

  private _getErrorMessage(error: AxiosError | any) {
    let errorMessage = FIELD_TEXT_MESSAGES.GENERAL_ERROR;

    if (error.response) {
      switch (error.response.status) {
        // case API_HTTP_CODES.BAD_REQUEST:
        //   errorMessage = 'Bad request.';
        //   break;
        case API_HTTP_CODES.UNAUTHORIZED:
          errorMessage = 'Unauthorized.';
          break;
        case API_HTTP_CODES.FORBIDDEN:
          errorMessage = 'Forbidden.';
          break;
        case API_HTTP_CODES.NOT_FOUND:
          errorMessage = 'Resource not found.';
          break;
        case API_HTTP_CODES.TOO_MANY_REQUESTS:
          errorMessage = 'Too many requests.';
          break;
        case API_HTTP_CODES.INTERNAL_SERVER_ERROR:
          errorMessage = 'Internal server error.';
          break;
        default:
          errorMessage =
            error?.response.data?.log?.error?.message ||
            error?.response.data?.message ||
            error?.statusText ||
            FIELD_TEXT_MESSAGES.GENERAL_ERROR;
      }
    }
    return errorMessage;
  }
}

export const API = new Http();
