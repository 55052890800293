import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { useRoutes } from 'react-router-dom';

import './styles/index.scss';
import App from './App';
import { env } from './env';
import reportWebVitals from './reportWebVitals';
import './i18n/index';

Sentry.init({
  dsn: env.REACT_APP_SENTRY_DSN,
  integrations: [],
  // tracesSampleRate: 1.0 this setting ensures that 100% of transactions will be sent to Sentry, but we recommend lowering this number in production.
  tracesSampleRate: env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE || 1.0,
});

export const useSentryRoutes = Sentry.wrapUseRoutes(useRoutes);

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
