import { Navigate, RouteObject } from 'react-router-dom';

import { AuthRoutes } from '../features/auth';
import AuthLayout from '../layouts/AuthLayout';

export const publicRoutes: Array<RouteObject> = [
  {
    path: '*',
    element: <Navigate to={'/'} replace />,
  },
  {
    index: true,
    element: <Navigate to='./auth' replace />,
  },
  {
    path: 'auth/*',
    element: <AuthLayout />,
    children: AuthRoutes,
  },
];
