import clsx from 'clsx';
import React, {
  forwardRef,
  useState,
  FunctionComponent,
  useCallback,
  useMemo,
  ComponentProps,
} from 'react';
import ReactSelect, {
  components,
  ControlProps,
  GroupBase,
  OptionProps,
  StylesConfig,
  InputProps,
} from 'react-select';

import checkIcon from '../../../assets/images/icons/check.svg';
import chevronDownIcon from '../../../assets/images/icons/chevron-down.svg';
import chevronUpIcon from '../../../assets/images/icons/chevron-up.svg';
import searchErrorIcon from '../../../assets/images/icons/search-error.svg';
import searchIcon from '../../../assets/images/icons/search.svg';

import './Select.scss';
import isNullOrUndefined from '../../../utils/isNullOrUndefined';
import { CommonDropdownType } from '../../../types';
import CancelActionModal from './CancelModal';

enum BORDER_COLORS {
  default = '#D0D5DD',
  focused = '#85d2f6',
  error = '#F04438',
}

// enum BOX_SHADOW {
//   default = '0px 1px 2px rgba(16, 24, 40, 0.05)',
//   focused = '0 0 0 0.25rem rgb(11 165 236 / 25%)',
//   error = '0 0 0 0.25rem rgb(240 68 56 / 25%)',
// }

enum OPTION_BG_COLORS {
  selected = '#B9E6FE',
  hover = '#E0F2FE',
}

interface CustomControlProps extends ControlProps {
  isSearchable?: boolean;
  isInvalid?: boolean;
}

// const DEFAULT_ALL_OPTION = {
//   label: 'Select All',
//   value: '*',
// };

const Control: FunctionComponent<CustomControlProps> = ({ children, ...props }) => {
  const style = { cursor: 'pointer', paddingRight: '8px' };
  return (
    <components.Control {...props}>
      {!props.isDisabled && props.isSearchable && (
        <img
          src={props.isInvalid ? searchErrorIcon : searchIcon}
          alt='search indicator'
          style={style}
        />
      )}
      {children}
    </components.Control>
  );
};

const Option = ({ children, ...props }: OptionProps) => {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };
  return (
    <components.Option {...newProps} className='d2md-custom-option'>
      {children}
      {props.isSelected && (
        <img src={checkIcon} alt='search indicator' style={{ float: 'right' }} />
      )}
    </components.Option>
  );
};

interface Props extends ComponentProps<typeof ReactSelect> {
  isInvalid?: boolean;
  hideDropdownIndicator?: boolean;
  showOptionsOutside?: boolean;
  showOptionsSummary?: boolean;
  allSelectedText?: string;
  enableAllSelectedValue?: boolean;
  testId?: string;
  /**
   * Has effect only if showOptionsOutside props is set to true.
   * works only when showOptionsOutside props is set to true
   */
  onRemoveOutsideOptionDialog?: {
    title: string;
    helpText?: string;
    confirmText: string;
    cancelText: string;
  };
  /**
   * Has effect only if showOptionsOutside props is set to true.
   * Modifier callback, return true if value should be highlighted
   */
  highlightOutsideOptions?: (value: any) => boolean;
  /**
   * Has effect only if showOptionsOutside props is set to true.
   * Callback triggered when clicking on a blue pill
   * @param value - reference to the value of selected blue pill
   * @returns void
   */
  onSelectOutsideOption?: (value: any) => void;
  /**
   * Has effect only if showOptionsOutside props is set to true.
   * Callback to alter the labels of blue pills
   * @param currentLabel - string
   * @param value - any
   * @returns - string
   */
  mapOutsideOptionLabel?: (currentLabel: string, value: any) => string;
  /**
   * Render a react component immidiately below the select container.
   * Used to avoid rendering items below blue pills if they are active.
   */
  renderBelowSelectContainer?: React.ReactNode;
  disableAutoFill?: boolean;
  /*
   * Callback function for when clicking to delete the option and
   * modal is opened up.
   */
  onOpenDeleteDialog?: (value: any, options: any) => void;
}

const BluePill = ({
  value,
  label,
  isDisabled,
  isSelected,
  onPillClick,
  onCloseClick,
  testId,
}: {
  value: any;
  label: string;
  isDisabled?: boolean;
  isSelected?: boolean;
  onPillClick?: () => void;
  onCloseClick?: () => void;
  testId?: string;
}) => {
  return (
    <div
      className={clsx([
        'custom-select-outside-item p-2',
        isSelected && 'custom-select-outside-item--selected',
        isDisabled && 'custom-select-outside-item--disabled',
      ])}
      role={'button'}
      tabIndex={-2}
      onClick={() => !isDisabled && onPillClick?.()}
      onKeyDown={(e) => e.key === 'Enter' && !isDisabled && onPillClick?.()}
      data-testid={testId}
    >
      {label}
      {!isDisabled && (
        <button
          name={value}
          type={'button'}
          onClick={(e) => {
            e.stopPropagation();
            onCloseClick?.();
          }}
        >
          ✕
        </button>
      )}
    </div>
  );
};

export const generateCustomSelectStyles = (props: Props): StylesConfig => ({
  container: (base) => ({
    ...base,
    width: '100%',
  }),
  multiValue: (base: any, state: any) => {
    return {
      ...base,
      background: state.data.isFixed ? 'gray' : 'rgba(240, 249, 255, 0.7)',
      borderRadius: '16px',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      padding: '2px 10px',
    };
  },
  multiValueLabel: (base: any, state: any) => {
    return state.data.isFixed
      ? {
          ...base,
          fontWeight: 'bold',
          color: 'white',
          paddingRight: 6,
          padding: '1px',
        }
      : {
          ...base,
          color: '#026AA2',
          padding: '1px',
        };
  },
  multiValueRemove: (base: any, state: any) => {
    return {
      ...base,
      display: state.data.isFixed ? 'none' : base.display,
      color: '#0BA5EC',
      padding: 0,
      fontSize: '6px',
      ':hover': {
        background: state.data.isFixed ? 'gray' : 'rgba(240, 249, 255, 0.7)',
      },
    };
  },
  control: (base, { isDisabled, isFocused, menuIsOpen }) => {
    const borderColor =
      !isDisabled && props.isInvalid
        ? BORDER_COLORS.error
        : isFocused && menuIsOpen
          ? BORDER_COLORS.focused
          : BORDER_COLORS.default;
    return {
      ...base,
      minHeight: '48px',
      paddingLeft: '16px',
      boxShadow: 'none',
      // boxShadow: isFocused
      //   ? props.isInvalid
      //     ? BOX_SHADOW.error
      //     : BOX_SHADOW.focused
      //   : BOX_SHADOW.default,
      borderRadius: '8px',
      borderWidth: '1px',
      borderStyle: 'solid',
      backgroundColor: isDisabled ? '#EAECF0' : base.backgroundColor,
      borderColor,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ':hover': { borderColor },
    };
  },
  valueContainer: (base, { isDisabled }) => ({
    ...base,
    color: isDisabled ? '#101828' : base.color,
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '0px',
    height: '100%',
    fontSize: '16px',
  }),
  singleValue: (base, { isDisabled }) => ({
    ...base,
    color: isDisabled ? '#101828' : base.color,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: !props.isDisabled && props.isInvalid ? BORDER_COLORS.error : BORDER_COLORS.default,
    display: props.hideDropdownIndicator ? 'none' : base.display,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  placeholder: (base) => ({
    ...base,
    color: '#98A2B2',
  }),
  option: (base, optionProps) => ({
    ...base,
    background: optionProps.isSelected ? OPTION_BG_COLORS.selected : '#FFFFFF',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ':hover': {
      background: !optionProps.isDisabled
        ? optionProps.isSelected
          ? OPTION_BG_COLORS.selected
          : OPTION_BG_COLORS.hover
        : base.background,
    },
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  input: (base) => ({
    ...base,
    margin: '0',
  }),
});

const Input: React.FC<InputProps> = (props) => (
  <components.Input {...props} autoComplete='new-password' />
);

const Select = (
  {
    className,
    isSearchable,
    showOptionsOutside,
    showOptionsSummary,
    onRemoveOutsideOptionDialog,
    disableAutoFill,
    allSelectedText = 'All Selected',
    enableAllSelectedValue = false,
    testId = 'select',
    onOpenDeleteDialog,
    ...props
  }: Props,
  ref: any,
) => {
  const { isMulti, onChange, options } = props;

  const value = useMemo(
    () =>
      Array.isArray(props.value) ? props.value.filter((v) => !isNullOrUndefined(v)) : props.value,
    [props.value],
  );

  const selectedValues = useMemo(() => {
    let v = Array.isArray(value) ? value : [value];
    if (isMulti && v.length > 4 && showOptionsSummary) {
      v = v.slice(0, 4);
    }
    return v;
  }, [isMulti, showOptionsSummary, value]);

  const [showAllOptions, setShowAllOptions] = useState<boolean>(false);
  const [valueToRemoveFromSelectedOptions, setValueToRemoveFromSelectedOptions] = useState<any>();

  const handleRemoveValue = useCallback(
    (valueToRemove: any) => {
      if (!onChange) return;
      const removedValue = (value as any).find(
        (val: CommonDropdownType) => val.value === valueToRemove,
      );
      if (!removedValue) return;
      onChange(
        (value as any).filter((val: any) => val?.value !== valueToRemove),
        { action: 'remove-value', removedValue },
      );
    },
    [onChange, value],
  );

  const customControlCallback = useCallback(
    (defaultControlProps: ControlProps<unknown, boolean, GroupBase<unknown>>) => (
      <Control {...defaultControlProps} isSearchable={isSearchable} isInvalid={props.isInvalid} />
    ),
    [isSearchable, props.isInvalid],
  );

  const styles = useMemo(() => generateCustomSelectStyles(props), [props]);

  return (
    <>
      <ReactSelect
        ref={ref}
        classNamePrefix={'d2md-select'}
        className={clsx('d2md-select', className)}
        isSearchable={isSearchable}
        menuPortalTarget={document.body}
        onKeyDown={(event: any) => {
          if (event.target.value.length === 0 && event.keyCode === 32) event.preventDefault();
        }}
        closeMenuOnScroll={(event: any) => {
          return event?.target?.id === 'scrollContainer';
        }}
        styles={styles}
        components={{
          Control: customControlCallback,
          Option: (defaultOptionProps) => <Option {...defaultOptionProps} />,
          ...(disableAutoFill && { Input }),
        }}
        {...props}
      />
      {props.renderBelowSelectContainer}
      {value && (value as any)?.length > 0 && isMulti && showOptionsOutside && (
        <>
          <div className='d-flex flex-row gap-3 flex-wrap mt-3'>
            {value && showOptionsSummary && (value as any[])?.length > 4 ? (
              <>
                {(value as any)?.length === options?.length &&
                enableAllSelectedValue &&
                !showAllOptions ? (
                  <BluePill
                    value='ALL'
                    label={allSelectedText}
                    isSelected={false}
                    onCloseClick={() => {
                      if (!onChange) return;
                      onChange([], {
                        action: 'remove-value',
                        removedValue: 'ALL',
                      });
                    }}
                    testId={`${testId}-blue-pill-all`}
                  />
                ) : (
                  (!showAllOptions ? selectedValues : (value as any[])).map((val: any, i) => {
                    const key = typeof val.value === 'object' ? val.label : val.value;
                    return (
                      <BluePill
                        key={`${key}-${i}`}
                        value={val.value}
                        label={props.mapOutsideOptionLabel?.(val.label, val) || val.label}
                        isDisabled={props.isDisabled}
                        isSelected={props.highlightOutsideOptions?.(val)}
                        onPillClick={() => props.onSelectOutsideOption?.(val)}
                        onCloseClick={() => {
                          onRemoveOutsideOptionDialog
                            ? setValueToRemoveFromSelectedOptions(val.value)
                            : handleRemoveValue(val.value);
                          onRemoveOutsideOptionDialog &&
                            onOpenDeleteDialog?.({ value: val.value, label: val.label }, value);
                        }}
                        testId={`${testId}-blue-pill-${val.value}`}
                      />
                    );
                  })
                )}
                <div
                  className='custom-select-outside-item p-2'
                  style={{ backgroundColor: '#E0F2FE', fontSize: '14px' }}
                >
                  <button
                    name={(value as any).value}
                    type={'button'}
                    onClick={() => setShowAllOptions((showAllOptions) => !showAllOptions)}
                    data-testid={`${testId}-show-more`}
                  >
                    {!showAllOptions
                      ? (value as any)?.length === options?.length && enableAllSelectedValue
                        ? 'Show All'
                        : `${(value as any)?.length - selectedValues.length} more`
                      : 'Close'}
                    <img
                      src={showAllOptions ? chevronUpIcon : chevronDownIcon}
                      alt=''
                      className='ms-2'
                    />
                  </button>
                </div>
              </>
            ) : (
              (value as any[])?.map?.((val: any, i) => {
                const key = typeof val.value === 'object' ? val.label : val.value;
                return (
                  <BluePill
                    key={`${key}-${i}`}
                    value={val.value}
                    label={props.mapOutsideOptionLabel?.(val.label, val) || val.label}
                    isDisabled={props.isDisabled}
                    isSelected={props.highlightOutsideOptions?.(val)}
                    onPillClick={() => props.onSelectOutsideOption?.(val)}
                    onCloseClick={() => {
                      onRemoveOutsideOptionDialog
                        ? setValueToRemoveFromSelectedOptions(val.value)
                        : handleRemoveValue(val.value);
                      onRemoveOutsideOptionDialog &&
                        onOpenDeleteDialog?.({ value: val.value, label: val.label }, value);
                    }}
                    testId={`${testId}-blue-pill-${val.value}`}
                  />
                );
              })
            )}
          </div>
          {onRemoveOutsideOptionDialog && (
            <CancelActionModal
              {...onRemoveOutsideOptionDialog}
              show={!!valueToRemoveFromSelectedOptions}
              onConfirm={() => {
                handleRemoveValue(valueToRemoveFromSelectedOptions);
                setValueToRemoveFromSelectedOptions(undefined);
              }}
              onCancel={() => setValueToRemoveFromSelectedOptions(undefined)}
            />
          )}
        </>
      )}
    </>
  );
};

export default forwardRef(Select);
