import clsx from 'clsx';
import { ComponentProps, forwardRef } from 'react';

const Textarea = (
  { className, ...props }: ComponentProps<'textarea'>,
  ref: React.Ref<HTMLTextAreaElement>,
) => {
  return <textarea ref={ref} className={clsx('form-control', className)} {...props} />;
};

export default forwardRef(Textarea);
