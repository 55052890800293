import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector as rawUseSelector } from 'react-redux';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from '../features/auth/api/authSlice';
import accountReducer from '../features/account/api/accountSlice';
import findMeReducer from '../features/auth/api/findMeSlice';

import socketService, { socketMiddleware } from './socket';
import registerReducer from '../features/register/api/registerSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['auth', 'registration', 'account'],
};

const rootReducers = combineReducers({
  auth: authReducer,
  account: accountReducer,
  socket: socketService,
  findMe: findMeReducer,
  register: registerReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          'account/uploadAvatar/fulfilled',
          'account/deleteAvatar/fulfilled',
          'clinic/organizations/uploadOrganizationLogo/fulfilled',
          'clinic/organizations/deleteOrganizationLogo/fulfilled',
          'messages/compose/uploadMessageAttachments/fulfilled',
          'socket/connect/fulfilled',
        ],
      },
    }).concat([socketMiddleware]), // Adding the api middleware enables caching, invalidation, polling, and other useful features of `rtk-query`
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// setupListeners(store.dispatch);

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = rawUseSelector;
