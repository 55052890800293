// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 791px) {
  .cancel-modal__dialog {
    width: 400px;
  }
}
.cancel-modal__content {
  padding: 24px;
  border-radius: 12px;
}

.cancel-modal__header {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  border: none;
}

.cancel-modal__body {
  overflow-y: auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  padding: 0;
}

.cancel-modal__title {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
}

.cancel-modal__reason_title {
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: #101828;
}

.cancel-modal__details_title {
  margin-top: 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: #101828;
}

.cancel-modal__footer {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  border: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.cancel-modal__btn {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  height: 44px;
}`, "",{"version":3,"sources":["webpack://./src/features/app/components/CancelActionModal.scss"],"names":[],"mappings":"AAAA;EACE;IACE,YAAA;EACF;AACF;AAEA;EACE,aAAA;EACA,mBAAA;AAAF;;AAGA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,YAAA;AAAF;;AAGA;EACE,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,UAAA;AAAF;;AAGA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAF;;AAGA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAF;;AAGA;EACE,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAF;;AAGA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;EACA,cAAA;AAAF;;AAGA;EACE,8CAAA;EACA,kBAAA;EACA,YAAA;AAAF","sourcesContent":["@media screen and (min-width: 791px) {\n  .cancel-modal__dialog {\n    width: 400px;\n  }\n}\n\n.cancel-modal__content {\n  padding: 24px;\n  border-radius: 12px;\n}\n\n.cancel-modal__header {\n  padding-left: 0;\n  padding-right: 0;\n  padding-top: 0;\n  border: none;\n}\n\n.cancel-modal__body {\n  overflow-y: auto;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n  color: #667085;\n  padding: 0;\n}\n\n.cancel-modal__title {\n  font-weight: 500;\n  font-size: 18px;\n  line-height: 28px;\n  color: #101828;\n}\n\n.cancel-modal__reason_title {\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 28px;\n  color: #101828;\n}\n\n.cancel-modal__details_title {\n  margin-top: 15px;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 28px;\n  color: #101828;\n}\n\n.cancel-modal__footer {\n  padding-left: 0;\n  padding-right: 0;\n  padding-bottom: 0;\n  border: none;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  grid-gap: 10px;\n}\n\n.cancel-modal__btn {\n  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);\n  border-radius: 8px;\n  height: 44px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
