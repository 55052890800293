import { Libraries } from '@react-google-maps/api';
import { env } from '../env';

export type GoogleLibraries = (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
)[];

export const GOOGLE_API_LIBRARIES = ['places'] as Libraries;

export const GOOGLE_JS_API_LOADER_CONFIG: {
  id: string;
  googleMapsApiKey: string;
  libraries: Libraries;
} = {
  id: 'google-map-script',
  googleMapsApiKey: env.REACT_APP_GOOGLE_PLACES_API_KEY || '',
  libraries: GOOGLE_API_LIBRARIES,
};

export const extractDataFromGooglePlaceResult = (place?: google.maps.places.PlaceResult) => {
  const obj: {
    zip?: google.maps.GeocoderAddressComponent;
    city?: google.maps.GeocoderAddressComponent;
    state?: google.maps.GeocoderAddressComponent;
    streetNumber?: google.maps.GeocoderAddressComponent;
    route?: google.maps.GeocoderAddressComponent;
    country?: google.maps.GeocoderAddressComponent;
    formattedAddress?: string;
  } = {};
  place?.address_components?.forEach((comp, i) => {
    if (comp.types.includes('locality')) {
      obj.city = place.address_components?.[i];
    }
    if (comp.types.includes('administrative_area_level_1')) {
      obj.state = place.address_components?.[i];
    }
    if (comp.types.includes('postal_code')) {
      obj.zip = place.address_components?.[i];
    }
    if (comp.types.includes('postal_code')) {
      obj.zip = place.address_components?.[i];
    }
    if (comp.types.includes('street_number')) {
      obj.streetNumber = place.address_components?.[i];
    }
    if (comp.types.includes('route')) {
      obj.route = place.address_components?.[i];
    }
    if (comp.types.includes('country')) {
      obj.country = place.address_components?.[i];
    }
  });
  if (place?.formatted_address) {
    obj.formattedAddress = place.formatted_address;
  }

  return obj;
};
