import * as yup from 'yup';

import { FIELD_DEFINITIONS, FIELD_TEXT_MESSAGES } from '../../../config';

export const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .email(FIELD_TEXT_MESSAGES.EMAIL_INVALID)
    .required(FIELD_TEXT_MESSAGES.EMAIL_REQUIRED),
  password: yup.string().required(FIELD_TEXT_MESSAGES.PASSWORD_REQUIRED),
});

export const FindMeSchema = yup.object().shape({
  lastName: yup.string().required(FIELD_TEXT_MESSAGES.LAST_NAME_REQUIRED),
  dob: yup.date().required(FIELD_TEXT_MESSAGES.DOB_REQUIRED),
  phoneNumber: yup
    .string()
    .required(FIELD_TEXT_MESSAGES.PHONE_REQUIRED)
    .matches(/^\d{10}$/, FIELD_TEXT_MESSAGES.PHONE_MAX_LENGTH),
});

export const FindMeCreateAccountSchema = yup.object().shape({
  email: yup
    .string()
    .min(FIELD_DEFINITIONS.EMAIL_MIN_LENGTH, FIELD_TEXT_MESSAGES.EMAIL_MIN_LENGTH)
    .max(FIELD_DEFINITIONS.EMAIL_MAX_LENGTH, FIELD_TEXT_MESSAGES.EMAIL_MAX_LENGTH)
    .email(FIELD_TEXT_MESSAGES.EMAIL_INVALID)
    .required(FIELD_TEXT_MESSAGES.EMAIL_REQUIRED),
  password: yup
    .string()
    .min(FIELD_DEFINITIONS.PASSWORD_MIN_LENGTH, FIELD_TEXT_MESSAGES.PASSWORD_MIN_LENGTH)
    .matches(
      FIELD_DEFINITIONS.PASSWORD_ONE_LOWERCASE_CHARACTER_PATTERN,
      FIELD_TEXT_MESSAGES.PASSWORD_LOWERCASE_CHARACTER_REQUIRED,
    )
    .matches(
      FIELD_DEFINITIONS.PASSWORD_ONE_UPPERCASE_CHARACTER_PATTERN,
      FIELD_TEXT_MESSAGES.PASSWORD_UPPERCASE_CHARACTER_REQUIRED,
    )
    .matches(
      FIELD_DEFINITIONS.PASSWORD_ONE_SPECIAL_CHARACTER_PATTERN,
      FIELD_TEXT_MESSAGES.PASSWORD_SPECIAL_CHARACTER_REQUIRED,
    )
    .matches(
      FIELD_DEFINITIONS.PASSWORD_ONE_NUMBER_PATTERN,
      FIELD_TEXT_MESSAGES.PASSWORD_NUMBER_REQUIRED,
    )
    .required(FIELD_TEXT_MESSAGES.PASSWORD_REQUIRED)
    .test('passwords-match', FIELD_TEXT_MESSAGES.PASSWORD_CONFIRM_INVALID, function (value) {
      return value && this.parent.confirmPassword && value === this.parent.confirmPassword;
    }),
  confirmPassword: yup
    .string()
    .required(FIELD_TEXT_MESSAGES.PASSWORD_CONFIRM_INVALID)
    .test('passwords-match', FIELD_TEXT_MESSAGES.PASSWORD_CONFIRM_INVALID, function (value) {
      return value && this.parent.password && value === this.parent.password;
    }),
  agreeToTerms: yup
    .boolean()
    .oneOf([true], FIELD_TEXT_MESSAGES.TERMS_AND_CONDITIONS_REQUIRED)
    .required(FIELD_TEXT_MESSAGES.TERMS_AND_CONDITIONS_REQUIRED),
});
