/* eslint-disable react/prop-types */
import { PropsWithChildren, useEffect, useRef } from 'react';

import InactivityModal from '../components/modal/InactivityModal';
import useOnlineStatus from '../hooks/useOnlineStatus';
import { CallbackFunction } from '../types';
import alert from '../utils/alert';

type Props = PropsWithChildren<{
  onNetworkLostRegainThresholdSeconds?: number;
  onNetworkLost?: CallbackFunction;
  onNetworkRegain?: CallbackFunction;
}>;

const SessionManagementProvider: React.FC<Props> = ({
  children,
  onNetworkLostRegainThresholdSeconds,
  onNetworkLost,
  onNetworkRegain,
}) => {
  // Network related part
  const { isOnline } = useOnlineStatus();
  const timeoutRef = useRef<NodeJS.Timeout>();
  const NLTS = onNetworkLostRegainThresholdSeconds || 0;
  useEffect(
    function () {
      if (!isOnline) {
        alert.error(
          `Application has lost network access. App will logout after ${NLTS} seconds if network is not regained. `,
          { toastId: 'network-error' },
        );
        clearTimeout(timeoutRef.current);
        timeoutRef.current = undefined;
        timeoutRef.current = setTimeout(() => onNetworkLost?.(), NLTS * 1000);
        return;
      }

      if (isOnline && timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = undefined;
        onNetworkRegain?.();
        alert.success('Application has regained network access.', { toastId: 'network-regained' });
      }
    },
    [isOnline, onNetworkLost, NLTS, onNetworkRegain],
  );

  return (
    <>
      <InactivityModal />
      {children}
    </>
  );
};

export default SessionManagementProvider;
