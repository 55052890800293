import * as yup from 'yup';
import { FIELD_DEFINITIONS, FIELD_TEXT_MESSAGES, MINOR_AGE } from '../../../config';

const subtractYears = (numOfYears: number, date = new Date()) => {
  const dateCopy = new Date(date);

  dateCopy.setFullYear(dateCopy.getFullYear() - numOfYears);

  dateCopy.setDate(dateCopy.getDate() + 1);

  return dateCopy;
};

export const RegisterSchema = yup.object().shape({
  email: yup
    .string()
    .required(FIELD_TEXT_MESSAGES.EMAIL_REQUIRED)
    .min(FIELD_DEFINITIONS.EMAIL_MIN_LENGTH, FIELD_TEXT_MESSAGES.EMAIL_MIN_LENGTH)
    .max(FIELD_DEFINITIONS.EMAIL_MAX_LENGTH, FIELD_TEXT_MESSAGES.EMAIL_MAX_LENGTH)
    .email(FIELD_TEXT_MESSAGES.EMAIL_INVALID),
  phone: yup
    .string()
    .required(FIELD_TEXT_MESSAGES.PHONE_REQUIRED)
    .test(
      'verifyIfStringIsAPhoneNumber',
      FIELD_TEXT_MESSAGES.FIELD_NUMBER_ONLY,
      (fieldValue) => !isNaN(+(fieldValue ? fieldValue.replace(/[^\d]/g, '') : '')),
    )
    .test(
      'minLength',
      FIELD_TEXT_MESSAGES.PHONE_MIN_LENGTH,
      (val) => !((val?.length || 0) < FIELD_DEFINITIONS.PHONE_MIN_LENGTH),
    )
    .test(
      'maxLength',
      FIELD_TEXT_MESSAGES.PHONE_MAX_LENGTH,
      (val) => !((val?.length || 0) > FIELD_DEFINITIONS.PHONE_MAX_LENGTH),
    )
    .test('containsNonNumbers', FIELD_TEXT_MESSAGES.FIELD_NUMBER_ONLY, (fieldValue) => {
      return !fieldValue || !fieldValue.match(/[^\d]/g);
    }),
  firstName: yup
    .string()
    .required(FIELD_TEXT_MESSAGES.FIRST_NAME_REQUIRED)
    .min(FIELD_DEFINITIONS.FIRST_NAME_MIN_LENGTH, FIELD_TEXT_MESSAGES.FIRST_NAME_MIN_LENGTH)
    .max(FIELD_DEFINITIONS.FIRST_NAME_MAX_LENGTH, FIELD_TEXT_MESSAGES.FIRST_NAME_MAX_LENGTH)
    .matches(
      FIELD_DEFINITIONS.MATCH_NOT_BLANK_STRING,
      FIELD_TEXT_MESSAGES.BLANK_STRING_NOT_ALLOWED,
    ),
  lastName: yup
    .string()
    .required(FIELD_TEXT_MESSAGES.LAST_NAME_REQUIRED)
    .min(FIELD_DEFINITIONS.LAST_NAME_MIN_LENGTH, FIELD_TEXT_MESSAGES.LAST_NAME_MIN_LENGTH)
    .max(FIELD_DEFINITIONS.LAST_NAME_MAX_LENGTH, FIELD_TEXT_MESSAGES.LAST_NAME_MAX_LENGTH)
    .matches(
      FIELD_DEFINITIONS.MATCH_NOT_BLANK_STRING,
      FIELD_TEXT_MESSAGES.BLANK_STRING_NOT_ALLOWED,
    ),
  middleName: yup
    .string()
    .max(FIELD_DEFINITIONS.MIDDLE_NAME_MAX_LENGTH, FIELD_TEXT_MESSAGES.MIDDLE_NAME_MAX_LENGTH)
    .matches(
      FIELD_DEFINITIONS.LETTERS_NUMBERS_SPACES_AND_SPECIAL_CHARACTERS,
      FIELD_TEXT_MESSAGES.LETTERS_NUMBERS_SPACES_AND_SPECIAL_CHARACTERS_ALLOWED,
    ),
  birthdate: yup
    .date()
    .required(FIELD_TEXT_MESSAGES.DOB_REQUIRED)
    .test('', FIELD_TEXT_MESSAGES.BIRTHDATE_MAX_DATE, (value) => {
      return new Date(value).getTime() <= new Date().getTime();
    })
    .max(subtractYears(MINOR_AGE), FIELD_TEXT_MESSAGES.BIRTHDATE_MIN_DATE)
    .min(new Date('1900'), FIELD_TEXT_MESSAGES.BIRTHDATE_FLOOR_DATE),
  sex: yup
    .object()
    .required(FIELD_TEXT_MESSAGES.SEX_REQUIRED)
    .shape({
      label: yup.string().required(),
      value: yup.string().required(FIELD_TEXT_MESSAGES.SEX_REQUIRED),
    }),
  street: yup
    .string()
    .required(FIELD_TEXT_MESSAGES.STREET_REQUIRED)
    .min(FIELD_DEFINITIONS.STREET_MIN_LENGTH, FIELD_TEXT_MESSAGES.STREET_MIN_LENGTH)
    .max(FIELD_DEFINITIONS.STREET_MAX_LENGTH, FIELD_TEXT_MESSAGES.STREET_MAX_LENGTH),
  unit: yup
    .string()
    .optional()
    .max(FIELD_DEFINITIONS.UNIT_MAX_LENGTH, FIELD_TEXT_MESSAGES.UNIT_MAX_LENGTH)
    .matches(
      FIELD_DEFINITIONS.LETTERS_AND_NUMBERS_ONLY,
      FIELD_TEXT_MESSAGES.LETTERS_AND_NUMBERS_ONLY_ALLOWED,
    )
    .transform((value) => (value === '' ? undefined : value)),
  city: yup
    .string()
    .required(FIELD_TEXT_MESSAGES.CITY_REQUIRED)
    .min(FIELD_DEFINITIONS.CITY_MIN_LENGTH, FIELD_TEXT_MESSAGES.CITY_MIN_LENGTH)
    .max(FIELD_DEFINITIONS.CITY_MAX_LENGTH, FIELD_TEXT_MESSAGES.CITY_MAX_LENGTH)
    .matches(FIELD_DEFINITIONS.LETTERS_ONLY, FIELD_TEXT_MESSAGES.LETTERS_ONLY_ALLOWED),
  state: yup
    .object()
    .required(FIELD_TEXT_MESSAGES.PATIENT_STATE_REQUIRED)
    .shape({
      label: yup.string().required(),
      value: yup.string().required(FIELD_TEXT_MESSAGES.PATIENT_STATE_REQUIRED),
    }),
  zip: yup
    .string()
    .required(FIELD_TEXT_MESSAGES.ZIP_REQUIRED)
    .min(FIELD_DEFINITIONS.ZIP_MIN_LENGTH, FIELD_TEXT_MESSAGES.ZIP_MIN_LENGTH)
    .max(FIELD_DEFINITIONS.ZIP_MAX_LENGTH, FIELD_TEXT_MESSAGES.ZIP_MAX_LENGTH)
    .matches(
      FIELD_DEFINITIONS.LETTERS_AND_NUMBERS_ONLY,
      FIELD_TEXT_MESSAGES.LETTERS_AND_NUMBERS_ONLY_ALLOWED,
    ),
  country: yup
    .object()
    .required(FIELD_TEXT_MESSAGES.COUNTRY_REQUIRED)
    .shape({
      label: yup.string().required(),
      value: yup.string().required(FIELD_TEXT_MESSAGES.COUNTRY_REQUIRED),
    }),
  termsAgreed: yup
    .boolean()
    .required(FIELD_TEXT_MESSAGES.TERMS_REQUIRED)
    .oneOf([true], FIELD_TEXT_MESSAGES.TERMS_REQUIRED),
});
