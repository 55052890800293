import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

// COMPONENTS
import LoaderButton from './LoaderButton';

type Props = {
  onClick: () => void;
  label: string;
  seconds: number;
  isLoading?: boolean;
  testID?: string;
};

export default function ResendCodeButton({ onClick, label, seconds, isLoading }: Props) {
  const { t }: any = useTranslation();
  const [countdown, setCountdown] = useState(seconds); // Initial countdown value in seconds

  const handleCountdown = useCallback(() => {
    setCountdown((prevCount) => prevCount - 1);
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (countdown > 0) {
      timer = setInterval(handleCountdown, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [countdown, handleCountdown]);

  return (
    <LoaderButton
      className='btn-secondary auth__btn-secondary'
      onClick={() => {
        onClick();
        setCountdown(seconds);
      }}
      disabled={countdown !== 0 || isLoading}
      isSubmitting={isLoading}
      style={{ width: '100%' }}
    >
      {!isLoading && (
        <p className='m-0'>{`${label} ${countdown == 0 ? '' : `(${countdown} ${t('seconds')})`}`}</p>
      )}
    </LoaderButton>
  );
}
