import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { FieldError } from 'react-hook-form';

import errorIcon from '../../../assets/images/icons/error.svg';
import successIconMuted from '../../../assets/images/icons/success-icon-muted.svg';
import successIcon from '../../../assets/images/icons/success.svg';
import { FIELD_TEXT_MESSAGES } from '../../../config';
import { useTranslation } from 'react-i18next';

const STATIC_PASSWORD_ERROR_MESSAGES = [
  FIELD_TEXT_MESSAGES.PASSWORD_MIN_LENGTH,
  // FIELD_TEXT_MESSAGES.PASSWORD_MAX_LENGTH,
  FIELD_TEXT_MESSAGES.PASSWORD_NUMBER_REQUIRED,
  FIELD_TEXT_MESSAGES.PASSWORD_SPECIAL_CHARACTER_REQUIRED,
  FIELD_TEXT_MESSAGES.PASSWORD_UPPERCASE_CHARACTER_REQUIRED,
  FIELD_TEXT_MESSAGES.PASSWORD_LOWERCASE_CHARACTER_REQUIRED,
  FIELD_TEXT_MESSAGES.PASSWORD_CONFIRM_INVALID,
];

type PasswordErrorProps = {
  errors: FieldError[] | Record<string, any> | undefined;
  showPasswordGuide?: boolean;
};

export const PasswordValidationErrors: FunctionComponent<PasswordErrorProps> = ({
  errors,
  showPasswordGuide,
}) => {
  const { t } = useTranslation();
  if (showPasswordGuide) {
    const displayErrorMessages = STATIC_PASSWORD_ERROR_MESSAGES.map((msg) => {
      return {
        type: 'default',
        message: msg,
      };
    });
    return (
      <ul className='list-unstyled list-pwd-errors'>
        {displayErrorMessages.map(({ type, message }, index) => {
          return (
            <li key={`pwd-${type}-${index}-item`} className={'pb-1 text-default'}>
              <img src={successIconMuted} width={20} height={20} alt='success-img' />{' '}
              <small>{t(message)}</small>
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <ErrorMessage
      errors={errors}
      name='password'
      render={({ messages }) => {
        const errorMessages: string[] = [];
        if (messages) {
          Object.entries(messages).forEach(([type, message]) => {
            if (type === 'required' || !message) return null;
            if (Array.isArray(message)) {
              message.forEach((msg) => errorMessages.push(msg));
            } else {
              errorMessages.push(message as string);
            }
          });
        }
        const displayErrorMessages = STATIC_PASSWORD_ERROR_MESSAGES.map((msg) => {
          if (errorMessages.includes(msg)) {
            return {
              type: 'error',
              message: msg,
            };
          }
          return {
            type: 'success',
            message: msg,
          };
        });

        return (
          <ul className='list-unstyled list-pwd-errors'>
            {displayErrorMessages.map(({ type, message }, index) => {
              return (
                <li
                  key={`pwd-error-${index}`}
                  className={clsx([type === 'error' ? 'text-danger' : 'text-success'], 'pb-1')}
                >
                  <img
                    src={type === 'error' ? errorIcon : successIcon}
                    width={20}
                    height={20}
                    alt='error-img'
                  />{' '}
                  <small>{t(message)}</small>
                </li>
              );
            })}
          </ul>
        );
      }}
    />
  );
};
