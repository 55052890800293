import {
  signIn,
  signOut,
  getCurrentUser,
  confirmSignIn,
  fetchAuthSession,
  fetchUserAttributes,
  resetPassword,
  confirmResetPassword,
} from '@aws-amplify/auth';
import {
  createAsyncThunk,
  createSlice,
  Action,
  PayloadAction,
  UnknownAction,
} from '@reduxjs/toolkit';
import { Amplify } from 'aws-amplify';

import { Hub } from 'aws-amplify/utils';

import AuthService from '../../../api/services/AuthService';
import { AMPLIFY_AUTH_CONFIG } from '../../../config';
import {
  removeAccessTokenCookie,
  removeLastActivityCookie,
  setAccessTokenCookie,
  upsertLastActivityCookie,
} from '../../../utils/sessionCookies';

import { RootState } from '../../../store';
import { getMeThunk } from '../../account/api/accountSlice';

Amplify.configure({
  Auth: {
    Cognito: {
      identityPoolId: AMPLIFY_AUTH_CONFIG.IDENTITY_POOL_ID,
      userPoolId: AMPLIFY_AUTH_CONFIG.USER_POOL_ID,
      userPoolClientId: AMPLIFY_AUTH_CONFIG.USER_POOL_WEB_CLIENT_ID,
      loginWith: {
        oauth: {
          domain: AMPLIFY_AUTH_CONFIG.OAUTH.DOMAIN,
          scopes: AMPLIFY_AUTH_CONFIG.OAUTH.SCOPE,
          redirectSignIn: [AMPLIFY_AUTH_CONFIG.OAUTH.REDIRECT_SIGN_IN],
          redirectSignOut: [AMPLIFY_AUTH_CONFIG.OAUTH.REDIRECT_SIGN_OUT],
          responseType: 'code',
        },
      },
    },
  },
});

interface AuthState {
  username: string;
  email?: string;
  emailVerified?: boolean;
  familyName?: string;
  givenName?: string;
  idToken?: string;
  accessToken?: string;
  preferredUsername?: string;
  needsVerification?: boolean;
  status: 'pending' | 'success' | 'failure';
  newPasswordRequired?: boolean;
  isLoading: boolean;
  isInactive?: boolean;
  error?: string;
  errorName?: string;
  errorMessage?: string;
}

const initialState: AuthState = {
  username: '',
  email: '',
  emailVerified: true,
  idToken: '',
  accessToken: '',
  givenName: '',
  familyName: '',
  preferredUsername: '',
  needsVerification: false,
  newPasswordRequired: false,
  status: 'pending',
  isLoading: false,
  isInactive: false,
  error: '',
  errorName: '',
  errorMessage: '',
};

const ACTIONS_PREFIX = 'auth';

export const createSessionApiThunk = createAsyncThunk(
  `${ACTIONS_PREFIX}/createSessionApi`,
  async (params: { accessToken: string }, { rejectWithValue, dispatch }) => {
    const joinWeirdErrorMessage = (error: any) => {
      /*
       * This only happens if the account is suspended
       */
      const integerKeys = Object.keys(error).filter((key) => Number.isInteger(parseInt(key)));
      const isWeirdResponse = integerKeys.length > 10;

      if (isWeirdResponse) {
        return Object.keys(error)
          .filter((key) => Number.isInteger(parseInt(key)))
          .map((key) => error[key])
          .join('');
      }
      return '';
    };

    try {
      const createSessionResult = await AuthService.createSession(params);
      if (
        !createSessionResult.data ||
        Object.keys(createSessionResult.data).length === 0 ||
        createSessionResult.data.statusCode !== 200
      ) {
        throw new Error('Could not create session!');
      }
      setAccessTokenCookie(createSessionResult.data.resource);
      upsertLastActivityCookie();
      await dispatch(getMeThunk()).unwrap();
    } catch (err) {
      const error = err as Error;
      const message = joinWeirdErrorMessage(error);
      return rejectWithValue({
        errorName: error.name,
        errorMessage: error.message,
        error:
          typeof err === 'string' ? err : message || error?.message || 'Could not create session!',
      } as Partial<AuthState>);
    }
  },
);

export const createWSSessionAPIThunk = createAsyncThunk(
  `${ACTIONS_PREFIX}/createWSSessionAPI`,
  async (params: { deviceId: string }, { rejectWithValue }) => {
    try {
      const createWSSessionResult = await AuthService.createWSSession(params);
      if (
        !createWSSessionResult.data ||
        Object.keys(createWSSessionResult.data).length === 0 ||
        createWSSessionResult.data.statusCode !== 200
      ) {
        throw new Error('Could not create WS session!');
      }
      const { wsToken, expiresAt } = createWSSessionResult.data.resource;
      return {
        token: wsToken,
        expiresAt,
      };
    } catch (err) {
      const error = err as Error;
      return rejectWithValue({
        errorName: error.name,
        errorMessage: error.message,
        error: typeof err === 'string' ? err : error?.message || 'Could not create WS session!',
      } as Partial<AuthState>);
    }
  },
);

export const loginUserThunk = createAsyncThunk<
  Partial<AuthState>,
  { username: string; password: string }
>(`${ACTIONS_PREFIX}/loginUser`, async (action, { rejectWithValue }) => {
  try {
    const { nextStep } = await signIn({
      username: action.username,
      password: action.password,
    });
    const user = await getCurrentUser();
    const userAttributes: any = await fetchUserAttributes();
    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
    if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { email, email_verified, family_name, given_name } = userAttributes;
      return {
        username: user.username,
        isLoading: false,
        newPasswordRequired: true,
        email,
        emailVerified: email_verified,
        familyName: family_name,
        givenName: given_name,
        status: 'pending',
      };
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { email, email_verified, preferred_username, family_name, given_name } = userAttributes;

    return {
      username: user.username,
      idToken: idToken?.toString(),
      accessToken: accessToken?.toString(),
      email,
      emailVerified: email_verified,
      preferredUsername: preferred_username,
      familyName: family_name,
      givenName: given_name,
      isLoading: false,
      status: 'success',
    };
  } catch (err: unknown) {
    const error = err as Error;
    if (error.name === 'UserNotFoundException' || error.name === 'NotAuthorizedException') {
      return rejectWithValue({
        errorName: error.name,
        errorMessage: error.message,
        error: 'User not found or invalid credentials.',
      } as Partial<AuthState>);
    }

    return rejectWithValue({
      idToken: '',
      accessToken: '',
      username: '',
      status: 'failure',
      isLoading: false,
      error: 'Something went wrong. Please try again.',
      errorName: error.name,
      errorMessage: error.message,
    } as Partial<AuthState>);
  }
});

export const logoutUserThunk = createAsyncThunk(
  `${ACTIONS_PREFIX}/logoutUser`,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  async (_, { fulfillWithValue }) => {
    try {
      // global: true, signs out all sessions
      await signOut({ global: true });
    } catch (err) {
      const error = err as Error;
      console.log(':: COGNITO LOGOUT ERROR ::', error);
      // * Force signOut event when cognito errors out
      Hub.dispatch('auth', { event: 'signOut' });
    } finally {
      // dispatch(clearAccountState());
      removeAccessTokenCookie();
      removeLastActivityCookie();
      fulfillWithValue(true);
    }
  },
);
export const completeNewPasswordThunk = createAsyncThunk(
  `${ACTIONS_PREFIX}/newPasswordRequiredUser`,
  async ({ newPassword }: { newPassword: string }, { getState }) => {
    try {
      const state = getState() as RootState;
      const { username } = state.auth;

      await confirmSignIn({
        challengeResponse: newPassword,
      });

      const userAttributes = await fetchUserAttributes();
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};

      return {
        username,
        idToken: idToken?.toString(),
        accessToken: accessToken?.toString(),
        ...userAttributes,
        isLoading: false,
        status: 'success',
      } as AuthState;
    } catch (err) {
      const error = err as Error;
      throw error;
    }
  },
);

export const resetPasswordThunk = createAsyncThunk(
  `${ACTIONS_PREFIX}/resetPassword`,
  async (
    { username, code, newPassword }: { username: string; code: string; newPassword: string },
    { rejectWithValue },
  ) => {
    try {
      await confirmResetPassword({
        username,
        confirmationCode: code,
        newPassword,
      });
      return {
        username,
        isLoading: false,
        status: 'success',
      } as AuthState;
    } catch (err) {
      const error = err as Error;
      return rejectWithValue({
        errorName: error.name,
        errorMessage: error.message,
        error: 'Something went wrong. Please try again.',
      } as Partial<AuthState>);
    }
  },
);
export const forgotPasswordThunk = createAsyncThunk<Partial<AuthState>, { username: string }>(
  `${ACTIONS_PREFIX}/forgotPassword`,
  async (action, { rejectWithValue }) => {
    try {
      await resetPassword({ username: action.username });
      return {};
    } catch (err: unknown) {
      const error = err as Error;
      return rejectWithValue({
        errorName: error.name,
        errorMessage: error.message,
        error: 'Something went wrong. Please try again.',
      } as Partial<AuthState>);
    }
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<Partial<AuthState>>) => {
      const { username, accessToken } = action.payload;
      state.username = username || '';
      state.accessToken = accessToken || '';
    },
    setInactivity: (state, action: PayloadAction<{ isInactive: boolean }>) => {
      const { isInactive } = action.payload;
      state.isInactive = isInactive;
    },
    clearAuthState: () => initialState,
  },
  extraReducers: (builder) => {
    // login user thunk fulfilled
    builder.addCase(loginUserThunk.fulfilled, (state, action) => {
      const {
        idToken,
        accessToken,
        preferredUsername,
        newPasswordRequired,
        username,
        email,
        emailVerified,
        familyName,
        givenName,
      } = action.payload;
      state.username = username || '';
      state.email = email || '';
      state.emailVerified = emailVerified || true;
      state.familyName = familyName || '';
      state.givenName = givenName || '';
      state.idToken = idToken || '';
      state.accessToken = accessToken || '';
      state.preferredUsername = preferredUsername || '';
      state.newPasswordRequired = newPasswordRequired || false;
    });
    // logout user thunk fulfilled
    builder.addCase(logoutUserThunk.fulfilled, () => {
      return initialState;
    });
    builder.addMatcher(
      (action: Action) =>
        (action.type as string).startsWith(ACTIONS_PREFIX) &&
        (action.type as string).endsWith('/fulfilled'),
      (state) => {
        Object.assign(state, {
          isLoading: false,
          status: 'success',
        });
      },
    );
    builder.addMatcher(
      (action: Action) =>
        (action.type as string).startsWith(ACTIONS_PREFIX) &&
        (action.type as string).endsWith('/pending'),
      (state) => {
        Object.assign(state, {
          isLoading: true,
          status: 'loading',
        });
      },
    );
    builder.addMatcher(
      (action: UnknownAction) =>
        (action.type as string).startsWith(ACTIONS_PREFIX) &&
        (action.type as string).endsWith('/rejected'),
      (state, action) => {
        Object.assign(state, {
          isLoading: false,
          status: 'failure',
          error: action || '',
        });
      },
    );
  },
});

// SELECTORS
export const selectAuthState = (state: RootState) => state.auth;
// ACTIONS
export const { setCredentials, setInactivity, clearAuthState } = authSlice.actions;
// REDUCER
export default authSlice.reducer;
