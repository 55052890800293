import { Outlet, Link } from 'react-router-dom';

import backgroundImg from '../assets/images/bg.png';
import logoImg from '../assets/logo-light-regular.svg';

import '../features/auth/components/AuthStyles.scss';

const AuthLayout = () => {
  return (
    <div className='d-flex vh-100 align-items-center'>
      <div
        className='d-none d-md-block col-md-6 p-0 bg-dark h-100'
        style={{
          backgroundImage: `url(${backgroundImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className='text-white d-md-flex align-items-center vh-100 p-5 text-center justify-content-center'>
          <div className='pt-5 pb-5'>
            <div className='app-logo'>
              <Link to='/'>
                <img src={logoImg} alt='logo' />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className='col col-md-6 p-0 bg-white h-100 position-relative'>
        <div className='d-flex align-items-center h-100 overflow-auto p-5 justify-content-center'>
          <div className='d-flex flex-column m-auto'>
            <div className='d-md-none mb-3 mt-auto'>
              <Link to='/'>
                <img src={logoImg} alt='logo' />
              </Link>
            </div>
            <div>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
