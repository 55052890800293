import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { FunctionComponent, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { FieldError, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import eyeOffIcon from '../../../assets/images/icons/eye-off.svg';
import eyeIcon from '../../../assets/images/icons/eye.svg';
import lockGlowIcon from '../../../assets/images/icons/lock-glow.svg';
import successGlowIcon from '../../../assets/images/icons/success-glow.svg';
import LoaderButton from '../../../components/common/LoaderButton';
import Input from '../../../components/Input';
import Label from '../../../components/Label';
import { SYSTEM_ROLES } from '../../../config';
import { useAppDispatch, useAppSelector } from '../../../store';
import alert from '../../../utils/alert';
import { completeNewPasswordThunk, resetPasswordThunk } from '../../auth/api/authSlice';
import { ResetPasswordSchema, ResetPasswordWithCodeSchema } from '../schemas/index';
import { PasswordValidationErrors } from '../../app/components/PasswordValidationError';
import { ResetFormData, ResetPasswordScreenProps, SuccessScreenProps } from '../types';
import { useTranslation } from 'react-i18next';

const ResetPasswordForm: FunctionComponent<ResetPasswordScreenProps> = ({
  onSubmit,
  code,
  email,
  newPasswordRequired,
}) => {
  const [inputFieldType, setInputFieldType] = useState('password');
  const togglePasswordVisibility = () => {
    setInputFieldType(inputFieldType === 'text' ? 'password' : 'text');
  };
  const auth = useAppSelector((state) => state.auth);
  const ResetSchema = newPasswordRequired
    ? ResetPasswordSchema
    : ResetPasswordSchema.concat(ResetPasswordWithCodeSchema);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, dirtyFields },
    trigger,
    setValue,
  } = useForm<ResetFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(ResetSchema),
  });

  useEffect(() => {
    code && setValue('code', code, { shouldValidate: true, shouldDirty: true });
    !newPasswordRequired &&
      email &&
      setValue('email', email, { shouldValidate: true, shouldDirty: true });
  }, [code, email, newPasswordRequired, setValue]);

  return (
    <div className='d-flex flex-column align-items-center auth__container--padding h-100 w-100'>
      <div className='d-flex flex-column align-items-center'>
        <img className='auth__page-icon' alt='lock-icon' src={lockGlowIcon} />
        <p className='auth__page-title'>{t('Set new password')}</p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className={'auth__form--width mx-auto'}>
        {!newPasswordRequired && !email && (
          <Form.Group controlId='email' className='auth__input-group'>
            <Label>{t('Email')}</Label>
            <Input
              type='text'
              autoComplete='off'
              placeholder='Email'
              className={clsx(
                [errors.email && 'is-invalid'],
                [dirtyFields.email && !errors.email ? 'is-valid' : ''],
              )}
              data-testid={'email-input'}
              {...register('email')}
            />
          </Form.Group>
        )}
        {!newPasswordRequired && (
          <Form.Group controlId='code' className='auth__input-group'>
            <Label>{t('Code')}</Label>
            <Input
              type='text'
              autoComplete='off'
              placeholder='Code'
              className={clsx(
                [errors.code && 'is-invalid'],
                [dirtyFields.code && !errors.code ? 'is-valid' : ''],
              )}
              data-testid={'code-input'}
              {...register('code')}
            />
          </Form.Group>
        )}
        <Form.Group controlId='password' className='auth__input-group auth__input-group--relative'>
          <Label>{t('Password')}</Label>
          <Input
            type={inputFieldType}
            className={clsx('auth__input-no-bootstrap-icon', [errors.password && 'is-invalid'])}
            data-testid={'password-input'}
            {...register('password', { onChange: () => trigger('confirmPassword') })}
          />
          <span
            role={'button'}
            tabIndex={0}
            onKeyDown={(e) => e.key === 'Enter' && togglePasswordVisibility()}
            className='auth__input-group-icon'
            onClick={togglePasswordVisibility}
          >
            {inputFieldType === 'password' ? (
              <img src={eyeOffIcon} width={20} height={20} alt='eye-off-icon' />
            ) : (
              <img src={eyeIcon} width={20} height={20} alt='eye-icon' />
            )}
          </span>
        </Form.Group>

        <Form.Group
          controlId='confirmPassword'
          className='auth__input-group auth__input-group--relative'
        >
          <Label>{t('Confirm password')}</Label>
          <Input
            type={inputFieldType}
            className={clsx('auth__input-no-bootstrap-icon', [
              errors.confirmPassword && 'is-invalid',
            ])}
            data-testid={'confirm-password-input'}
            {...register('confirmPassword', { onChange: () => trigger('password') })}
          />
          <span
            role={'button'}
            tabIndex={0}
            onKeyDown={(e) => e.key === 'Enter' && togglePasswordVisibility()}
            className='auth__input-group-icon'
            onClick={togglePasswordVisibility}
          >
            {inputFieldType === 'password' ? (
              <img src={eyeOffIcon} width={20} height={20} alt='eye-off-icon' />
            ) : (
              <img src={eyeIcon} width={20} height={20} alt='eye-icon' />
            )}
          </span>
        </Form.Group>

        <PasswordValidationErrors
          errors={errors as FieldError[]}
          showPasswordGuide={!dirtyFields.password && !dirtyFields.confirmPassword}
        />

        <div className='d-flex flex-column align-items-center pt-4 pb-4'>
          <LoaderButton
            className={`w-100`}
            type='submit'
            onClick={handleSubmit(onSubmit)}
            isSubmitting={auth.isLoading}
            disabled={!isValid}
          >
            {t('Next')}
          </LoaderButton>
        </div>
      </form>
    </div>
  );
};
const ResetPasswordSuccess: FunctionComponent<SuccessScreenProps> = ({ onProcessDone }) => {
  const { t } = useTranslation();
  return (
    <div className='d-flex flex-column justify-content-center'>
      <div className='d-flex flex-column align-items-center'>
        <img className='auth__icon' alt='lock-icon' src={successGlowIcon} />
        <p className='auth__page-title'>{t('Password Updated')}</p>
        <div className='d-flex flex-column align-items-center'>
          <p className='auth__help-text'>
            {t('Password was successfully updated, please click login to continue.')}
          </p>

          <button className='btn btn-primary auth__btn-primary' onClick={() => onProcessDone()}>
            {t('Back to Login')}
          </button>
        </div>
      </div>
    </div>
  );
};

const ResetPasswordSteps = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [hasResetPassword, setHasResetPassword] = useState(false);
  const [shouldShowProviderMessage, setShouldShowProviderMessage] = useState(false);

  const [searchParams] = useSearchParams();
  const email = searchParams.get('u');
  const code = searchParams.get('c');
  const roles = searchParams.get('r');
  const { t } = useTranslation();
  const newPasswordRequired = searchParams.get('newPasswordRequired') ? true : false;

  useEffect(() => {
    if (roles) {
      const userRoles = roles.split(',');
      const isHigherRoleUser = userRoles.some((role) =>
        [SYSTEM_ROLES.ADMIN, SYSTEM_ROLES.PROVIDER, SYSTEM_ROLES.MANAGER].includes(role),
      );
      setShouldShowProviderMessage(isHigherRoleUser);
    }
  }, [roles]);

  const completeNewPassword = async (formData: ResetFormData) => {
    try {
      await dispatch(completeNewPasswordThunk({ newPassword: formData.confirmPassword })).unwrap();
      setHasResetPassword(true);
    } catch (error: Error | any) {
      const message = 'Something went wrong. Please try again.';
      alert.error(t(message), { toastId: 'complete-new-password-error' });
    }
  };

  const resetPassword = async (formData: ResetFormData) => {
    try {
      await dispatch(
        resetPasswordThunk({
          username: email ? email : formData.email || '',
          code: code ? code : formData.code || '',
          newPassword: formData.password,
        }),
      ).unwrap();
      setHasResetPassword(true);
    } catch (error: Error | any) {
      let message = 'Something went wrong. Please try again.';
      if (error instanceof Error) message = error.message;
      alert.error(t(message), { toastId: 'reset-password-error' });
    }
  };

  const onSubmitAction = newPasswordRequired ? completeNewPassword : resetPassword;
  const onProcessDone = () => navigate('/');

  return !hasResetPassword ? (
    <ResetPasswordForm
      onSubmit={onSubmitAction}
      newPasswordRequired={newPasswordRequired}
      code={code}
      email={email}
    />
  ) : (
    <ResetPasswordSuccess
      onProcessDone={onProcessDone}
      shouldShowProviderMessage={shouldShowProviderMessage}
    />
  );
};

export default ResetPasswordSteps;
