import { Suspense, useCallback, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import useWebsocket, { ReadyState } from 'react-use-websocket';

import LoadingIndicator from '../components/common/LoadingIndicator';
import Sidebar from '../features/app/components/Sidebar';
import { createSocketConnection, selectSocketState } from '../store/socket';
import { useAppDispatch, useAppSelector } from '../store';
import { logoutUserThunk } from '../features/auth/api/authSlice';
import usePrevious from '../hooks/usePrevious';
import SessionManagementProvider from '../providers/SessionManagementProvider';
import { createNotificationActionMessage, NotificationTypeSocketCommand } from '../utils/socket';

export const AppLayout = () => {
  const dispatch = useAppDispatch();
  const { connectionUrl } = useAppSelector(selectSocketState);

  const getSocketUrl = useCallback(async () => {
    await dispatch(createSocketConnection()).unwrap();
  }, [dispatch]);

  useEffect(() => {
    getSocketUrl();
  }, [getSocketUrl]);

  const { sendMessage, readyState } = useWebsocket(connectionUrl, {
    onOpen: () => console.log('opened'),
    share: true,
  });

  const logout = useCallback(() => dispatch(logoutUserThunk()), [dispatch]);

  // * TEMP. ZOOM LEFTOVERS WORKAROUND
  const location = useLocation();
  const previousLocation = usePrevious(location);
  useEffect(() => {
    if (previousLocation?.pathname.includes('meeting') && !location.pathname.includes('meeting')) {
      console.log('Leaving meeting route', location, previousLocation);
      window.location.reload();
    }
  }, [location, previousLocation]);

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      sendMessage(
        JSON.stringify(createNotificationActionMessage(NotificationTypeSocketCommand.List)),
      );
    }
  }, [readyState, sendMessage]);

  return (
    <>
      <div className={`container-fluid vh-100`}>
        <div className='row h-100' id='page-list' style={{ overflow: 'auto' }}>
          <div className='col-12 p-0 col-md-auto app__sidebar-wrap'>
            <Sidebar />
          </div>
          <div className='col p-0 d-flex flex-column'>
            <main className={`flex-grow-1 bg-secondary app__main`}>
              <Suspense fallback={<LoadingIndicator />}>
                <SessionManagementProvider
                  onNetworkLost={logout}
                  onNetworkLostRegainThresholdSeconds={30}
                >
                  <Outlet />
                </SessionManagementProvider>
              </Suspense>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};
